<template>
  <div :class="$vuetify.breakpoint.mobile ? 'mobile-device' : 'other-device'">
    <div
      class="d-flex justify-center header-toolbar"
    >
      <v-row no-gutters>
        <v-col
          class="px-5 py-4 logo-mobile"
          cols="auto"
        >
          <div class="d-flex align-center">
            <v-img
              src="/images/logo.svg"
              max-height="39"
              max-width="39"
            />
            <span class="ml-3 notify-title-text-white">
              Manage School Notify notifications
            </span>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-container class="mt-sm-10">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
          v-if="this.other"
        >
          <Other />
        </v-col>
        <v-col
          cols="12"
          sm="10"
          v-if="this.parent"
        >
          <Parent />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Other from './Other.vue';
import Parent from './Parent.vue';
export default {
  name: 'Notifications',
  title: 'Manage School Notify notifications',
  components: {
    Other,
    Parent
  },
  data() {
    return {
      other: false,
      parent: false
    }
  },
  created() {
    if (this.$route.query.contactId) {
      this.other = true;
    } else {
      this.parent = true;
    }
  },
}
</script>

<style lang='scss' scoped>
 .header-toolbar {
  background-color: #002664
 }
</style>
