<template>
  <div>
    <v-container class="py-5">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
        >
          <label class="ad-text font-weight-bold">Select a response procedure</label>
        </v-col>
        <v-col
          cols="12"
          sm="10"
        >
          <AdsSelect
            :items="getSelectionItems"
            item-text="title"
            item-value="id"
            v-model="selectedItem"
            return-object
            hide-details
            label="Select a reponse procedure"
            menu-props="offset-y"
          />
        </v-col>
      </v-row>
    </v-container>
    <div style="background-color: #f4f4f7">
      <v-container 
        class="py-5"
      >
        <v-row justify="center">
          <v-col
            cols="12"
            sm="10"
          >
            <v-list 
              class="py-0" 
              style="background: transparent"
            >
              <v-list-item
                class="px-0 mb-6"
                v-for="(item, index) in checkListAttributes[0]?.items"
                :key="item.label"
              >
                <v-card
                  class="d-flex align-center flex-grow-1"
                  outlined
                  min-height="80"
                >
                  <div class="d-flex align-center pa-4 primary--text">
                    <v-avatar
                      color="primary"
                      size="27"
                      class="mr-4"
                    >
                      {{ index + 1 }}
                    </v-avatar>
                    {{ item.label }}
                  </div>
                </v-card>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import { AdsSelect } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';
export default {
  name: 'EmergencyChecklists',
  components: {
    AdsSelect
  },
  data: () => ({
    selectedItem: {
      id: '',
      title: '',
    },
  }),
  computed: {
    ...mapGetters({
      emergencyChecklists: 'moduleDashboard/getEmergencyChecklists',
    }),
    getSelectionItems() {
      const entries = this.emergencyChecklists.map((item) => {
        return {
          id: item.category_id,
          title: item.label,
        };
      });
      this.setSelectedItem(entries);
      return entries;
    },
    checkListAttributes() {
      return this.emergencyChecklists.filter(
        (item) => item.category_id === this.selectedItem.id
      );
    },
  },
  methods: {
    setSelectedItem(entries) {
      if (this.selectedItem.title.length === 0) {
        this.selectedItem = entries[0];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  border: 2px solid #495054;
}
.v-avatar {
  background-color: #002664 !important;
}
</style>
