<template>
  <div :class="$vuetify.breakpoint.mobile ? 'mobile-device' : 'other-device'">
    <div
      v-if="!showDialog"
      class="d-flex justify-center header-toolbar"
      :style="
        $vuetify.breakpoint.mobile ? {} : { 'background-color': '#002664' }
      "
    >
      <v-row no-gutters>
        <v-col
          class="px-5 py-4 logo-mobile"
          cols="auto"
        >
          <div class="d-flex align-center">
            <v-img
              src="/images/logo.svg"
              max-height="39"
              max-width="39"
            />
            <span class="hidden-sm-and-down ml-3 notify-title-text-white">
              School Notify
            </span>
          </div>
        </v-col>
        <div
          class="d-flex align-center justify-end"
          style="flex: 1"
        >
          <v-col 
            cols="auto"
            class="pr-0"  
          >
            <v-btn
              icon
              :dark="!$vuetify.breakpoint.xs"
              @click="showChecklistDialog = !showChecklistDialog"
              aria-label="Emergency checklist"
            >
              <v-icon size="28px">
                mdi-clipboard-text-outline
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :dark="!$vuetify.breakpoint.xs"
                  aria-label="Settings"
                >
                  <v-icon size="28px">
                    mdi-cog-outline
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(setting, i) in settings"
                  :key="i"
                  @click="selectedSetting(setting)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="setting.icon" />
                  </v-list-item-icon>
                  <v-list-item-title style="color: #002664">
                    {{ setting.title }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="px-5"
                  v-if="showDebug"
                >
                  <v-list-item-icon>
                    <v-icon v-text="'mdi-information-outline'" />
                  </v-list-item-icon>
                  <v-list-item-title style="color: #002664">
                    Build: 40
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-col
            class="d-flex align-center profileName"
            :class="{ 'pr-5 pl-2': $vuetify.breakpoint.mobile }"
            cols="auto"
          >
            <Profile
              :givenName="userFirstName"
              :surname="userLastName"
              :updateProfile="false"
              :changePassword="false"
              :updateSecretQuestion="false"
              :portalSettings="false"
              :logout="true"
              :logoutHandler="logout"
              tabindex="0"
            />
          </v-col>
        </div>
      </v-row>
    </div>
    <v-container class="page-banner">
      <div class="d-inline-flex">
        <v-row class="d-flex">
          <v-col
            :class="{ 'pt-8': !$vuetify.breakpoint.mobile }"
            v-if="items.length > 1"
          >
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-row no-gutters>
                  <v-col
                    class="schoolName-text"
                    style="white-space: nowrap"
                  >
                    {{ getSchoolName }}
                  </v-col>
                  <v-col
                    cols="auto"
                    class="px-4"
                  >
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      aria-label="Select school"
                    >
                      <v-icon size="32">
                        mdi-arrow-down-drop-circle-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>

              <v-list>
                <v-list-item
                  v-for="school in items"
                  :key="school.code"
                  @click="selectedSchool(school)"
                >
                  <v-list-item-title style="color: #002664">
                    {{ school.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col v-else>
            <span class="schoolName-text mb-3">
              {{ getSchoolName }}
            </span>
          </v-col>
        </v-row>
      </div>
      <v-row class="d-flex pt-3">
        <v-col cols="9">
          <span class="message-dashboard"> Message dashboard </span>
        </v-col>
      </v-row>

      <v-row class="d-flex pb-7">
        <v-col cols="9">
          <FullScreenNewMessageDialog />
        </v-col>
      </v-row>
    </v-container>
    <template>
      <FullscreenDialog
        title="Help"
        v-model="showHelpDialog"
        transition="dialog-bottom-transition"
      >
        <template v-slot:content>
          <Help @close-dialog="showHelpDialog = false" />
        </template>
      </FullscreenDialog>
    </template>
    <template>
      <FullscreenDialog
        title="My templates"
        v-model="showTemplateDialog"
        transition="dialog-bottom-transition"
      >
        <template v-slot:content>
          <ManageTemplates />
        </template>
      </FullscreenDialog>
    </template>
    <template>
      <FullscreenDialog
        title="Manage contacts"
        v-model="showContactDialog"
        transition="dialog-bottom-transition"
        content-class="bg-grey"
      >
        <template v-slot:content>
          <ManageContacts />
        </template>
      </FullscreenDialog>
    </template>
    <FullscreenDialog
      title="Emergency checklists"
      v-model="showChecklistDialog"
      transition="dialog-bottom-transition"
    >
      <template v-slot:content>
        <EmergencyChecklists />
      </template>
    </FullscreenDialog>
  </div>
</template>
<script>
import FullScreenNewMessageDialog from '@/components/common/FullScreenNewMessageDialog';
import ManageContacts from '@/views/Settings/Contacts/ManageContacts';
import Help from '@/views/Settings/Help/Help';
import EmergencyChecklists from '@/views/Dashboard/Toolbar/EmergencyChecklists';
import ManageTemplates from '@/views/Settings/Templates/ManageTemplates';
import { FullscreenDialog, Profile } from '@nswdoe/doe-ui-core';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Toolbar',
  components: {
    Profile,
    FullScreenNewMessageDialog,
    FullscreenDialog,
    Help,
    ManageTemplates,
    ManageContacts,
    EmergencyChecklists
  },
  data() {
    return {
      settings: [
        {
          title: 'My templates',
          icon: 'mdi-note-multiple-outline',
          route: '/managetemplates',
          id: 1,
        },
        {
          title: 'Manage contacts',
          icon: 'mdi-account-group-outline',
          route: '/contacts',
          id: 2,
        },
        {
          title: 'Help',
          icon: 'mdi-help-circle-outline',
          route: '/help',
          id: 3,
        },
      ],
      showDialog: false,
      showHelpDialog: false,
      showTemplateDialog: false,
      showContactDialog: false,
      showChecklistDialog: false,
      title: 'New Message',
    };
  },
  computed: {
    ...mapState('moduleDashboard', {
      messages: (state) => state.dashboard,
    }),
    ...mapGetters({
      selected: 'moduleDashboard/getSelectedSchool',
      items: 'moduleDashboard/getAllSchools',
      userProfile: 'moduleUserProfile/userProfile',
    }),
    select: {
      get() {
        return this.selected;
      },
      set(selection) {
        this.$store.dispatch('moduleDashboard/updateSelectedSchool', selection);
      },
    },
    getSchoolName() {
      return this.selected ? this.selected.name : undefined;
    },
    userFirstName() {
      if (this.userProfile.firstName) {
        return this.userProfile.firstName;
      } else {
        return this.$OAuth.data?.profile?.name;
      }
    },
    userLastName() {
      if (this.userProfile.lastName) {
        return this.userProfile.lastName;
      } else {
        return '';
      }
    },
    showDebug() {
      return process.env.NODE_ENV !== 'production';
    },
  },
  watch: {
    selected(newValue, oldValue) {
      // alert(`yes, computed property changed: ${newValue.code}`);
      if (newValue !== undefined || newValue !== oldValue) {
        this.$store.dispatch('moduleDashboard/fetchSchoolNotifications');
      }
    },
    showContactDialog(newVal) {
      if (newVal) {
        this.$store.dispatch('moduleManageContacts/fetchDelContacts');
      }
    },
    showTemplateDialog(newVal) {
      if (newVal) {
        this.$store.dispatch('moduleTemplates/fetchTemplates');
      }
    },
  },
  created() {
    if (this.selected === undefined) {
      this.$store.dispatch(
        'moduleDashboard/fetchAllSchools',
        this.userProfile.locations
      );
    }
  },

  methods: {
    showMessagePage() {
      this.showDialog = true;
    },
    selectedSchool(school) {
      this.$store.dispatch('moduleDashboard/updateSelectedSchool', school);
    },
    logout() {
      window.sessionStorage.removeItem('vuex');
      this.$OAuth.logout();
    },
    selectedSetting(setting) {
      switch (setting.id) {
        case 1:
          this.showTemplateDialog = true;
          break;
        case 2:
          this.showContactDialog = true;
          break;
        case 3:
          this.showHelpDialog = true;
          break;
      }
      // this.$router.push({
      //   path: setting.route,
      // })
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 599px) {
  .mobile-device {
    background-image: url('/images/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 100% !important;
  }

  .schoolName-text {
    white-space: normal !important;
  }

  ::v-deep .v-avatar {
    background-color: #002664 !important;
    height: 30px;
    min-width: 30px;
    width: 30px;
  }

  ::v-deep .initials-icon {
    color: #cbedfd !important;
  }
}

@media screen and (min-width: 600px) {
  .page-banner {
    background-image: url('/images/page-banner.png');
    background-size: cover;
    max-width: 100% !important;
  }

  .header-toolbar {
    background-color: #002664;
  }
}

::v-deep {
  .v-main__wrap {
    display: flex;
  }

  .v-tab {
    text-align: center;
    width: 100%;
    padding: 5px 2px;
  }

  .theme--light.v-card .v-card__text,
  .theme--light.v-card .v-card__subtitle {
    color: black;
  }

  .theme--light.v-text-field--solo-inverted.v-input--is-focused
    > .v-input__control
    > .v-input__slot {
    background: none;
    border: none;
  }

  .v-select__selection {
    width: auto;
  }

  .v-select__selection--comma {
    text-wrap: wrap;
  }

  .v-list-item[role='menuitem'] {
    padding: 0 20px;
  }

  .v-list-item__title,
  .v-list-item__subtitle {
    word-break: normal;
  }
  .v-list-item__icon:first-child {
    margin-right: 20px;
  }

  .theme--light.v-select.v-text-field--solo-inverted.v-input--is-focused
    .v-select__selections {
    color: #121212;
  }

  .v-card__title {
    word-break: normal;
    font-weight: 700;
    color: #002664;
  }

  // .v-input {
  //   font-size: 20px;
  //   font-weight: bold;
  // }
}
</style>
