<template>
  <div>
    <v-container class="py-5">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
        >
          <span class="notify-title-text">Message</span>
        </v-col>
        <v-col
          cols="12"
          sm="10"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              ref="title"
              outlined
              background-color="white"
              label="Message title"
              v-model="formData.title"
              required
              placeholder="Required"
              :rules="nameRules"
              persistent-placeholder
              :aria-invalid="$refs.title && $refs.title.hasFocused && $refs.title.hasError ? 'true' : 'false'"
              :append-icon="$refs.title && $refs.title.hasFocused && $refs.title.hasError ? 'mdi-alert-circle' : ''"
              :aria-describedby="$refs.title && $refs.title.hasFocused && $refs.title.hasError ? 'title-error' : false"
            >
              <template v-slot:message>
                <div id="title-error">
                  <template v-for="(message) in $refs.title.messagesToDisplay">
                    {{ message }}
                  </template>
                </div>
              </template>
            </v-text-field>
            <v-textarea
              ref="message"
              spellcheck="true"
              outlined
              background-color="white"
              label="Message"
              counter="1340"
              style="min-height: 276px"
              v-model="formData.description"
              required
              placeholder="Required"
              :rules="[messageRules]"
              persistent-placeholder
              :aria-invalid="$refs.message && $refs.message.hasFocused && $refs.message.hasError ? 'true' : 'false'"
              :append-icon="$refs.message && $refs.message.hasFocused && $refs.message.hasError ? 'mdi-alert-circle' : ''"
              :aria-describedby="$refs.message && $refs.message.hasFocused && $refs.message.hasError ? 'message-error' : false"
            >
              <template v-slot:message>
                <div id="message-error">
                  <template v-for="(message) in $refs.message.messagesToDisplay">
                    {{ message }}
                  </template>
                </div>
              </template>
            </v-textarea>

            <v-row
              v-show="displayAlert"
              class="flex-nowrap mr-2 mb-1"
            >
              <v-col cols="auto pr-0">
                <v-img
                  src="/images/notification-icon.svg"
                  width="24px"
                  height="24px"
                />
              </v-col>
              <v-col cols="auto pl-0 mb-2 flex-shrink-1">
                <div class="ml-2 check_list_alert">
                  {{ warning }}
                </div>
              </v-col>
            </v-row>

            <v-select
              ref="template"
              v-model="formData.selected"
              :items="getAllTemplates"
              label="Template category"
              outlined
              item-text="name"
              item-value="id"
              return-object
              :rules="[checkSelection]"
              :aria-invalid="$refs.template && $refs.template.hasFocused && $refs.template.hasError ? 'true' : 'false'"
              :append-icon="$refs.template && $refs.template.hasFocused && $refs.template.hasError ? 'mdi-alert-circle' : 'mdi-menu-down'"
              :aria-describedby="$refs.template && $refs.template.hasFocused && $refs.template.hasError ? 'template-error' : false"
              menu-props="offset-y"
            >
              <template v-slot:message="{ message }">
                <div
                  id="template-error"
                  v-html="message"
                />
              </template>
            </v-select>

            <v-row justify="space-between">
              <v-col sm="3">
                <AdsButton
                  block
                  primary
                  :buttonText="isEdit ? 'Save and close' : 'Save template'"
                  @click="saveTemplate"
                  :disabled="!this.valid"
                />
              </v-col>
              <v-col
                sm="3"
                v-if="isEdit && formData && !formData.corporate_ind"
              >
                <AdsButton
                  block
                  color="error"
                  buttonText="Delete this template"
                  @click="deleteTemplate"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  AdsButton,
} from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';
export default {
  name: 'EditTemplate',
  components: {
    AdsButton,
  },
  props: {
    templateId: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    selected: [],
    valid: true,
    isEdit: false,
    displayAlert: false,
    warning:
      'NOTE: This message exceeds SMS length (approx. 160 characters). It will be sent as separate SMS messages.',
    formData: {
      title: '',
      description: '',
      selected: [],
      isEmergency: false,
      templateId: '',
      catId: '',
    },
    nameRules: [
      (v) => !!v || 'Title is required',
      (v) => (v && v.length <= 64) || 'Title must be less than 64 characters',
    ],
  }),
  methods: {
    messageRules(val) {
      if (!val || val.length === 0) {
        this.displayAlert = false;
        return 'Message is required';
      } else if (val.length > 1340) {
        return 'Message must be less than 1340 characters';
      } else if (val.length >= 160) {
        this.displayAlert = true;
        return true;
      } else {
        this.displayAlert = false;
        return true;
      }
    },
    checkSelection() {
      if (Array.isArray(this.formData.selected)) {
        return 'Category name is required';
      }
      return true;
    },
    filterTemplateById(templateId) {
      return this.templatesMetaData.filter((item) => item.id === templateId);
    },
    async saveTemplate() {
      if (this.$refs.form.validate()) {
        if (this.isEdit) {
          await this.updateTemplate();
          this.$emit('close-dialog', true);
        } else {
          await this.createNewTemplate();
          this.$emit('close-dialog', true);
          this.$refs.form.reset();
        }
      }
    },
    async deleteTemplate() {
      this.$store.commit('SET_IS_LOADING', 'deleteTemplate', {
        root: true,
      });
      const templateId = this.formData.templateId;
      await this.$http
        .delete(process.env.VUE_APP_API_BASE_URL + '/templates/' + templateId)
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch('moduleTemplates/fetchTemplates');
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'deleteTemplate', {
            root: true,
          });
          this.$emit('close-dialog', true);
        });
    },
    async createNewTemplate() {
      const payload = {
        title: this.formData.title,
        description: this.formData.description,
        catId: this.formData.selected.id,
        corporate_ind: false,
        isEmergency: this.formData.selected.type == 'Emergencies',
      };
      this.$store.commit('SET_IS_LOADING', 'createNewTemplate', {
        root: true,
      });
      await this.$http
        .post(process.env.VUE_APP_API_BASE_URL + '/templates', payload)
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch('moduleTemplates/fetchTemplates');
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'createNewTemplate', {
            root: true,
          });
        });
    },
    async updateTemplate() {
      const templateId = this.formData.templateId;
      const payload = {
        title: this.formData.title,
        description: this.formData.description,
        catId: this.formData.selected.id,
        corporate_ind: this.formData.corporate_ind,
        isEmergency: this.formData.selected.type == 'Emergencies',
      };
      this.$store.commit('SET_IS_LOADING', 'updateTemplate', {
        root: true,
      });
      await this.$http
        .put(
          process.env.VUE_APP_API_BASE_URL + '/templates/' + templateId,
          payload
        )
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch('moduleTemplates/fetchTemplates');
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'updateTemplate', {
            root: true,
          });
        });
    },
    mapSelectionItem(item) {
      var mappedItems = [];
      const header = { header: item.name };
      const options = item.items.map((el) => {
        const option = {
          name: el.name,
          type: el.type,
          total: el.total,
          id: el.catId,
          edit: el.edit,
        };
        var prependCount = `${el.name} (${el.total})`;
        option.name = prependCount;
        return option;
      });
      const divider = { divider: true };
      mappedItems.push(header);
      for (const op of options) {
        mappedItems.push(op);
      }
      mappedItems.push(divider);
      return mappedItems;
    },
  },
  computed: {
    ...mapGetters({
      school: 'moduleDashboard/getSelectedSchool',
      templates: 'moduleTemplates/getTemplates',
      templateData: 'moduleManageTemplates/getTemplateData',
      templatesMetaData: 'moduleTemplates/getTemplatesMetaData',
    }),
    getAllTemplates() {
      var selection = [];
      for (const item of this.templates) {
        const items = this.mapSelectionItem(item);
        selection.push(...items);
      }
      selection.pop();
      return selection;
    },
  },
  created() {
    if (this.templateId !== 'new') {
      const filteredRes = this.filterTemplateById(this.templateId);
      const res = filteredRes[0];
      const selection = this.getAllTemplates.filter(
        (item) => item.id === res.catId
      );
      this.isEdit = true;
      this.formData.title = res.title;
      this.formData.description = res.description;
      this.formData.isEmergency = res.isEmergency;
      this.formData.selected = selection[0];
      this.formData.templateId = res.id;
      this.formData.catId = res.catId;
      this.formData.corporate_ind = res.corporate_ind;
      // this.$store.dispatch('moduleManageTemplates/fetchTemplateDataById', params);
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-style {
  height: 44px;
  align-items: center;
}
</style>
