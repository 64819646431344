import api from '../../api';

// Vuex Posts module
const moduleManageTemplates = {
  namespaced: true,
  state: {
    editTemplate: {
      title: 'rrr',
      description: 'rrrr 67867',
      category: '',
      id: 'rrrr',
    },
  },
  getters: {
    getTemplateData: (state) => state.editTemplate,
  },
  mutations: {
    SET_TEMPLATE_DATA(state, data) {
      state.editTemplate = data;
    },
  },
  actions: {
    fetchTemplateDataById({ commit }, params) {
      commit('SET_IS_LOADING', `templateDataById-${params.templateId}`, {
        root: true,
      });
      api.managetemplates
        .fetchTemplateDataById(params.templateId, params.schoolCode)
        .then((response) => {
          return commit('SET_TEMPLATE_DATA', ...response);
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', `templateDataById-${params.templateId}`, {
            root: true,
          });
        });
    },
  },
};

export default moduleManageTemplates;
