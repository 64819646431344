var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"py-5"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('h2',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isEdit),expression:"!isEdit"}],staticClass:"notify-title-text"},[_vm._v(" Add a new contact ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEdit),expression:"isEdit"}],staticClass:"notify-title-text"},[_vm._v(" Edit contact ")])]),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-card-subtitle',[_c('v-text-field',{ref:"firstName",attrs:{"outlined":"","background-color":"white","label":"First name","required":"","placeholder":"Required","rules":_vm.firstNameRules,"persistent-placeholder":"","aria-invalid":_vm.$refs.firstName &&
                    _vm.$refs.firstName.hasFocused &&
                    _vm.$refs.firstName.hasError
                    ? 'true'
                    : 'false',"append-icon":_vm.$refs.firstName &&
                    _vm.$refs.firstName.hasFocused &&
                    _vm.$refs.firstName.hasError
                    ? 'mdi-alert-circle'
                    : '',"aria-describedby":_vm.$refs.firstName &&
                    _vm.$refs.firstName.hasFocused &&
                    _vm.$refs.firstName.hasError
                    ? 'first-name-error'
                    : false},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('div',{attrs:{"id":"first-name-error"}},[_vm._l((_vm.$refs.firstName.messagesToDisplay),function(message){return [_vm._v(" "+_vm._s(message)+" ")]})],2)]},proxy:true}]),model:{value:(_vm.formData.first_name),callback:function ($$v) {_vm.$set(_vm.formData, "first_name", $$v)},expression:"formData.first_name"}}),_c('v-text-field',{ref:"lastName",attrs:{"outlined":"","background-color":"white","label":"Last name","required":"","placeholder":"Required","rules":_vm.lastNameRules,"persistent-placeholder":"","aria-invalid":_vm.$refs.lastName &&
                    _vm.$refs.lastName.hasFocused &&
                    _vm.$refs.lastName.hasError
                    ? 'true'
                    : 'false',"append-icon":_vm.$refs.lastName &&
                    _vm.$refs.lastName.hasFocused &&
                    _vm.$refs.lastName.hasError
                    ? 'mdi-alert-circle'
                    : '',"aria-describedby":_vm.$refs.lastName &&
                    _vm.$refs.lastName.hasFocused &&
                    _vm.$refs.lastName.hasError
                    ? 'last-name-error'
                    : false},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('div',{attrs:{"id":"last-name-error"}},[_vm._l((_vm.$refs.lastName.messagesToDisplay),function(message){return [_vm._v(" "+_vm._s(message)+" ")]})],2)]},proxy:true}]),model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", $$v)},expression:"formData.last_name"}}),_c('v-text-field',{ref:"phone",attrs:{"outlined":"","background-color":"white","label":"Mobile number","required":"","placeholder":"Required","rules":[_vm.phoneRules],"persistent-placeholder":"","aria-invalid":_vm.$refs.phone &&
                    _vm.$refs.phone.hasFocused &&
                    _vm.$refs.phone.hasError
                    ? 'true'
                    : 'false',"append-icon":_vm.$refs.phone &&
                    _vm.$refs.phone.hasFocused &&
                    _vm.$refs.phone.hasError
                    ? 'mdi-alert-circle'
                    : '',"aria-describedby":_vm.$refs.phone &&
                    _vm.$refs.phone.hasFocused &&
                    _vm.$refs.phone.hasError
                    ? 'phone-error'
                    : false},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('div',{attrs:{"id":"phone-error"}},[_vm._l((_vm.$refs.phone.messagesToDisplay),function(message){return [_vm._v(" "+_vm._s(message)+" ")]})],2)]},proxy:true}]),model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),_c('v-text-field',{ref:"email",attrs:{"outlined":"","background-color":"white","label":"Email address","required":"","placeholder":"Required","rules":[_vm.emailRules],"persistent-placeholder":"","aria-invalid":_vm.$refs.email &&
                    _vm.$refs.email.hasFocused &&
                    _vm.$refs.email.hasError
                    ? 'true'
                    : 'false',"append-icon":_vm.$refs.email &&
                    _vm.$refs.email.hasFocused &&
                    _vm.$refs.email.hasError
                    ? 'mdi-alert-circle'
                    : '',"aria-describedby":_vm.$refs.email &&
                    _vm.$refs.email.hasFocused &&
                    _vm.$refs.email.hasError
                    ? 'email-error'
                    : false},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('div',{attrs:{"id":"email-error"}},[_vm._l((_vm.$refs.email.messagesToDisplay),function(message){return [_vm._v(" "+_vm._s(message)+" ")]})],2)]},proxy:true}]),model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('v-text-field',{ref:"role",attrs:{"outlined":"","background-color":"white","label":"Role","rules":[_vm.roleRules],"persistent-placeholder":"","aria-invalid":_vm.$refs.role && _vm.$refs.role.hasFocused && _vm.$refs.role.hasError
                    ? 'true'
                    : 'false',"append-icon":_vm.$refs.role && _vm.$refs.role.hasFocused && _vm.$refs.role.hasError
                    ? 'mdi-alert-circle'
                    : '',"aria-describedby":_vm.$refs.role && _vm.$refs.role.hasFocused && _vm.$refs.role.hasError
                    ? 'role-error'
                    : false},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('div',{attrs:{"id":"role-error"}},[_vm._l((_vm.$refs.role.messagesToDisplay),function(message){return [_vm._v(" "+_vm._s(message)+" ")]})],2)]},proxy:true}]),model:{value:(_vm.formData.role),callback:function ($$v) {_vm.$set(_vm.formData, "role", $$v)},expression:"formData.role"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-8",attrs:{"cols":"12"}},[_c('h2',{staticClass:"notify-title-text"},[_vm._v(" Choose a subgroup ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-subtitle',{staticClass:"message-dashboard"},[_vm._v(" choose a subgroup for this contact ")]),_c('v-card-text',[_c('v-btn',{staticClass:"template-category pl-3 pr-4",attrs:{"outlined":"","block":"","aria-label":_vm.formData.subgroup
                        ? _vm.formData.subgroup.name
                        : 'Select subgroup'},on:{"click":function($event){_vm.subgroupDialog = !_vm.subgroupDialog}}},[_vm._v(" "+_vm._s(_vm.formData.subgroup ? _vm.formData.subgroup.name : "Select subgroup")+" "),_c('v-icon',{attrs:{"right":"","dark":"","size":"24"}},[_vm._v(" mdi-menu-down ")])],1),(_vm.getSelectionItems)?_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.subgroupDialog),callback:function ($$v) {_vm.subgroupDialog=$$v},expression:"subgroupDialog"}},[_c('v-list',[_c('v-list-item-group',{attrs:{"mandatory":""},on:{"change":_vm.subgroupChange},model:{value:(_vm.formData.subgroup),callback:function ($$v) {_vm.$set(_vm.formData, "subgroup", $$v)},expression:"formData.subgroup"}},[_vm._l((_vm.getSelectionItems),function(subgroup){return [_c('v-list-item',{key:subgroup.id,attrs:{"value":subgroup}},[_vm._v(" "+_vm._s(subgroup.name)+" ")])]}),_c('v-divider')],2),_c('v-form',{ref:"categoryForm",staticClass:"mt-2",attrs:{"lazy-validation":"","onSubmit":"return false"},model:{value:(_vm.categoryFormValid),callback:function ($$v) {_vm.categoryFormValid=$$v},expression:"categoryFormValid"}},[_c('v-list-item',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-list-item-content',{staticClass:"pb-0"},[_c('v-text-field',{ref:"categoryName",staticClass:"plain-textbox",attrs:{"outlined":"","background-color":"white","label":"Create a new subgroup","required":"","placeholder":"Required","rules":[_vm.nameRules],"persistent-placeholder":"","aria-invalid":(_vm.createNewCategoryInvalid &&
                                    _vm.newSubGroup.length === 0) ||
                                    (_vm.$refs.categoryForm &&
                                    _vm.$refs.categoryForm.inputs[0].hasFocused &&
                                    _vm.$refs.categoryForm.inputs[0].hasError)
                                    ? 'true'
                                    : 'false',"append-icon":(_vm.createNewCategoryInvalid &&
                                    _vm.newSubGroup.length === 0) ||
                                    (_vm.$refs.categoryForm &&
                                    _vm.$refs.categoryForm.inputs[0].hasFocused &&
                                    _vm.$refs.categoryForm.inputs[0].hasError)
                                    ? 'mdi-alert-circle'
                                    : '',"aria-describedby":(_vm.createNewCategoryInvalid &&
                                    _vm.newSubGroup.length === 0) ||
                                    (_vm.$refs.categoryForm &&
                                    _vm.$refs.categoryForm.inputs[0].hasFocused &&
                                    _vm.$refs.categoryForm.inputs[0].hasError)
                                    ? 'category-error'
                                    : false},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('div',{attrs:{"id":"category-error"}},[_vm._l((_vm.$refs.categoryName
                                        .messagesToDisplay),function(message){return [_vm._v(" "+_vm._s(message)+" ")]})],2)]},proxy:true}],null,false,3621164377),model:{value:(_vm.newSubGroup),callback:function ($$v) {_vm.newSubGroup=$$v},expression:"newSubGroup"}})],1),_c('v-list-item-action',{staticClass:"align-self-start mt-3"},[_c('v-btn',{attrs:{"icon":"","aria-label":"Create a new subgroup"},on:{"click":_vm.createGroup}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-plus-circle ")])],1)],1)]},proxy:true}],null,false,1379046785)})],1)],1)],1):_vm._e()],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"space-between"}},[(_vm.isEdit)?_c('v-col',{attrs:{"cols":"12","sm":"3","order":_vm.$vuetify.breakpoint.mobile ? 1 : 0}},[_c('AdsButton',{attrs:{"color":"error","buttonText":"Delete this contact","block":""},on:{"click":_vm.validateDelete}})],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('AdsButton',{staticStyle:{"border-radius":"4px"},attrs:{"primary":"","buttonText":_vm.isEdit ? 'Save' : 'Add contact',"block":""},on:{"click":_vm.validate}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }