<template>
  <div style="width: 100%">
    <AdsButton
      :buttonText="buttonText"
      icon="mdi-pencil"
      :primary="buttonText !== 'Use this template'"
      :secondary="buttonText === 'Use this template'"
      @click="showDialog = true"
      block
      :class="
        buttonText === 'Use this template'
          ? 'use-template-btn'
          : 'start_with_blank_btn'
      "
    />
    <v-dialog
      v-model="showDialog"
      fullscreen
      hide-overlay
      transition="slide-x-reverse-transition"
      content-class="new-message-dialog"
      persistent
    >
      <v-card class="rounded-0">
        <v-toolbar color="primary">
          <v-btn
            icon
            color="white"
            @click="showDialog = false"
            aria-label="Back"
          >
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title>
            <h1>New Message</h1>
          </v-toolbar-title>
        </v-toolbar>
        <BlankMessage
          :value="showDialog"
          :eventDel="eventDetails"
          :tempId="templateId"
          :close="close"
          :update="update"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import BlankMessage from '@/views/Message/BlankMessage/BlankMessage';
import { AdsButton } from '@nswdoe/doe-ui-core';

export default {
  name: 'FullScreenBlankMessageDialog',
  components: {
    BlankMessage,
    AdsButton,
  },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    templateId: {
      type: String,
      default: '',
    },
    eventDetails: {
      type: Object,
      default: () => {
        return {};
      },
    },
    close: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.new-message-dialog {
  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 10px;
    h1 {
      font-size: 20px;
    }
  }
}
</style>
