<template>
  <div>
    <AdsButton
      small
      :buttonText="buttonText"
      primary
      icon="mdi-square-edit-outline"
      @click="showDialog = true"
      class="mr-2"
      tertiary
      aria-label="Edit DEL contact"
    />
    <v-dialog
      v-model="showDialog"
      fullscreen
      persistent
      hide-overlay
      transition="slide-x-reverse-transition"
      content-class="del-dialog bg-grey"
    >
      <v-card class="rounded-0">
        <v-toolbar color="primary">
          <v-btn
            icon
            color="white"
            @click="showDialog = false"
            aria-label="Back"
          >
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title>
            <h1>DEL</h1>
          </v-toolbar-title>
        </v-toolbar>
        <DelContact
          :dialog-open="showDialog"
          @close-dialog="showDialog = false"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DelContact from '@/views/Settings/Contacts/DelContact';
import { AdsButton } from '@nswdoe/doe-ui-core';

export default {
  name: 'DelContactDialog',
  components: {
    AdsButton,
    DelContact,
  },
  props: {
    tempId: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    tertiary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },

  created() {},
};
</script>
<style lang="scss" scoped>
.del-dialog {
  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 10px;
    h1 {
      font-size: 20px;
    }
  }
}
</style>
