<template>
  <div>
    <v-container class="py-5">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
          class="text-right"
        >
          <!-- <AdsButton
            buttonText="New contact"
            primary
            icon="mdi-plus-circle-outline"
            @click="addContact()"
          /> -->
          <FullScreenContactDialog />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
        >
          <v-card>
            <div class="d-flex align-center justify-space-between">
              <div>
                <v-card-title class="mt-2">
                  <h2 class="notify_subheader_subname">
                    DEL
                  </h2>
                </v-card-title>
                <v-card-subtitle
                  style="word-break: break-all"
                  v-if="delContact"
                >
                  <div>{{ fullName }}</div>
                  <div>{{ delContact.email }}</div>
                  <div>{{ delContact.phone }}</div>
                </v-card-subtitle>
                <v-card-subtitle v-if="!delContact">
                  <span>TBD</span>
                </v-card-subtitle>
              </div>
              <DelContactDialog />
            </div>
            <v-divider />
            <v-card-title>
              <h2 class="notify_subheader_subname">
                School emergency response team
              </h2>
              <v-spacer />
              <!-- <v-btn
                icon
                compact
                @click="editContact('/emergencycontact')"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn> -->
              <EmergencyContactDialog />
            </v-card-title>
            <v-divider />
            <v-card-title
              :style="show ? { 'background-color': '#cbedfd' } : {}"
              :class="{ 'active-header': show }"
            >
              <h2 class="notify_subheader_subname">
                School staff
              </h2>
              <v-spacer />
              <v-btn
                icon
                @click="show = !show"
                aria-label="Expand for school staff options"
                :aria-expanded="show ? 'true' : 'false'"
              >
                <v-icon>
                  {{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <div v-show="show">
                <v-divider />
                <v-list>
                  <v-list-item
                    v-for="group in schoolStaffSubGroupList"
                    :key="group.id"
                    class="py-1"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="select">
                        {{ group.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon class="ma-0">
                      <v-btn
                        icon
                        @click="editCategoryMembers(group, 'staff')"
                        :aria-label="`Edit ${group.name} subgroup`"
                      >
                        <v-icon color="primary">
                          mdi-pencil-outline
                        </v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item>
                    <CreateSubGroupDialog subGroupType="staff" />
                  </v-list-item>
                </v-list>
              </div>
            </v-expand-transition>
            <v-divider />
            <v-card-title
              :style="showParents ? { 'background-color': '#cbedfd' } : {}"
              :class="{ 'active-header': showParents }"
            >
              <h2 class="notify_subheader_subname">
                Parents and carers
              </h2>
              <v-spacer />
              <v-btn
                icon
                @click="showParents = !showParents"
                aria-label="Expand for parents and carers options"
                :aria-expanded="showParents ? 'true' : 'false'"
              >
                <v-icon>
                  {{ showParents ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <div v-show="showParents">
                <v-divider />
                <v-card-text>
                  <strong>Note:</strong> Parents and carers are automatically
                  sorted by scholastic year and cannot be edited manually.
                </v-card-text>
              </div>
            </v-expand-transition>
            <v-divider />
            <v-card-title
              :style="showOther ? { 'background-color': '#cbedfd' } : {}"
              :class="{ 'active-header': showOther }"
            >
              <h2 class="notify_subheader_subname">
                Other contacts
              </h2>
              <v-spacer />
              <v-btn
                icon
                @click="expand('showOther')"
                aria-label="Expand for other contacts options"
                :aria-expanded="showOther ? 'true' : 'false'"
              >
                <v-icon>
                  {{ showOther ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <div v-show="showOther">
                <v-divider />
                <v-list>
                  <v-list-item
                    v-for="group in otherContactsSubGroupList"
                    :key="group.id"
                    class="py-1"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="select">
                        {{ group.name }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon class="ma-0">
                      <v-btn
                        icon
                        @click="editCategoryMembers(group, 'other')"
                        :aria-label="`Edit ${group.name} subgroup`"
                      >
                        <v-icon color="primary">
                          mdi-pencil-outline
                        </v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </div>
            </v-expand-transition>
          </v-card>
          <Dialog
            :openDialog="openDialog"
            @close="openDialog = false"
            persistent
            display-close-btn
            content-class="edit-dialog"
          >
            <template #title>
              <div class="v-card__title d-flex justify-space-between">
                <h2 class="title">
                  Edit subgroup
                </h2>
              </div>
            </template>
            <template #actions>
              <div class="px-6 d-flex flex-column">
                <AdsButton
                  primary
                  @click="handleSaveAndClose(subgroup)"
                  buttonText="Save and close"
                />
                <v-btn
                  text
                  color="error"
                  class="my-2"
                  @click="openDeleteDialog = true"
                >
                  <v-icon left>
                    mdi-delete-outline
                  </v-icon>
                  Delete this subgroup
                </v-btn>
              </div>
            </template>
            <template #text>
              <v-form
                ref="contactSubCategory"
                v-model="valid"
                lazy-validation
                onSubmit="return false"
              >
                <div class="px-6">
                  <v-text-field
                    ref="subgroupName"
                    outlined
                    background-color="white"
                    label="Subgroup name"
                    v-model="subgroup.name"
                    required
                    placeholder="Required"
                    :rules="[checkAllStaff, checkDuplicate, rules.required, rules.lengthCheck]"
                    persistent-placeholder
                    :aria-invalid="$refs.subgroupName && $refs.subgroupName.hasFocused && $refs.subgroupName.hasError ? 'true' : 'false'"
                    :append-icon="$refs.subgroupName && $refs.subgroupName.hasFocused && $refs.subgroupName.hasError ? 'mdi-alert-circle' : ''"
                    :aria-describedby="$refs.subgroupName && $refs.subgroupName.hasFocused && $refs.subgroupName.hasError ? 'subgroup-name-error' : false"
                  >
                    <template v-slot:message>
                      <div id="subgroup-name-error">
                        <template v-for="(message) in $refs.subgroupName.messagesToDisplay">
                          {{ message }}
                        </template>
                      </div>
                    </template>
                  </v-text-field>
                </div>
              </v-form>
            </template>
          </Dialog>
        </v-col>
      </v-row>
    </v-container>
    <EditSubGroupDialog
      :showDialog="showEditSubgroupDialog"
      :subGroup="subgroup"
      @closeDialog="showEditSubgroupDialog = false"
    />
    <Dialog
      :openDialog="openDeleteDialog"
      :title="`Delete ${subgroup.name}`"
      @close="openDeleteDialog = false"
      persistent
      display-close-btn
      content-class="edit-dialog"
    >
      <template #actions>
        <div class="px-6 pb-6 d-flex flex-column">
          <AdsButton
            color="error"
            @click="handleDeleteSubgroup(subgroup)"
          >
            Confirm delete
          </AdsButton>
          <AdsButton
            secondary
            @click="openDeleteDialog = false"
            class="mt-2"
          >
            Cancel
          </AdsButton>
        </div>
      </template>
      <template #text>
        <div class="px-6">
          <p>Are you sure you want to delete this subgroup?</p>
          <p>
            This will permanently delete the {{ subGroupMembers }} in this
            subgroup
          </p>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import CreateSubGroupDialog from '@/views/Settings/Contacts/CreateSubGroupDialog';
import DelContactDialog from '@/views/Settings/Contacts/DelContactDialog';
import EditSubGroupDialog from '@/views/Settings/Contacts/EditSubGroupDialog';
import EmergencyContactDialog from '@/views/Settings/Contacts/EmergencyContactDialog';
import FullScreenContactDialog from '@/views/Settings/Contacts/FullScreenContactDialog';
import { AdsButton, Dialog } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';
export default {
  name: 'ManageContacts',
  components: {
    AdsButton,
    Dialog,
    FullScreenContactDialog,
    DelContactDialog,
    EmergencyContactDialog,
    CreateSubGroupDialog,
    EditSubGroupDialog,
  },
  data: () => ({
    show: false,
    showParents: false,
    showOther: false,
    openDialog: false,
    openDeleteDialog: false,
    showEditSubgroupDialog: false,
    subgroup: {
      id: '',
      name: '',
      type: '',
    },
    cloneSubGroup: {},
    valid: true,
    subGroupNameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.length <= 30) || 'Name must be less than 30 characters',
    ],
    rules: {
      required: (v) => !!v || 'Name field is required',
      lengthCheck: (v) =>
        (v && v.length <= 30) || 'Name must be less than 30 characters',
    },
  }),

  methods: {
    checkDuplicate(val) {
      if (this.cloneSubGroup.name === val) {
        return true;
      }
      let res = [];
      if (this.subgroup.type === 'STAFF') {
        res = this.schoolStaffSubGroupList.filter((el) => el.name.toLowerCase() === val.toLowerCase());
      }
      if (this.subgroup.type === 'OTHER') {
        res = this.otherContactsSubGroupList.filter((el) => el.name.toLowerCase() === val.toLowerCase());
      }

      if (res && res.length > 0) {
        return `Name "${val}" already exists`;
      } else {
        return true;
      }
    },
    checkAllStaff(val) {
      if (val === 'All school staff') {
        return 'Please use another subgroup name'
      } else {
        return true;
      }
    },
    async updateSubGroup(subGroup) {
      const payload = {
        name: subGroup.name,
        type: subGroup.type,
      };
      this.$store.commit('SET_IS_LOADING', 'updateSubGroup', {
        root: true,
      });
      await this.$http
        .put(
          process.env.VUE_APP_API_BASE_URL +
            '/contacts/categories/' +
            subGroup.id,
          payload
        )
        .then((response) => {
          if (response.status === 200) {
            if (subGroup.type === 'STAFF') {
              this.$store.dispatch(
                'moduleManageContacts/fetchSchoolStaffContactSubGroups'
              );
            }
            if (subGroup.type === 'OTHER') {
              this.$store.dispatch(
                'moduleManageContacts/fetchOtherContactSubGroups'
              );
            }
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'updateSubGroup', {
            root: true,
          });
        });
    },
    async handleDeleteSubGroup(subGroup) {
      this.$store.commit('SET_IS_LOADING', 'deleteSubGroup', {
        root: true,
      });
      await this.$http
        .delete(
          process.env.VUE_APP_API_BASE_URL +
            `/contacts/categories/${subGroup.id}`
        )
        .then((response) => {
          if (response.status === 200) {
            if (subGroup.type === 'STAFF') {
              this.$store.dispatch(
                'moduleManageContacts/fetchSchoolStaffContactSubGroups'
              );
              this.$store.dispatch(
                'moduleManageContacts/fetchStaffSubGroupDetailsBySubGroupId',
                subGroup.id
              );
            }
            if (subGroup.type === 'OTHER') {
              this.$store.dispatch(
                'moduleManageContacts/fetchOtherContactSubGroups'
              );
              this.$store.dispatch(
                'moduleManageContacts/fetchOtherContactBySubGroupId',
                subGroup.id
              );
            }
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'deleteSubGroup', {
            root: true,
          });
          this.openDialog = false;
        });
    },
    editContact(routePath) {
      this.$router.push({
        path: routePath,
      });
    },
    showDashboard() {
      this.$router.push({
        path: '/',
      });
    },
    editContactById(subGroupId) {
      this.$router.push({
        path: `/schoolstaffcontact/${subGroupId}`,
      });
    },
    editSubGroup(sg) {
      this.subgroup = { ...sg };
      this.cloneSubGroup = { ...sg };
      this.openDialog = true;
    },
    async handleSaveAndClose(subgroup) {
      if (this.$refs.contactSubCategory.validate()) {
        await this.updateSubGroup(subgroup);
        this.openDialog = false;
      }
    },
    async handleDeleteSubgroup(subgroup) {
      if (this.$refs.contactSubCategory.validate()) {
        await this.handleDeleteSubGroup(subgroup);
        this.openDeleteDialog = false;
        this.openDialog = false;
      }
    },
    editCategoryMembers(group) {
      this.subgroup = { ...group };
      this.showEditSubgroupDialog = true;
    },
    expand(group) {
      this[group] = !this[group]
    }
  },
  computed: {
    ...mapGetters({
      delContact: 'moduleManageContacts/getDelContact',
      school: 'moduleDashboard/getSelectedSchool',
      schoolStaffSubGroupList: 'moduleManageContacts/getSchoolStaffSubGroup',
      schoolStaffSubGroupDetails: 'moduleManageContacts/schoolStaffSubGroupDetails',
      otherContactsSubGroupList:
        'moduleManageContacts/getOtherContactsSubGroup',
      otherContactsSubGroupDetails:
        'moduleManageContacts/otherContactsSubGroupDetails',
    }),
    fullName() {
      if (this.delContact) {
        return this.delContact.first_name.trim() + ' ' + this.delContact.last_name.trim();
      } else {
        return ''
      }
    },
    subGroupMembers() {
      if (this.subgroup) {
        let groupMembers = {};
        if (this.subgroup.type === 'STAFF') {
          groupMembers = this.schoolStaffSubGroupDetails
        } else {
          groupMembers = this.otherContactsSubGroupDetails
        }

        return `${groupMembers.length} contact${
          groupMembers.length > 1 ? 's' : ''
        }`;
      }
      return '0 contacts';
    },
  },
  mounted() {
    this.$store.dispatch('moduleManageContacts/fetchOtherContactsList');
  },
  created() {},
  watch: {
    show(newValue) {
      if (newValue) {
        this.$store.dispatch(
          'moduleManageContacts/fetchSchoolStaffContactSubGroups'
        );
      }
    },
    showOther(newValue) {
      if (newValue) {
        this.$store.dispatch('moduleManageContacts/fetchOtherContactSubGroups');
      }
    },
    showEditSubgroupDialog(newValue) {
      if (newValue) {
        if (this.subgroup.type === 'STAFF') {
          this.$store.dispatch(
            'moduleManageContacts/fetchSchoolStaffContactsList'
          );
          this.$store.dispatch(
            'moduleManageContacts/fetchStaffSubGroupDetailsBySubGroupId',
            this.subgroup.id
          );
        }
        if (this.subgroup.type === 'OTHER') {
          this.$store.dispatch(
            'moduleManageContacts/fetchOtherContactBySubGroupId',
            this.subgroup.id
          );
          this.$store.dispatch('moduleManageContacts/fetchOtherContactsList');
        }
      }
    },
    openDialog(newValue) {
      if (newValue) {
        if (this.subgroup.type === 'STAFF') {
          this.$store.dispatch(
            'moduleManageContacts/fetchSchoolStaffContactsList'
          );
          this.$store.dispatch(
            'moduleManageContacts/fetchStaffSubGroupDetailsBySubGroupId',
            this.subgroup.id
          );
        }
        if (this.subgroup.type === 'OTHER') {
          this.$store.dispatch(
            'moduleManageContacts/fetchOtherContactBySubGroupId',
            this.subgroup.id
          );
          this.$store.dispatch('moduleManageContacts/fetchOtherContactsList');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-wrapper {
  height: 100%;
}
.container-main {
  max-width: 814px !important;
}
.active-header {
  .v-btn:focus {
    &::before {
      opacity: 1;
    }
    i {
      color: white;
    }
  }
}
</style>
