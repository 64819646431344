<template>
  <div class="container-wrapper">
    <v-container class="py-2">
      <div v-if="activeMessages.length === 0">
        <MessageNotFound messageStatus="active" />
      </div>
      <div v-else>
        <Message :messages="activeMessages" />
      </div>
    </v-container>
  </div>
</template>
<script>
import Message from '@/components/MessageCard/Message';
import MessageNotFound from '@/components/MessageCard/MessageNotFound';
import { mapGetters } from 'vuex';
export default {
  name: 'ActiveMessage',
  components: {
    Message,
    MessageNotFound,
  },
  computed: {
    ...mapGetters({
      activeMessages: 'moduleDashboard/activeMessages',
    }),
  },
};
</script>

<style lang="scss" scoped>
.container-wrapper,
.container-main {
  border-radius: 10px 10px 0px 0px;
  background: var(--greys-white, #fff);
}
</style>
