export default [
      {
        name: 'Head teachers',
        id: '897',
        members: [{
          id: '123a',
          name: 'Jason Oner',
        },
        {
          name: 'Tony Carlson',
          id: '8907b'
        },
        {
          name: 'Mike Smith',
          id: '89017c'
        }]
      },
  ];