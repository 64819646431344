<template>
  <div>
    <AdsButton
      small
      :buttonText="buttonText"
      primary
      icon="mdi-square-edit-outline"
      @click="showDialog = true"
      tertiary
    />
    <v-dialog
      v-model="showDialog"
      fullscreen
      persistent
      hide-overlay
      transition="slide-x-reverse-transition"
      content-class="edit-contact-dialog bg-grey"
    >
      <v-card class="rounded-0">
        <v-toolbar color="primary">
          <v-btn
            icon
            color="white"
            @click="showDialog = false"
            aria-label="Back"
          >
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title>
            <h1>Edit contact</h1>
          </v-toolbar-title>
        </v-toolbar>
        <CreateNewContact
          isEdit
          :contact="contact"
          @close-dialog="showDialog = false"
        />
      </v-card>
    </v-dialog>
  </div>

  <!-- <FullscreenDialog
    title="Edit contact"
    v-model="showDialog"
  >
    <template v-slot:activator>
      <AdsButton
        small
        :buttonText="buttonText"
        primary
        icon="mdi-square-edit-outline"
        @click="showDialog = true"
        tertiary
      />
    </template>
    <template v-slot:content>
      <CreateNewContact
        isEdit
        :contact="contact"
        @close-dialog="showDialog = false"
      />
    </template>
  </FullscreenDialog> -->
</template>
<script>
import CreateNewContact from '@/views/Settings/Contacts/CreateNewContact';
import { AdsButton } from '@nswdoe/doe-ui-core';

export default {
  name: 'EditContactDialog',
  components: {
    AdsButton,
    CreateNewContact,
  },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    tertiary: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },

  created() {},
  watch: {
    showDialog(newValue) {
      if (newValue) {
        this.$store.dispatch('moduleManageContacts/fetchOtherContactSubGroups');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-contact-dialog {
  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 10px;
    h1 {
      font-size: 20px;
    }
  }
}
</style>
