export default [
    {
        'created_on': '',
        'name':'adasd',
        'id': 'emergency1233',
        'description': 'This template to notify Cyclone emergency dfgdfgdfgdfgdf ',
        'title': 'Cyclone Notification',
        'created_by': '',
        'category': {
            'categoryId' : '',
            'name':''
        }
    },
  ];