import mockTemplateDetails from '../data/templatedetails';
import mockTemplates from '../data/templates';
import mockTemplatesData from '../data/templatesdata';


//const fetchHistoryById = (url, config = {}) => get(url, config);
//const fetchCheckListById = (url, config = {}) => get(url, config);

/* eslint no-console: "off" */
const fetchTemplates = () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockTemplates);
    }, 100);
  });
};

const fetchTemplatesByCategoryId = () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockTemplatesData);
    }, 100);
  });
};

const fetchTemplateById = () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockTemplateDetails);
    }, 100);
  });
};


export default {
    fetchTemplates,
    fetchTemplatesByCategoryId,
    fetchTemplateById,
};