// Vuex Recipients module
const moduleRecipients = {
  namespaced: true,
  state: {
    recipients: [],
    selection: [],
  },
  getters: {
    getRecipientsByName: (state) => (name) => {
      return state.recipients.find((item) => item.name === name);
    },
    getRecipientSelectedChannels: (state) => (name) => {
      return state.recipients.find(
        (item) => item.name === name && item.selected === true
      );
    },
    getTotalRecipient: (state) => (name) => {
      var channels = [];
      for (const cName of state.recipients) {
        if (cName.name == name) {
          for (const c of cName.channels)
            if (c.selected) {
              channels.push(c.name);
            }
        }
      }
      return channels.length > 0 ? true : false;
    },

    getDelRecipients: (state) =>
      state.recipients.find((recipient) => recipient.name === 'DEL'),
    getEmergencyRecipients: (state) =>
      state.recipients.find((recipient) => recipient.name === 'ERT'),
    getParentsRecipients: (state) =>
      state.recipients.find((recipient) => recipient.name === 'PARENT'),
    getStaffRecipients: (state) =>
      state.recipients.find((recipient) => recipient.name === 'STAFF'),
    getOtherContactsRecipients: (state) =>
      state.recipients.find((recipient) => recipient.name === 'OTHER'),
    getChecked: (state) => state.isChecked,
    getSelection: (state) => state.selection,
  },
  mutations: {
    SET_RECIPIENTS(state, recipients) {
      state.recipients = recipients;
    },
    SET_DEL_CHECKED(state, val) {
      state.recipients.del.selected = val;
    },
    SET_EMERGENCY_CHECKED(state, val) {
      state.recipients.emergency.selected = val;
    },
    SET_STAFF_CHECKED(state, val) {
      state.recipients.staff.selected = val;
    },
    SET_DEL_CHANNELS_SMS(state, val) {
      state.recipients.del.channels.sms = val;
    },
    SET_DEL_CHANNELS_TEAM(state, val) {
      state.recipients.del.channels.team = val;
    },
    SET_DEL_ALL_CHANNELS(state, val) {
      state.recipients.del.channels = val;
    },
    SET_DEL_CHANNELS(state, ch) {
      var delchannels = [];
      let resp = state.recipients.del.channels;
      for (const item of resp) {
        if (ch.cName == item.name) {
          item.selected = ch.val;
        }
        delchannels.push(item);
      }
      state.recipients.del.channels = delchannels;
    },
    SET_UPDATE_SELECTION(state, name, val) {
      var delchannels = [];
      let resp = state.selection;
      for (const i of resp) {
        if (name == resp.name) {
          i.selected = val;
        }
        delchannels.push(i);
      }
      state.selection = delchannels;
    },

    SET_EMERGENCY_CHANNELS(state, ch) {
      const channels = mapChToState(state.recipients.emergency.channels, ch);
      // let resp = state.recipients.emergency.channels;
      // for(const item of resp) {
      //   if(ch.cName == item.name){
      //     item.selected = ch.val;
      //   }
      //   echannels.push(item);
      // }
      state.recipients.emergency.channels = channels;
    },
    SET_STAFF_SUB_CHANNEL(state, ch) {
      var echannels = [];
      let resp = state.recipients.staff.subname;
      for (const item of resp) {
        if (ch.key === item.name) {
          for (const i of item.channels) {
            if (ch.cName === i.name) i.selected = ch.val;
          }
        }
        echannels.push(item);
      }
      state.recipients.staff.subname = echannels;
    },
  },
  actions: {
    fetchRecipientByTemplateId({ commit }, schoolDetails) {
      commit('SET_RECIPIENTS', schoolDetails.recipients);
    },
    updateSelectedChannel({ commit }, ch) {
      switch (ch.key) {
        case 'DEL':
          commit('SET_DEL_CHANNELS', ch);
          return;
        case 'EMERGENCY':
          commit('SET_EMERGENCY_CHANNELS', ch);
          return;
      }
    },
    updateSTAFFSelectedChannel({ commit }, ch) {
      commit('SET_STAFF_SUB_CHANNEL', ch);
    },
    updateRecipients({ commit }, recipients) {
      commit('SET_RECIPIENTS', recipients);
    },
  },
};

const mapChToState = (channels, ch) => {
  var echannels = [];
  for (const item of channels) {
    if (ch.cName == item.name) {
      item.selected = ch.val;
    }
    echannels.push(item);
  }
  return echannels;
};

export default moduleRecipients;
