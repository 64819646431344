import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import moduleDashboard from './modules/dashboard';
import moduleEventDetails from './modules/eventdetails';
import moduleManageContacts from './modules/managecontacts';
import moduleManageTemplates from './modules/managetemplates';
import moduleRecipients from './modules/recipients';
import moduleTemplates from './modules/templates';
import moduleUserProfile from './modules/userProfile';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    moduleDashboard,
    moduleEventDetails,
    moduleTemplates,
    moduleRecipients,
    moduleManageContacts,
    moduleManageTemplates,
    moduleUserProfile,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    hasError: false,
    loading: [],
  },
  getters: {
    loading: (state) => (state.loading.length ? true : false),
  },
  mutations: {
    SET_IS_LOADING: function (state, item) {
      state.loading.push(item);
    },
    REMOVE_IS_LOADING: function (state, item) {
      state.loading = state.loading.filter((loading) => {
        return loading !== item;
      });
    },
    RESET_IS_LOADING: function (state) {
      state.loading = [];
    },
    SET_HAS_ERROR(state, hasError) {
      state.hasError = hasError;
    },
  },
  actions: {},
});
