<template>
  <div style="width: 100%">
    <v-btn
      :id="item.event_version"
      icon
      @click="viewEvent(item)"
      :aria-label="`View ${item.title} details`"
    >
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
    <v-dialog
      v-model="showDialog"
      fullscreen
      persistent
      hide-overlay
      transition="slide-x-reverse-transition"
      content-class="update-message-dialog bg-grey"
    >
      <v-card class="rounded-0">
        <v-toolbar color="primary">
          <v-btn
            icon
            color="white"
            @click="showDialog = false"
            aria-label="Back"
          >
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title>
            <h1>Message details</h1>
          </v-toolbar-title>
        </v-toolbar>
        <ShowEventDetails :isEmergency="isEmergency" />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ShowEventDetails from '@/views/EventDetails/ShowEventDetails';
import { mapGetters } from 'vuex';

export default {
  name: 'EventDetailsDialog',
  components: {
    ShowEventDetails,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isEmergency: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      updateTemplate: 'moduleTemplates/getUpdateTemplate',
    }),
  },
  created() {},
  methods: {
    onUpdate() {
      this.$store.dispatch(
        'moduleTemplates/updateSelectedTemplate',
        this.updateTemplate
      );
      this.showDialog = true;
    },
    viewEvent(selectedEvent) {
      this.$store.dispatch(
        'moduleEventDetails/updateSelectedMessage',
        selectedEvent
      );
      this.showDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.update-message-dialog {
  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 10px;
    h1 {
      font-size: 20px;
    }
  }
}
</style>
