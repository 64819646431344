export default [
  {
    'category': 'Other Templates',
    'name': 'Other Templates',
    'id': 'Other Templates',
    'items': [
      {
        'name': 'Parent Interview',
        'type': 'Other Templates',
        'catId': '1698800529548',
        'total': 0
      }
    ]
  },
  {
    'category': 'Emergencies',
    'name': 'Emergencies',
    'id': 'Emergencies',
    'items': [
      {
        'name': 'Update templates',
        'type': 'Emergencies',
        'catId': 'C002',
        'total': 1
      },
      {
        'name': 'Initiate templates',
        'type': 'Emergencies',
        'catId': 'C001',
        'total': 2
      },
      {
        'name': 'Flood Emergencies',
        'type': 'Emergencies',
        'catId': '1698797305782',
        'total': 0
      },
      {
        'name': 'Close templates',
        'type': 'Emergencies',
        'catId': 'C003',
        'total': 0
      }
    ]
  }
];