import _ from 'lodash';



// Vuex UserProfile module
const moduleUserProfile = {
  namespaced: true,
  state: {
    userProfile: {},
    userAvatar: undefined,
  },
  getters: {
    userProfile: (state) => state.userProfile,
    userAvatar: (state) => state.userAvatar,
    userInitialName: (state) => {
      if (!_.isEmpty(state.userProfile)) {
        return `${
          state.userProfile.FirstName.charAt(0) +
          state.userProfile.LastName.charAt(0)
        }`;
      }
    },
  },
  mutations: {
    SET_PROFILE(state, profile) {
      state.userProfile = profile;
    },

    setAvatar(state, avatar) {
      state.userAvatar = avatar;
    },
  },
  actions: {
    fetchAvatar({ commit, state }) {
      if (state.userAvatar === undefined) {
        return this._vm.$http
          .get('/api/user/profile/avatar', { responseType: 'blob' })
          .then((response) => {
            const avatarSrc = URL.createObjectURL(response.data);
            commit('setAvatar', avatarSrc);
          })
          .catch(() => {
            commit('setAvatar', null);
          });
      }
    },
    updateUserProfile({ commit}, profile) {
      commit('SET_PROFILE', profile);
    },
  },
};

export default moduleUserProfile;
