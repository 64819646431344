
import mockDelContact from '../data/delcontact';
import mockEmergencyContacts from '../data/emergencycontacts';
import mockEmergencyTeam from '../data/emergencyteam';
import mockSchoolStaffSubGroups from '../data/schoolstaffcontactgroup';
import mockSchoolStaffSubGroupDetails from '../data/schoolstaffcontactgroupdetails';


//const fetchHistoryById = (url, config = {}) => get(url, config);
//const fetchCheckListById = (url, config = {}) => get(url, config);

/* eslint no-console: "off" */
const fetchDelContacts = () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockDelContact);
    }, 100);
  });
};
const fetchEmergencyContacts = () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockEmergencyContacts);
    }, 100);
  });
};

const fetchSchoolStaffContacts = () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockEmergencyContacts);
    }, 100);
  });
};


const fetchEmergencyTeamMembers = () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockEmergencyTeam);
    }, 100);
  });
};

const fetchSchoolStaffSubGroups = () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockSchoolStaffSubGroups);
    }, 100);
  });
};

const fetchSchoolStaffSubGroupById = () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockSchoolStaffSubGroupDetails);
    }, 100);
  });
};

const fetchOtherContactsSubGroups = () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockSchoolStaffSubGroups);
    }, 100);
  });
};

const fetchOtherContactsSubGroupById = () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockSchoolStaffSubGroupDetails);
    }, 100);
  });
};

const fetchOtherContacts = () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockEmergencyContacts);
    }, 100);
  });
};

export default {
    fetchDelContacts,
    fetchEmergencyContacts,
    fetchEmergencyTeamMembers,
    fetchSchoolStaffSubGroups,
    fetchSchoolStaffSubGroupById,
    fetchSchoolStaffContacts,
    fetchOtherContactsSubGroups,
    fetchOtherContactsSubGroupById,
    fetchOtherContacts,
    // fetchTemplateById,
    // fetchRecipientsByTemplateId,
};