
import mockManageTemplateMetaData from '../data/managetemplates';


//const fetchHistoryById = (url, config = {}) => get(url, config);
//const fetchCheckListById = (url, config = {}) => get(url, config);

/* eslint no-console: "off" */

const fetchTemplateDataById = () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockManageTemplateMetaData);
    }, 100);
  });
};



export default {
  fetchTemplateDataById
};