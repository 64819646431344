<template>
  <div
    class="light10 fill-height"
    style="width: 100%"
  >
    <Toolbar />
    <div>
      <TabNavigation
        :grow="$vuetify.breakpoint.xlAndDown"
        mobile-breakpoint="xs"
        :items="tabs"
        slider-color="#D7153A"
        slider-size="4"
      >
        <template v-slot:content1>
          <ActiveMessage />
        </template>

        <template v-slot:content2>
          <ClosedMessage />
        </template>
      </TabNavigation>
    </div>
  </div>
</template>

<script>
import ActiveMessage from '@/views/Dashboard/ActiveMessages/ActiveMessage';
import ClosedMessage from '@/views/Dashboard/ClosedMessages/ClosedMessage';
import Toolbar from '@/views/Dashboard/Toolbar/Toolbar';
import { TabNavigation } from '@nswdoe/doe-ui-core';
import jwt_decode from 'jwt-decode';

export default {
  name: 'Dashboard',
  /**
   *  The title option is used by titleMixin for implementing dynamic <title> in SPA for accessibility
   *  Recommended title for each 'view/page' is 'Page name - Site Name'.
   */
  title: 'Dashboard - School Notify',
  data() {
    return {
      loading: false,
      tabs: [{ tab: 'Active' }, { tab: 'Closed' }],
    };
  },
  components: {
    //Banner,
    ActiveMessage,
    ClosedMessage,
    TabNavigation,
    Toolbar,
  },
  methods: {},
  created() {
    var decoded_accessToken = jwt_decode(this.$OAuth.data.accessToken);
    var decoded = jwt_decode(this.$OAuth.data.idToken);
    const profile = {
      firstName: decoded_accessToken.given_name,
      lastName: decoded_accessToken.family_name,
      email: this.$OAuth.data.profile.email,
      locations: decoded?.locations,
    };
    this.$store.dispatch('moduleUserProfile/updateUserProfile', profile);
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-main__wrap {
    display: flex;
  }
  .v-tab {
    text-align: center;
    width: 100%;
    padding: 5px 2px;
  }
  .v-card_title {
    padding: 0px;
  }

  .v-tab-navigation {
    .v-slide-group__content {
      &::after {
        position: absolute;
        content: '';
        height: 1px;
        width: 100%;
        bottom: 0;
        background-color: #e0e0e0;
      }
    }
  }
}
</style>
