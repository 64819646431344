export default [
     {
        id: '345',
        name: 'Non-teaching staff',
      },
      {
        name: 'Head teachers',
        id: '897'
      },
      {
        name: 'Heads of department',
        id: '89017c'
      }
  ];