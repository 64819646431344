<template>
  <div>
    <p class="text-h5 mb-8">
      Opt out of School Notify notifications for <b>{{ schoolName ? schoolName : '...loading' }}</b>
    </p>
    <template v-if="!success && !alreadyComplete">
      <p class="mb-8 font-weight-bold">
        Complete the fields below to opt out of non-emergency
        <span v-if="contactType === 'e'"> email </span>
        <span v-if="contactType === 'p'"> SMS </span>
        notifications
      </p>
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-row>
          <v-col
            cols="12"
            sm="8"
            md="6"
          >
            <AdsTextField
              outlined
              label="Your email address"
              v-if="contactType === 'e'"
              v-model="email"
              required
              placeholder="Required"
              persistent-hint
              hint="This is used only to correctly remove you from the notification list and will never be shared with any third party"
              :rules="[rules.required('Enter your email address')]"
            />
            <AdsTextField
              outlined
              label="Your mobile number"
              v-if="contactType === 'p'"
              v-model="phone"
              required
              placeholder="Required"
              persistent-hint
              hint="This is used only to correctly remove you from the notification list and will never be shared with any third party"
              :rules="[rules.required('Enter your mobile number')]"
            />
          </v-col>
        </v-row>
        <AdsButton
          button-text="Submit"
          class="mt-5"
          type="submit"
          :disabled="loading"
        />
      </v-form>
      <p
        class="red--text mt-5"
        v-if="invalid"
      >
        The <span v-if="contactType === 'e'"> email </span> <span v-if="contactType === 'p'"> mobile number </span>
        you entered does not match our records.
      </p>
      <p
        class="red--text mt-5"
        v-if="error"
      >
        Something went wrong, please try again later
      </p>
    </template>
    <template v-if="success">
      <div class="d-flex align-center">
        <div class="mr-4">
          <v-icon
            color="error"
            size="60"
          >
            mdi-cancel
          </v-icon>
        </div>
        <div>
          <p class="mb-0">
            You have been opted out of all non-emergency
            <span v-if="contactType === 'e'"> email </span> <span v-if="contactType === 'p'"> SMS </span>
            communication from <b>{{ schoolName }}</b>
          </p>
          <p class="mb-0">
            You can close this page now
          </p>
        </div>
      </div>
    </template>
    <template v-if="alreadyComplete">
      We're sorry, this form has expired
    </template>
  </div>
</template>

<script>
import { AdsButton, AdsTextField } from '@nswdoe/doe-ui-core';
import api from '@/api/index.js'
const valueLookup = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

const getSchoolCode = (optOutId) => {
  if(optOutId && optOutId.length > 3) {
    const value = valueLookup.indexOf(optOutId.charAt(0)) * Math.pow(valueLookup.length, 2)
      + valueLookup.indexOf(optOutId.charAt(1)) * valueLookup.length
      + valueLookup.indexOf(optOutId.charAt(2));
    return value >> 1;
  }
  return null;
}

export default {
  name: 'ParentCarer',
  title: 'Manage School Notify notifications',
  components: {
    AdsButton,
    AdsTextField
  },
  data() {
    return {
      phone: '',
      email: '',
      optOutId: '',
      schoolName: null,
      success: false,
      alreadyComplete: false,
      invalid: false,
      error: false,
      loading: false,
      rules: {
        required: message => value => !!value || message,
      }
    }
  },
  created() {
    const optOutId = this.$route.fullPath.split('?')[1];
    this.$set(this, 'optOutId', optOutId);
    const schoolCode = getSchoolCode(optOutId);
    if (schoolCode) {
      api.notifications.fetchSchoolDetails(schoolCode)
      .then((response) => {
        this.schoolName = response.data.name
      })
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        api.notifications.optOutCarer(this.email, this.phone, this.optOutId)
        .then((response) => {
          if (response.status === 204) {
            this.alreadyComplete = true;
            this.loading = false;
          } else {
            this.success = true;
            this.loading = false;
          }
        })
        .catch((error) => {
          if(error.response && error.response.status === 400) {
            this.invalid = true;
          } else {
            this.error = true;
          }
          this.loading = false;
        })
      }
    }
  },
  computed: {
    schoolCode() {
      return getSchoolCode(this.optOutId);
    },
    contactType() {
      if(this.$data.optOutId && this.$data.optOutId.length > 3) {
        const value = valueLookup.indexOf(this.$data.optOutId.charAt(2));
        if(value % 2 === 0) {
          return 'e';
        } else {
          return 'p';
        }
      }
      return '';
    }
  }
}
</script>

<style lang='scss' scoped>
 .header-toolbar {
  background-color: #002664
 }
</style>
