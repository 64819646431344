<template>
  <div>
    <p class="text-h5 mb-8">
      Manage School Notify notifications for <b>{{ schoolName ? schoolName : '...loading' }}</b>
    </p>
    <template v-if="!success && !alreadyComplete">
      <p class="mb-8">
        Please choose from the options below:
      </p>
      <v-radio-group v-model="radioGroup">
        <v-radio
          label="I would like to receive notifications"
          value="In"
          class="mb-5"
        >
          <template v-slot:label>
            <div
              class="ml-2"
              style="color: #121212"
            >
              I would like to receive notifications
            </div>
          </template>
        </v-radio>
        <v-radio
          label="I would not like to receive notifications"
          value="Out"
        >
          <template v-slot:label>
            <div
              class="ml-2"
              style="color: #121212"
            >
              I would <b>not</b> like to receive notifications
            </div>
          </template>
        </v-radio>
      </v-radio-group>
      <AdsButton
        button-text="Submit"
        class="mt-5"
        @click="submit"
        :disabled="loading"
      />
      <p
        class="red--text mt-5"
        v-if="error"
      >
        Something went wrong, please try again later
      </p>
    </template>
    <template v-if="success">
      <div
        class="d-flex align-center"
        v-if="radioGroup === 'In'"
      >
        <div class="mr-4">
          <v-icon
            color="green"
            size="60"
          >
            mdi-check-circle
          </v-icon>
        </div>
        <div>
          <p class="mb-0">
            You have opted in to receive communication from <b>{{ schoolName }}</b>
          </p>
          <p class="mb-0">
            You can close this page now
          </p>
        </div>
      </div>
      <div
        class="d-flex align-center"
        v-else
      >
        <div class="mr-4">
          <v-icon
            color="error"
            size="60"
          >
            mdi-cancel
          </v-icon>
        </div>
        <div>
          <p class="mb-0">
            You have been opted out of all communication from <b>{{ schoolName }}</b>
          </p>
          <p class="mb-0">
            You can close this page now
          </p>
        </div>
      </div>
    </template>
    <template v-if="alreadyComplete">
      We're sorry, this form has expired
    </template>
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core';
import api from '@/api/index.js'
export default {
  name: 'Other',
  title: 'Manage School Notify notifications',
  components: {
    AdsButton,
  },
  data() {
    return {
      radioGroup: 'In',
      schoolName: null,
      success: false,
      alreadyComplete: false,
      error: false,
      loading: false,
      other: false,
      parent: false
    }
  },
  created() {
    if (this.$route.query.schoolCode) {
      api.notifications.fetchSchoolDetails(this.$route.query.schoolCode)
      .then((response) => {
        this.schoolName = response.data.name
      })
      api.notifications.fetchContactDetails(this.$route.query.contactId, this.$route.query.schoolCode)
        .then(response => {
          const contacts = response.data;
          this.alreadyComplete = contacts.length === 0 || (Date.now() - contacts[0].createdAt) > (1000 * 60 * 60 * 24);
        })
    }
  },
  methods: {
    submit() {
      this.loading = true;
      api.notifications.optInOutOther(this.$route.query.contactId, this.$route.query.schoolCode, this.radioGroup)
      .then((response) => {
        if (response.status === 204) {
          this.alreadyComplete = true;
          this.loading = false;
        } else {
          this.success = true;
          this.loading = false;
        }
      })
      .catch(() => {
        this.error = true;
        this.loading = false;
      })
    }
  }
}
</script>

<style lang='scss' scoped>
 .header-toolbar {
  background-color: #002664
 }
</style>
