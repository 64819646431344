<template>
  <div class="light10">
    <TabNavigation
      :grow="$vuetify.breakpoint.xlAndDown"
      mobile-breakpoint="xs"
      :items="tabs"
      slider-color="#D7153A"
      slider-size="4"
      fixedTabs
      v-if="!this.event?.status === 'Closed' || this.event?.is_emergency"
    >
      <template v-slot:content1>
        <CheckList />
      </template>

      <template v-slot:content2>
        <History />
      </template>
    </TabNavigation>
    <History v-else />
  </div>
</template>

<script>
import CheckList from '@/views/EventDetails/CheckList';
import History from '@/views/EventDetails/History';
import { TabNavigation } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';

export default {
  name: 'CheckListAndHistory',
  data() {
    return {
      loading: false,
    };
  },
  components: {
    TabNavigation,
    CheckList,
    History,
  },
  computed: {
    ...mapGetters({
      event: 'moduleDashboard/getSelectedEvent',
    }),
    tabs() {
      return [{ tab: 'CHECKLIST' }, { tab: 'HISTORY' }];
    },
  },
  methods: {
    disableCheckListTab() {
      this.showCheckList = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-tab {
    text-align: left;
    width: 100%;
    padding: 5px 2px;
  }
  .v-tab {
    margin-left: initial !important;
  }
}
</style>
