import mockCheckList from '../data/checklist';
import { get } from '../http';


const fetchHistoryById = (url, config = {}) => get(url, config);
const fetchCheckListById = (url, config = {}) => get(url, config);

/* eslint no-console: "off" */
const fetchEvents = () => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockCheckList);
    }, 100);
  });
};

// const fetchCheckListById = () => {
//   return new Promise(function (resolve) {
//     setTimeout(function () {
//       resolve(mockCheckList);
//     }, 100);
//   });
// };

// const fetchHistoryById = () => {
//   return new Promise(function (resolve) {
//     setTimeout(function () {
//       resolve(mockHistory);
//     }, 100);
//   });
// };

export default {
    fetchEvents,
    fetchCheckListById,
    fetchHistoryById
};