<template>
  <div
    class="container-wrapper"
    style="background: #f4f4f7"
  >
    <v-container class="py-5">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-row>
              <v-col cols="12">
                <h2 class="notify-title-text">
                  Subgroup Name:
                </h2>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  ref="subgroupName"
                  outlined
                  background-color="white"
                  label="Subgroup name"
                  v-model="formData.name"
                  required
                  placeholder="Required"
                  :rules="[checkAllStaff, checkDuplicate, rules.required, rules.lengthCheck]"
                  persistent-placeholder
                  :aria-invalid="
                    $refs.form &&
                      $refs.form.inputs[0].hasFocused &&
                      $refs.form.inputs[0].hasError
                      ? 'true'
                      : 'false'
                  "
                  :append-icon="
                    $refs.form &&
                      $refs.form.inputs[0].hasFocused &&
                      $refs.form.inputs[0].hasError
                      ? 'mdi-alert-circle'
                      : ''
                  "
                  :aria-describedby="
                    $refs.form &&
                      $refs.form.inputs[0].hasFocused &&
                      $refs.form.inputs[0].hasError
                      ? 'subgroup-name-error'
                      : false
                  "
                  hide-details="auto"
                >
                  <template v-slot:message>
                    <div id="subgroup-name-error">
                      <template
                        v-for="message in $refs.subgroupName.messagesToDisplay"
                      >
                        {{ message }}
                      </template>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <span class="notify-title-text">Contacts:</span>
              </v-col>
              <v-col cols="12">
                <v-card>
                  <div class="pl-4 py-2 pr-1">
                    <v-text-field
                      solo
                      prepend-icon="mdi-magnify grey--text"
                      label="Search for a contact"
                      v-model="searchBarValue"
                      hide-details
                      flat
                      clearable
                      role="combobox"
                      :aria-expanded="
                        searchBarValue &&
                          searchBarValue.length > 0 &&
                          filteredContacts &&
                          filteredContacts.length
                          ? 'true'
                          : 'false'
                      "
                      autocomplete="off"
                      aria-activedescendant="contacts-available"
                    />
                  </div>
                  <v-divider />
                  <v-list
                    v-if="
                      !searchBarValue ||
                        (searchBarValue && searchBarValue.length === 0)
                    "
                    class="py-0"
                  >
                    <v-list-item
                      v-for="member in sortBy(fullMembersList, (m) =>
                        fullName(m).toLocaleLowerCase()
                      )"
                      :key="member.staff_id"
                      class="py-1"
                      @click="removeMember(member, true)"
                      aria-selected="true"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ fullName(member) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon color="primary">
                          mdi-minus-circle-outline
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                  <v-list
                    v-else-if="
                      searchBarValue &&
                        searchBarValue.length > 0 &&
                        filteredContacts &&
                        filteredContacts.length
                    "
                    class="py-0"
                    id="contacts-available"
                  >
                    <v-list-item
                      v-for="contact in filteredContacts"
                      :key="`search-${contact.staff_id}`"
                      class="py-1"
                      @click="addMember(contact)"
                      :aria-selected="
                        alreadyAdded(contact.staff_id) ? 'true' : 'false'
                      "
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ fullName(contact) }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon
                          v-if="alreadyAdded(contact.staff_id)"
                          color="primary"
                        >
                          mdi-check-circle
                        </v-icon>
                        <v-icon
                          v-else
                          color="primary"
                        >
                          mdi-check-circle-outline
                        </v-icon>
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                  <v-list
                    v-else-if="
                      searchBarValue &&
                        searchBarValue.length > 0 &&
                        filteredContacts &&
                        filteredContacts.length === 0
                    "
                  >
                    <v-list-item>
                      <strong>Contact not found</strong>
                    </v-list-item>
                  </v-list>
                  <v-list
                    v-if="
                      searchBarValue &&
                        searchBarValue.length === 0 &&
                        fullMembersList &&
                        fullMembersList.length === 0
                    "
                  >
                    <v-list-item>
                      <strong>Members not found</strong>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col
          cols="12"
          sm="10"
        >
          <v-row justify="end">
            <v-col
              cols="12"
              sm="3"
            >
              <AdsButton
                primary
                buttonText="Done"
                @click="validate"
                block
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';
import { sortBy } from 'lodash';
export default {
  name: 'CreateNewSubGroup',
  components: {
    AdsButton,
  },
  props: {
    listType: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      staffContactsList: 'moduleManageContacts/getSchoolStaffContactList',
      otherContactsList: 'moduleManageContacts/getOtherContactList',
      schoolStaffSubGroupList: 'moduleManageContacts/getSchoolStaffSubGroup',
      otherContactsSubGroupList:
        'moduleManageContacts/getOtherContactsSubGroup',
      school: 'moduleDashboard/getSelectedSchool',
    }),
    contacts() {
      if (this.listType === 'staff') {
        return this.staffContactsList;
      } else {
        return this.otherContactsList;
      }
    },
    filteredContacts() {
      let contacts = this.contacts;
      if (contacts && contacts.length && this.searchBarValue.length > 0) {
        return contacts.filter((contact) =>
          this.fullName(contact)
            .toLocaleLowerCase()
            .includes(this.searchBarValue.toLocaleLowerCase())
        );
      }
      return contacts;
    },
    fullMembersList() {
      return [...this.members, ...this.addedMembers].filter((user) => {
        return !this.removedMembers.some(
          (member) => member.staff_id === user.staff_id
        );
      });
    },
  },
  data: () => ({
    valid: true,
    entries: [],
    model: null,
    search: null,
    isLoading: false,
    searchBarValue: '',
    members: [],
    addedMembers: [],
    removedMembers: [],
    formData: {
      name: '',
    },
    rules: {
      required: (v) => !!v || 'Name is required',
      lengthCheck: (v) =>
        (v && v.length <= 30) || 'Name must be less than 30 characters',
    },
  }),
  methods: {
    sortBy,
    checkDuplicate() {
      let res = [];
      if (this.listType === 'staff') {
        res = this.schoolStaffSubGroupList.filter(
          (el) => el.name.toLowerCase() === this.formData.name.toLowerCase()
        );
      }
      if (this.listType === 'other') {
        res = this.otherContactsSubGroupList.filter(
          (el) => el.name.toLowerCase() === this.formData.name.toLowerCase()
        );
      }

      if (res && res.length > 0) {
        return `Name "${this.formData.name}" already exists`;
      } else {
        return true;
      }
    },
    checkAllStaff(val) {
      if (val === 'All school staff') {
        return 'Please use another subgroup name'
      } else {
        return true;
      }
    },
    removeMember(member, timeout) {
      if (timeout) {
        setTimeout(() => {
          if (
            this.addedMembers.some((user) => user.staff_id === member.staff_id)
          ) {
            this.addedMembers = this.addedMembers.filter(
              (item) => item.staff_id !== member.staff_id
            );
          } else {
            this.removedMembers.push(member);
          }
        }, 350);
      } else {
        if (
          this.addedMembers.some((user) => user.staff_id === member.staff_id)
        ) {
          this.addedMembers = this.addedMembers.filter(
            (item) => item.staff_id !== member.staff_id
          );
        } else {
          this.removedMembers.push(member);
        }
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        await this.createNewSubGroup();
        this.$refs.form.reset();
        (this.members = []), this.$emit('close-dialog', true);
      }
    },

    async createNewSubGroup() {
      const payload = this.mapMembersData(this.fullMembersList);
      const categoryname = this.formData.name;
      this.$store.commit('SET_IS_LOADING', 'createSubGroup', {
        root: true,
      });
      await this.$http
        .post(
          process.env.VUE_APP_API_BASE_URL + `/contacts/${this.listType}/bulk`,
          payload,
          {
            params: {
              categoryname,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            if (this.listType === 'staff') {
              this.$store.dispatch(
                'moduleManageContacts/fetchSchoolStaffContactSubGroups'
              );
            }
            if (this.listType === 'other') {
              this.$store.dispatch(
                'moduleManageContacts/fetchOtherContactSubGroups'
              );
            }
            this.members = [];
            this.addedMembers = [];
            this.removedMembers = [];
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'createSubGroup', {
            root: true,
          });
        });
    },
    addMember(member) {
      if (
        this.members.find(
          (user) =>
            user.staff_id === member.staff_id &&
            !this.removedMembers.find(
              (user) => user.staff_id === member.staff_id
            )
        )
      ) {
        this.removeMember(member, false);
      } else if (
        this.members.find(
          (user) =>
            user.staff_id === member.staff_id &&
            this.removedMembers.find(
              (user) => user.staff_id === member.staff_id
            )
        )
      ) {
        this.removedMembers = this.removedMembers.filter(
          (user) => user.staff_id !== member.staff_id
        );
      } else if (
        this.addedMembers.find((o) => o.staff_id === member.staff_id)
      ) {
        this.removeMember(member, false);
      } else {
        this.addedMembers.push(member);
      }
    },
    fullName(contact) {
      return contact?.first_name.trim() + ' ' + contact?.last_name.trim();
    },
    mapMembersData(members) {
      const options = members.map((el) => {
        const option = {
          first_name: el.first_name,
          last_name: el.last_name,
          staff_id: el.staff_id,
        };
        return option;
      });
      return options;
    },
    alreadyAdded(staff_id) {
      return this.fullMembersList.some(
        (member) => member.staff_id === staff_id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-style {
  height: 44px;
  align-items: center;
}
</style>
