<template>
  <v-row
    no-gutters
    v-if="recipients"
  >
    <v-col cols="12">
      <v-card-title
        class="py-0"
        :style="show ? { 'background-color': '#cbedfd' } : {}"
      >
        <v-checkbox
          v-model="recipients.selected"
          :input-value="recipients.selected"
          :label="recipients.label"
          @change="handleOtherCheckBox($event)"
          :class="{ partial: recipients.selected && someSubgroupsChecked }"
          :aria-checked="recipients.selected ? 'true' : 'false'"
        />
        <v-spacer />
        <v-btn
          icon
          @click="show = !show"
          aria-label="Expand for other contacts options"
          :aria-expanded="show ? 'true' : 'false'"
        >
          <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle v-show="!show">
        Expand to see selected subgroups and channels
      </v-card-subtitle>
      <v-expand-transition>
        <div v-show="show">
          <v-card-subtitle>
            <div class="d-flex align-center flex-wrap">
              <div class="d-flex mr-2">
                <v-chip
                  v-for="(dname, j) in recipients.channels"
                  :key="j"
                  outlined
                  v-show="dname.selected"
                  close
                  color="#002664"
                  class="mr-1"
                  @click:close="
                    handleChipClose(dname.name, 'parent', recipients)
                  "
                >
                  {{ dname.name }}
                </v-chip>
              </div>
              <v-btn
                prepend-icon
                rounded
                text
                color="primary"
                @click="showChannelDialog(recipients)"
                class="pl-1 add-channel"
              >
                <v-icon left>
                  mdi-plus-circle-outline
                </v-icon>
                Add channel
              </v-btn>
            </div>
            <div
              v-for="sub in sortBy(recipients.items, (m) =>
                m.label.toLocaleLowerCase()
              )"
              :key="sub.name"
            >
              <v-checkbox
                v-model="sub.selected"
                :label="sub.label"
                :input-value="sub.selected"
                @click="handleOtherSubGroupCheckBox()"
                :aria-checked="sub.selected ? 'true' : 'false'"
                :disabled="sub.size === 0"
              />
              <!-- <div class="d-flex align-center flex-wrap">
                <div class="mr-2">
                  <v-chip
                    v-for="(dname, j) in sub.channels"
                    :key="j"
                    outlined
                    v-show="dname.selected"
                    close
                    color="#002664"
                    class="mr-1"
                    @click:close="handleChipClose(dname.name, 'child', sub)"
                  >
                    {{ dname.name }}
                  </v-chip>
                </div>
                <v-btn
                  prepend-icon
                  rounded
                  text
                  color="primary"
                  @click="showChannelDialog(sub)"
                  class="pl-1 add-channel"
                >
                  <v-icon left>
                    mdi-plus-circle-outline
                  </v-icon>
                  Add channel
                </v-btn>
              </div> -->
            </div>
          </v-card-subtitle>
        </div>
      </v-expand-transition>
      <ChannelsDialog
        :openDialog="eventDetailsDialog"
        :name="name"
        :items="dialogData"
        @close-dialog="(items) => handleResponse(items)"
      />
    </v-col>
  </v-row>
</template>

<script>
import ChannelsDialog from '@/views/Message/BlankMessage/ChannlesDialog';
import { mapGetters } from 'vuex';
import { sortBy } from 'lodash';
export default {
  name: 'OtherContacts',
  components: {
    ChannelsDialog,
  },
  data() {
    return {
      show: false,
      openDialog: false,
      eventDetailsDialog: false,
      name: '',
      dialogData: [],
      channels: [],
      test: {},
      otherContacts: {
        name: 'Other contacts',
        channels: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      communicationInfo: 'moduleDashboard/communicationInfo',
      getRecipientsByName: 'moduleRecipients/getRecipientsByName',
      event: 'moduleDashboard/getSelectedEvent',
      otherRecipients: 'moduleRecipients/getOtherContactsRecipients',
      message: 'moduleEventDetails/getSelectedMessage',
    }),
    recipients() {
      return this.otherRecipients
    },
    someSubgroupsChecked() {
      let all = this.recipients.items.every(year => year.selected)
      let some = this.recipients.items.some(year => year.selected)
      if (all) {
        return false;
      } else if (some) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    sortBy,
    showChannelDialog(sub) {
      this.dialogData = sub.channels;
      this.name = sub.label;
      this.eventDetailsDialog = true;
    },
    handleResponse() {
      this.eventDetailsDialog = false;
      const subGroups = this.recipients.items ?? [];
      const otherContactsSelected = subGroups.some((item) => item.selected);
      otherContactsSelected
        ? (this.recipients.selected = true)
        : (this.recipients.selected = false);
      const selectedChannels = this.recipients.channels.filter(
        (item) => item.selected === true
      );
      if (selectedChannels.length === 0) {
        this.recipients.selected = false;
        this.recipients.items.forEach(item => {
          item.selected = false;
        })
      }
      this.$emit('other-subgroup-update', {
        ...this.recipients,
        selected: otherContactsSelected,
      });
    },
    handleChipClose(itemClosed, catItem, selectedItem) {
      if (catItem === 'parent') {
        for (var item of this.recipients.channels) {
          if (item.name === itemClosed) {
            item.selected = false;
          }
        }
      } else {
        for (let item of selectedItem.channels) {
          if (item.name === itemClosed) {
            item.selected = false;
          }
        }
      }
      const subGroups = this.recipients.items ?? [];
      const otherContactsSelected = subGroups.some((item) => item.selected);
      otherContactsSelected
        ? (this.recipients.selected = true)
        : (this.recipients.selected = false);
      const selectedChannels = this.recipients.channels.filter(
        (item) => item.selected === true
      );
      if (selectedChannels.length === 0) {
        this.recipients.selected = false;
        this.recipients.items.forEach(item => {
          item.selected = false;
        })
      }
      this.$emit('other-subgroup-update', {
        ...this.recipients,
        selected: otherContactsSelected,
      });
    },
    handleOtherCheckBox(selected) {
      this.recipients.items.forEach((item) => {
        item.size === 0 ? item.selected = false : item.selected = selected;
      });
      const subGroups = this.recipients.items ?? [];
      const otherContactsSelected = subGroups.some((item) => item.selected);
      otherContactsSelected
        ? (this.recipients.selected = true)
        : (this.recipients.selected = false);
      this.$emit('other-subgroup-update', {
        ...this.recipients,
        selected: otherContactsSelected,
      });
    },
    handleOtherSubGroupCheckBox() {
      const subGroups = this.recipients.items ?? [];
      const otherContactsSelected = subGroups.some((item) => item.selected);
      otherContactsSelected
        ? (this.recipients.selected = true)
        : (this.recipients.selected = false);
      this.$emit('other-subgroup-update', {
        ...this.recipients,
        selected: otherContactsSelected,
      });
    },
  },
  // watch: {
  //   recipients: {
  //     deep: true,
  //     handler(selection) {
  //       if (selection.selected) {
  //         const selectedChannels = selection.channels.filter(
  //           (item) => item.selected === true
  //         );
  //         if (selectedChannels.length === 0) {
  //           this.recipients.selected = false;
  //           this.recipients.items.forEach(item => {
  //             item.selected = false;
  //           })
  //         } else {
  //           this.recipients.selected = true;
  //         }
  //       }
  //     },
  //   },
  // },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-chip.v-chip--outlined.v-chip.v-chip {
    background-color: #cbedfd !important;
  }
  .partial {
    i::before {
      content: '\F0375'
    }
  }
  .v-input--checkbox {
    label {
      margin-left: 8px;
    }
  }
}
</style>
