/**
 * This is the entry point for all API requests.
 * import API modules and expose them to components via Vue.prototype.$api
 */
import dashboard from './modules/dashboard';
import eventdetails from './modules/eventdetails';
import managecontacts from './modules/managecontacts';
import managetemplates from './modules/managetemplates';
import templates from './modules/templates';
import notifications from './modules/notifications';

export default {
  dashboard,
  eventdetails,
  templates,
  managecontacts,
  managetemplates,
  notifications,
};
