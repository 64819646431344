<template>
  <div>
    <v-btn
      icon
      rounded
      @click="showDialog = true"
      aria-label="School emergency response team"
    >
      <v-icon> mdi-chevron-right </v-icon>
    </v-btn>
    <v-dialog
      v-model="showDialog"
      fullscreen
      persistent
      hide-overlay
      transition="slide-x-reverse-transition"
      content-class="emergency-contact-dialog bg-grey"
    >
      <v-card class="rounded-0">
        <v-toolbar color="primary">
          <v-btn
            icon
            color="white"
            @click="showDialog = false"
            aria-label="Back"
          >
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title>
            <h1>School emergency response team</h1>
          </v-toolbar-title>
        </v-toolbar>
        <EmergencyContact @close-dialog="showDialog = false" />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import EmergencyContact from '@/views/Settings/Contacts/EmergencyContact';

export default {
  name: 'EmergencyContactDialog',
  components: {
    EmergencyContact,
  },
  props: {
    tempId: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    tertiary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  watch: {
    showDialog(newValue) {
      if (newValue) {
        this.$store.dispatch('moduleManageContacts/fetchEmergencyContactsList');
        this.$store.dispatch('moduleManageContacts/fetchEmergencyContact');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.emergency-contact-dialog {
  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 10px;
    h1 {
      font-size: 20px;
    }
  }
}
</style>
