<template>
  <div class="container-wrapper py-2">
    <v-container class="py-1">
      <v-row
        v-if="messages.length != 0"
        justify="center"
      >
        <v-col
          v-for="item in messages"
          :key="item.event_id"
          cols="12"
          sm="10"
        >
          <v-card
            :id="item.event_id"
            class="event-card pa-4"
            @click="toggleDialog(item)"
          >
            <div class="d-flex align-center">
              <div
                v-if="item.is_emergency"
                class="card-icon pa-4"
              >
                <v-img src="/images/warning-icon.svg" />
              </div>
              <div
                v-if="!item.is_emergency"
                class="card-icon pa-4"
              >
                <v-img src="/images/message-icon.svg" />
              </div>

              <div class="notify-title-text ml-5">
                {{ item.title }}
                <div
                  class="timestamp-txt"
                  v-if="item.status === 'Active'"
                >
                  <div class="last-message">
                    Last message sent
                  </div>
                  <div class="last-message-date">
                    {{ formatDate(item.modified_datetime) }}
                  </div>
                </div>
                <div
                  class="timestamp-txt"
                  v-if="item.status === 'Closed'"
                >
                  <div class="last-message">
                    Message thread closed
                  </div>
                  <div class="last-message-date">
                    {{ formatDate(item.modified_datetime) }}
                  </div>
                </div>

                <div>
                  <v-chip class="event-badge mt-2">
                    {{ item.badge.toUpperCase() }}
                  </v-chip>
                </div>
              </div>
              <v-spacer />
              <div :id="item.event_id">
                <v-img
                  src="../images/icon-right.svg"
                  :id="item.event_id"
                  max-height="24"
                  max-width="24"
                />
              </div>
            </div>
          </v-card>
        </v-col>
        <v-dialog
          v-model="showDialog"
          fullscreen
          persistent
          hide-overlay
          transition="dialog-bottom-transition"
          content-class="event-details-dialog"
        >
          <v-card class="rounded-0">
            <v-toolbar color="primary">
              <v-btn
                icon
                color="white"
                @click="showDialog = false"
                aria-label="Close"
              >
                <v-icon> mdi-close </v-icon>
              </v-btn>
              <v-toolbar-title>
                <h1>Message detail</h1>
              </v-toolbar-title>
            </v-toolbar>
            <EventDetails @close-event-details="closeDialog" />
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import EventDetails from '@/views/EventDetails';

export default {
  name: 'Message',
  props: {
    messages: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    EventDetails,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      school: 'moduleDashboard/getSelectedSchool',
    }),
  },
  mounted() {
    this.$eventHub.$on('close-fsdialog', () => {
      this.showDialog = false;
    });
  },
  methods: {
    toggleDialog(item) {
      this.$store.dispatch('moduleDashboard/updateSelectedEvent', item);
      this.$store.dispatch(
        'moduleEventDetails/fetchHistoryById',
        item.event_id,
        this.school.schoolCode
      );
      this.$store.dispatch('moduleEventDetails/fetchCheckListById', {
        id: item.event_id,
        itemCheckList: false,
      });
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    formatDate(itemDate) {
      var date = new Date(itemDate);
      return moment(date).format('DD/MM/YYYY, HH:mm A');
    },
    editEvent(event) {
      this.$store.dispatch('moduleDashboard/updateSelectedEvent', event);
      this.$router.push({
        path: '/emergency',
      });
    },
  },
  watch: {
    showDialog(newValue) {
      if (newValue) {
        this.$store.dispatch('moduleTemplates/fetchTemplates');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-chip {
    &.event-badge {
      background-color: #3375D7;
      .v-chip__content {
        color: var(--greys-white, #fff);
        text-align: center;
        font-family: Public Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 17px;
        text-transform: uppercase;
      }
    }
  }
}

.timestamp-txt {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}

.container-main {
  max-width: 800px !important;
}

.card-icon {
  height: 78px;
  width: 78px;
  background-color: #cbedfd;
  border-radius: 5px;
}

.event-details-dialog {
  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 10px;
    h1 {
      font-size: 20px;
    }
  }
}
</style>
