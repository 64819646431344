import mockSchools from '../data/schools';
import { get, post } from '../http';

const fetchDashboard = (url, config = {}) => get(url, config);
const postPosts = (url, data = {}, config = {}, isForm = false) => post(url, data, config, isForm)

/* eslint no-console: "off" */
const fetchAllSchools = () => {
  const abc =  new Promise(function (resolve) {
    setTimeout(function () {
      resolve(mockSchools);
    }, 100);
  });
  return abc;
};

export default {
  fetchDashboard,
  postPosts,
  fetchAllSchools
};