<template>
  <div class="wrapper-bg">
    <v-container class="py-5">
      <v-row
        justify="center"
        v-if="event"
      >
        <v-col
          cols="12"
          sm="10"
          :class="`d-flex ${event.is_emergency ? 'align-end' : 'align-start'}`"
        >
          <div>
            <v-img
              src="/images/message-icon.svg"
              v-show="!event.is_emergency"
              height="42"
              width="42"
              class="mr-4 mt-2"
            />
            <v-img
              src="/images/warning-icon.svg"
              v-show="event.is_emergency"
              height="54"
              width="60"
              class="mr-4 mb-1"
            />
          </div>
          <div class="d-flex aware-wrapper align-left">
            <h1 class="d-flex align-center event-details-title">
              {{ event.title }}
            </h1>
            <div class="d-flex align-center event-school-name">
              {{ school.name }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        justify="start"
        v-show="event && event.status !== 'Closed'"
      >
        <v-col
          cols="12"
          lg="2"
          sm="3"
          offset-sm="1"
        >
          <UpdateMessageDialog />
        </v-col>
        <v-col
          cols="12"
          lg="2"
          sm="3"
        >
          <AdsButton
            buttonText="Close"
            secondary
            icon="mdi-check-circle-outline"
            @click="openDialog = true"
            block
            style="
              border-radius: 4px;
              border: 2px solid var(--doe-brand-colours-primary-blue, #002664);
              background: var(--greys-white, #fff);
            "
          />
        </v-col>
      </v-row>
    </v-container>
    <Dialog
      :openDialog="openDialog"
      icon="mdi-check-circle-outline"
      @close="openDialog = false"
      persistent
      display-close-btn
      content-class="close-message"
      v-if="event"
    >
      <template #title>
        <v-card-title class="d-flex justify-space-between">
          <h2 class="title">
            <v-avatar size="40">
              <v-icon>mdi-check-circle-outline</v-icon>
            </v-avatar>
            Close message
          </h2>
        </v-card-title>
      </template>
      <template #actions>
        <div class="px-6 pb-6 d-flex justify-space-between">
          <AdsButton
            secondary
            @click="openDialog = false"
            v-show="event.is_emergency"
          >
            Cancel
          </AdsButton>
          <AdsButton
            secondary
            @click="closeMessage()"
            v-show="!event.is_emergency"
          >
            Just close
          </AdsButton>
          <v-spacer />
          <CloseMessageDialog />
        </div>
      </template>
      <template #text>
        <div class="px-6 py-4">
          <label
            class="primary--text"
            v-show="event.is_emergency"
          >
            Would you like to close communication about this emergency with a
            final message?
          </label>
          <label
            class="primary--text"
            v-show="!event.is_emergency"
          >
            Would you like to close this with a final message?
          </label>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import CloseMessageDialog from '@/views/EventDetails/CloseMessageDialog';
import UpdateMessageDialog from '@/views/EventDetails/UpdateMessageDialog';
import { AdsButton, Dialog } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';
export default {
  name: 'EventUpdate',
  components: {
    AdsButton,
    Dialog,
    UpdateMessageDialog,
    CloseMessageDialog,
  },
  data: () => ({
    actions: [{ name: 'Close' }, { name: 'Update' }],
    openDialog: false,
  }),
  computed: {
    ...mapGetters({
      school: 'moduleDashboard/getSelectedSchool',
      event: 'moduleDashboard/getSelectedEvent',
      updateTemplate: 'moduleTemplates/getUpdateTemplate',
      closeTemplate: 'moduleTemplates/getCloseTemplate',
      selectedCheckList: 'moduleEventDetails/getCheckListItems',
    }),
  },
  async created() {
    await this.$store.dispatch('moduleEventDetails/fetchCheckListById', {
      id: this.event.event_id,
      itemCheckList: false,
    });
    await this.$store.dispatch('moduleTemplates/fetchTemplates');
  },
  methods: {
    async closeMessage() {
      this.openDialog = false;
      this.$store.commit('SET_IS_LOADING', 'closeMessage', { root: true });
      const data = {
        schoolCode: this.school.code,
        badge: 'Closed',
      };
      await this.$http
        .post(
          process.env.VUE_APP_API_BASE_URL + `/events/${this.event.event_id}`,
          data
        )
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.dispatch('moduleDashboard/fetchSchoolNotifications');
          this.$store.commit('REMOVE_IS_LOADING', 'closeMessage', {
            root: true,
          });
          this.$emit('close-event-details');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 448px) {
  .btn-update-close {
    width: 110px !important;
  }
}

.wrapper-bg {
  background-color: #cbedfd;
}

.header-btn i {
  font-size: 40px !important;
}

.header-btn {
  color: #fff !important;
}

.primary {
  height: 64px;
}

.aware-wrapper {
  display: flex;
  flex-direction: column;
}
.v-card {
  position: relative;

  .close-btn__wrapper {
    position: absolute;
    top: 9px;
    right: 9px;
  }
  .v-card__title {
    padding: 24px 10px 0 24px !important;
    margin-bottom: 18px;
    color: #002664 !important;
    .title {
      font-size: 1.125rem !important;
      display: flex;
      align-items: center;
      font-weight: 700;
    }

    .v-avatar {
      background-color: #cbedfd !important;
      margin-right: 10px;
    }
  }

  .content {
    padding: 0 24px;
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 24px;
    color: #002664 !important;
    background-color: unset !important;
  }

  .v-card__actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px 24px 24px !important;

    .v-btn {
      margin-left: 12px !important;
      padding: 0 16px;
      color: #fff;
    }
  }

  .v-btn:focus {
    outline: 3px auto -webkit-focus-ring-color;
  }
}
</style>
