// Vuex Posts module
const moduleManageContacts = {
  namespaced: true,
  state: {
    delContact: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      contact_id: '',
    },
    emergencyTeam: [],
    contactList: [],
    schoolStaffContactList: [],
    otherContactList: [],
    schoolStaffSubGroup: [],
    schoolStaffSubGroupDetails: [],
    otherContactsSubGroupDetails: [],
    otherContactsSubGroup: [],
  },
  getters: {
    getDelContact: (state) => state.delContact,
    getEmergencyTeam: (state) => state.emergencyTeam,
    getContactList: (state) => state.contactList,
    getSchoolStaffSubGroup: (state) =>
      state.schoolStaffSubGroup.sort(function (a, b) {
        var textA = a.name.toLowerCase();
        var textB = b.name.toLowerCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }),
    getOtherContactsSubGroup: (state) =>
      state.otherContactsSubGroup.sort(function (a, b) {
        var textA = a.name.toLowerCase();
        var textB = b.name.toLowerCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }),
    getSchoolStaffContactList: (state) => state.schoolStaffContactList,
    getOtherContactList: (state) => state.otherContactList,
    schoolStaffSubGroupDetails: (state) => state.schoolStaffSubGroupDetails,
    otherContactsSubGroupDetails: (state) => state.otherContactsSubGroupDetails,
  },
  mutations: {
    SET_DEL_CONTACTS(state, delContact) {
      state.delContact = delContact;
    },
    SET_EMERGENCY_TEAM(state, team) {
      state.emergencyTeam = team;
    },
    SET_CONTACT_LIST(state, contactList) {
      state.contactList = contactList;
    },
    SET_SCHOOL_STAFF_SUB_GROUPS(state, groups) {
      state.schoolStaffSubGroup = groups;
    },
    SET_SCHOOL_STAFF_SUB_GROUP_DETAILS(state, subGroup) {
      state.schoolStaffSubGroupDetails = subGroup;
    },
    SET_OTHER_CONTACTS_SUB_GROUP_DETAILS(state, subGroup) {
      state.otherContactsSubGroupDetails = subGroup;
    },
    SET_OTHER_CONTACTS_SUB_GROUPS(state, groups) {
      state.otherContactsSubGroup = groups;
    },
    SET_SCHOOL_STAFF_CONTACT_LIST(state, contactList) {
      state.schoolStaffContactList = contactList;
    },
    SET_OTHER_CONTACT_LIST(state, contactList) {
      state.otherContactList = contactList;
    },
  },
  actions: {
    async fetchDelContacts({ commit }) {
      commit('SET_IS_LOADING', 'delContacts', { root: true });
      this._vm.$http
        .get('/contacts/del/del')
        .then((response) => {
          if (response.data) {
            return commit('SET_DEL_CONTACTS', response.data[0]);
          }
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', 'delContacts', { root: true });
        });
    },
    async fetchEmergencyContact({ commit }) {
      commit('SET_IS_LOADING', 'emergencyContacts', { root: true });
      await this._vm.$http
        .get('/contacts/ert/ert')
        .then((response) => {
          if (response.data) {
            return commit('SET_EMERGENCY_TEAM', response.data);
          }
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', 'emergencyContacts', { root: true });
        });
    },
    async fetchEmergencyContactsList({ commit }) {
      commit('SET_IS_LOADING', 'emergencyContactList', { root: true });
      await this._vm.$http
        .get('/contacts/search/staff')
        .then((response) => {
          if (response.data) {
            return commit('SET_CONTACT_LIST', response.data);
          }
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', 'emergencyContactList', { root: true });
        });
    },
    async fetchSchoolStaffContactSubGroups({ commit }) {
      commit('SET_IS_LOADING', 'schoolStaffContactSubGroups', { root: true });
      await this._vm.$http
        .get('/contacts/categories/staff')
        .then((response) => {
          if (response.data) {
            return commit('SET_SCHOOL_STAFF_SUB_GROUPS', response.data);
          }
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', 'schoolStaffContactSubGroups', {
            root: true,
          });
        });
    },

    async fetchSchoolStaffContactsList({ commit }) {
      commit('SET_IS_LOADING', 'schoolStassContactsList', { root: true });
      await this._vm.$http
        .get('/contacts/search/staff')
        .then((response) => {
          if (response.data) {
            return commit('SET_SCHOOL_STAFF_CONTACT_LIST', response.data);
          }
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', 'schoolStassContactsList', {
            root: true,
          });
        });
    },

    async fetchOtherContactsList({ commit }, options) {
      if(options != null && !options.backgroundLoading) {
        commit('SET_IS_LOADING', 'otherContactsList', { root: true });
      }
      await this._vm.$http
        .get('/contacts/search/other#', {params: { includeHidden: '1'}})
        .then((response) => {
          if (response.data) {
            return commit('SET_OTHER_CONTACT_LIST', response.data);
          }
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', 'otherContactsList', { root: true });
        });
    },

    async fetchOtherContactSubGroups({ commit }) {
      commit('SET_IS_LOADING', 'otherContactSubGroups', { root: true });
      await this._vm.$http
        .get('/contacts/categories/other')
        .then((response) => {
          if (response.data) {
            return commit('SET_OTHER_CONTACTS_SUB_GROUPS', response.data);
          }
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', 'otherContactSubGroups', { root: true });
        });
    },
    async fetchOtherContactBySubGroupId({ commit }, subGroupId) {
      commit('SET_IS_LOADING', `otherContactBySubGroup-${subGroupId}`, {
        root: true,
      });
      this._vm.$http
        .get(`/contacts/other/${subGroupId}`)
        .then((response) => {
          if (response.data) {
            return commit(
              'SET_OTHER_CONTACTS_SUB_GROUP_DETAILS',
              response.data
            );
          }
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', `otherContactBySubGroup-${subGroupId}`, {
            root: true,
          });
        });
    },
    async fetchStaffSubGroupDetailsBySubGroupId({ commit }, subGroupId) {
      commit(
        'SET_IS_LOADING',
        `staffSubGroupDetailsBySubGroupId-${subGroupId}`,
        { root: true }
      );
      this._vm.$http
        .get(`/contacts/staff/${subGroupId}`)
        .then((response) => {
          if (response.data) {
            return commit('SET_SCHOOL_STAFF_SUB_GROUP_DETAILS', response.data);
          }
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit(
            'REMOVE_IS_LOADING',
            `staffSubGroupDetailsBySubGroupId-${subGroupId}`,
            { root: true }
          );
        });
    },
  },
};

export default moduleManageContacts;
