/* eslint-disable no-unused-vars */
import axios from 'axios';
// Vuex Events module
const moduleEventDetails = {
  namespaced: true,
  state: {
    events: [],
    checklist: [],
    selection: undefined,
    history: [],
    selectedmessage: undefined,
  },
  mutations: {
    SET_EVENT_WITH_CHECKLIST(state, events) {
      state.events = events;
    },
    SET_CHECKLIST_BY_ID(state, checklist) {
      state.checklist = checklist;
    },
    SET_HISTORY_BY_ID(state, history) {
      state.history = history;
    },
    SET_SELECTED_MESSAGE(state, selectedMessage) {
      state.selectedmessage = selectedMessage;
    },
  },
  getters: {
    getAllCheckList: (state) => state.checklist,
    getCheckListItems: (state) => state.selection,
    getSelectedMessage: (state) => state.selectedmessage,
    getAllHistory: (state) =>
      state.history.sort(
        (e1, e2) => parseFloat(e2.event_version) - parseFloat(e1.event_version)
      ),
  },
  actions: {
    fetchCheckListById({ commit }, checkListParams) {
      if (!checkListParams.itemChecklist)
        commit('SET_IS_LOADING', `fetchCheckList-${checkListParams.id}`, {
          root: true,
        });
      this._vm.$http
        .get(
          `${process.env.VUE_APP_API_BASE_URL}/events/${checkListParams.id}/checklists`
        )
        .then((response) => {
          return commit('SET_CHECKLIST_BY_ID', response.data);
        })
        .finally(() => {
          if (!checkListParams.itemChecklist)
            commit(
              'REMOVE_IS_LOADING',
              `fetchCheckList-${checkListParams.id}`,
              {
                root: true,
              }
            );
        });
    },

    fetchHistoryById({ commit }, eventId) {
      commit('SET_IS_LOADING', `fetchHistory-${eventId}`, { root: true });
      return this._vm.$http
        .get('/events/' + eventId)
        .then((response) => {
          return commit('SET_HISTORY_BY_ID', response.data);
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', `fetchHistory-${eventId}`, {
            root: true,
          });
        });
    },

    updateSelectedMessage({ commit }, selectedMessage) {
      commit('SET_SELECTED_MESSAGE', selectedMessage);
    },
  },
};

export default moduleEventDetails;
