import api from '../../api';

// Vuex Templates module
const moduleTemplates = {
  namespaced: true,
  state: {
    event: {
      title: '',
      description: '',
      isEmergency: '',
      postFacebook: '',
      postSchoolWebsite: '',
      schoolCode: '',
      email: '',
    },
    isChecked: false,
    templates: [],
    selectedTemplate: undefined,
    templatesMetaData: [],
  },
  getters: {
    getTemplates: (state) => state.templates,
    getEventData: (state) => state.event,
    getTemplatesMetaData: (state) => {
      const res = state.templatesMetaData?.sort(function (a, b) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      return res;
    },
    getSelectedTemplates: (state) => state.selectedTemplate,
    getUpdateTemplate: (state) => {
      const category = state.templates.filter(
        (el) => el.category === 'Emergencies'
      );
      const res = category[0].items.filter(
        (eachVal) => eachVal.catId === 'C002'
      );
      return res[0];
    },
    getCloseTemplate: (state) => {
      const category = state.templates.filter(
        (el) => el.category === 'Emergencies'
      );
      const res = category[0].items.filter(
        (eachVal) => eachVal.catId === 'C003'
      );
      return res[0];
    },
    getRecipientsByName: (state) => (name) => {
      return state.event.recipients, find((item) => item.name === name);
    },
    getRecipientSelectedChannels: (state) => (name) => {
      return (
        state.event.recipients,
        find((item) => item.name === name && item.selected === true)
      );
    },
    getTotalRecipient: (state) => (name) => {
      var channels = [];
      for (const cName of state.recipients) {
        if (cName.name == name) {
          for (const c of cName.channels)
            if (c.selected) {
              channels.push(c.name);
            }
        }
      }
      return channels.length > 0 ? true : false;
    },
    getEventDetails: (state) => state.event,
    getChecked: (state) => state.isChecked,
  },
  mutations: {
    SET_DESCRIPTION(state, description) {
      state.event.description = description;
    },
    SET_TITLE(state, title) {
      state.event.title = title;
    },
    SET_EMERGENCY(state, isEmergency) {
      state.event.isEmergency = isEmergency;
    },
    SET_FACEBOOK(state, postFacebook) {
      state.event.postFacebook = postFacebook;
    },
    SET_SCHOOL_WEBSITE(state, postSchoolWebsite) {
      state.event.postSchoolWebsite = postSchoolWebsite;
    },
    // SET_RECIPIENTS(state, details) {
    //   var channels = [];
    //   let resp = state.event.recipients;
    //   for(const item of resp) {
    //     if(item.name == details.gName){
    //       for(const c of item.channels)
    //         if(c.name == details.cName){
    //           c.selected = details.val;
    //         }
    //         channels.push[item];
    //     }
    //     channels.push[item];
    //   }
    //   state.event.recipients = resp;
    // },
    // SET_RECIPIENTS_BY_NAME(state, details) {
    //   for(const item of state.event.recipients) {
    //     if(item.name == details.gName){
    //       //console.log(, state.event.recipients[index].name);
    //       //state.event.recipients[index].channels = details.cName
    //     }
    //   }
    // },
    SET_TEMPLATES(state, templates) {
      state.templates = templates;
    },
    SET_SELECTED_TEMPLATE(state, selectedTemplate) {
      state.selectedTemplate = selectedTemplate;
    },
    SET_TEMPLATES_METADATA(state, templateDetails) {
      state.templatesMetaData = templateDetails;
    },
    SET_TEMPLATES_DETAILS(state, templateDetails) {
      state.event = templateDetails;
    },
  },
  actions: {
    async fetchTemplates({ commit }) {
      commit('SET_IS_LOADING', 'fetchTemplates', { root: true });
      this._vm.$http
        .get('/templates/categories')
        .then((response) => {
          if (response.data) {
            // pull emergencies to top of list
            response.data.sort((a) => a.id === 'Emergencies' && -1);
            return commit('SET_TEMPLATES', response.data);
          }
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', 'fetchTemplates', { root: true });
        });
    },
    async fetchTemplatesByCategoryId({ commit }, catId) {
      commit('SET_IS_LOADING', 'fetchTemplatesByCategoryId', {
        root: true,
      });
      this._vm.$http
        .get('/templates/category/' + catId)
        .then((response) => {
          if (response.data) {
            return commit('SET_TEMPLATES_METADATA', response.data);
          }
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', 'fetchTemplatesByCategoryId', {
            root: true,
          });
        });
    },

    fetchTemplateById({ commit }, templateId) {
      commit('SET_IS_LOADING', `fetchTemplateById-${templateId}`, {
        root: true,
      });
      api.templates
        .fetchTemplateById(templateId)
        .then((response) => {
          return commit('SET_TEMPLATES_DETAILS', response[0]);
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', `fetchTemplateById-${templateId}`, {
            root: true,
          });
        });
    },
    updateSelectedChannel({ commit }, updateChannelDetails) {
      commit('SET_RECIPIENTS', updateChannelDetails);
    },
    updateSelectedChannels({ commit }, updateChannelDetails) {
      commit('SET_RECIPIENTS_BY_NAME', updateChannelDetails);
    },
    updateSelectedTemplate({ commit }, template) {
      commit('SET_SELECTED_TEMPLATE', template);
    },
  },
};

export default moduleTemplates;
