import { render, staticRenderFns } from "./OtherRecipient.vue?vue&type=template&id=baa3b146&scoped=true"
import script from "./OtherRecipient.vue?vue&type=script&lang=js"
export * from "./OtherRecipient.vue?vue&type=script&lang=js"
import style0 from "./OtherRecipient.vue?vue&type=style&index=0&id=baa3b146&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baa3b146",
  null
  
)

export default component.exports