<template>
  <v-row
    no-gutters
    v-if="recipients"
  >
    <v-col cols="12">
      <v-card-title
        class="py-0"
        :style="show ? { 'background-color': '#cbedfd' } : {}"
      >
        <v-checkbox
          v-model="recipients.selected"
          :input-value="recipients.selected"
          :label="recipients.label"
          return-object
          @change="handleParentCheckBox($event)"
          :class="{ partial: recipients.selected && someSubgroupsChecked }"
          :aria-checked="recipients.selected ? 'true' : 'false'"
        />
        <v-spacer />
        <v-btn
          icon
          @click="show = !show"
          aria-label="Expand for parents and carers options"
          :aria-expanded="show ? 'true' : 'false'"
        >
          <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle v-show="!show">
        Expand to see selected subgroups and channels
      </v-card-subtitle>
      <v-expand-transition>
        <div v-show="show">
          <v-card-subtitle>
            <div class="d-flex align-center flex-wrap">
              <div class="d-flex mr-2">
                <v-chip
                  v-for="(dname, j) in recipients.channels"
                  :key="j"
                  outlined
                  v-show="dname.selected"
                  close
                  color="#002664"
                  class="mr-1"
                  @click:close="handleChipClose(dname)"
                >
                  {{ dname.name }}
                </v-chip>
              </div>
              <v-btn
                prepend-icon
                rounded
                text
                color="primary"
                @click="showChannelDialog(recipients)"
                class="pl-1 add-channel"
              >
                <v-icon left>
                  mdi-plus-circle-outline
                </v-icon>
                Add channel
              </v-btn>
            </div>
            <v-checkbox
              v-for="year in recipients.scholastic_years"
              :key="year.name"
              v-model="year.selected"
              :label="year.label"
              :input-value="year.selected"
              @change="handleYearCheckBox(year)"
              :aria-checked="year.selected ? 'true' : 'false'"
            />
          </v-card-subtitle>
        </div>
      </v-expand-transition>
      <ChannelsDialog
        :openDialog="eventDetailsDialog"
        :name="name"
        :items="dialogData"
        @close-dialog="(items) => handleResponse(items)"
      />
    </v-col>
  </v-row>
</template>

<script>
import ChannelsDialog from '@/views/Message/BlankMessage/ChannlesDialog';
import { mapGetters } from 'vuex';
export default {
  name: 'ParentsAndCarersRecipient',
  components: {
    ChannelsDialog,
  },
  data() {
    return {
      show: false,
      openDialog: false,
      eventDetailsDialog: false,
      channels: '',
      name: '',
      dialogData: [],
      parents: {
        name: 'Parents and Carers',
        selected: false,
        years: [],
        channels: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      communicationInfo: 'moduleDashboard/communicationInfo',
      getRecipientsByName: 'moduleRecipients/getRecipientsByName',
      event: 'moduleDashboard/getSelectedEvent',
      parentsRecipients: 'moduleRecipients/getParentsRecipients',
      message: 'moduleEventDetails/getSelectedMessage',
    }),
    recipients() {
      return this.parentsRecipients;
    },
    someSubgroupsChecked() {
      let all = this.recipients.scholastic_years.every(year => year.selected)
      let some = this.recipients.scholastic_years.some(year => year.selected)
      if (all) {
        return false;
      } else if (some) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    showChannelDialog(sub) {
      this.dialogData = sub.channels;
      this.name = sub.label;
      this.eventDetailsDialog = true;
    },
    handleResponse() {
      this.eventDetailsDialog = false;
      // const scholasticYears = this.recipients.scholastic_years ?? [];
      // const isParentSelected = scholasticYears.some((year) => year.selected);
      // isParentSelected
      //   ? (this.recipients.selected = true)
      //   : (this.recipients.selected = false);
      // this.$emit('parent-update', {
      //   ...this.recipients,
      //   selected: isParentSelected,
      // });
      const selectedChannels = this.recipients.channels.filter(
        (item) => item.selected === true
      );
      if (selectedChannels.length === 0) {
        this.recipients.selected = false;
        this.recipients.scholastic_years.forEach(item => {
          item.selected = false;
        })
      }
    },
    handleChipClose(itemClosed) {
      for (var item of this.recipients.channels) {
        if (item.name === itemClosed.name) {
          item.selected = false;
        }
      }
      const scholasticYears = this.recipients.scholastic_years ?? [];
      const isParentSelected = scholasticYears.some((year) => year.selected);
      isParentSelected
        ? (this.recipients.selected = true)
        : (this.recipients.selected = false);
      const selectedChannels = this.recipients.channels.filter(
        (item) => item.selected === true
      );
      if (selectedChannels.length === 0) {
        this.recipients.selected = false;
        this.recipients.scholastic_years.forEach(item => {
          item.selected = false;
        })
      }
      this.$emit('parent-update', {
        ...this.recipients,
        selected: isParentSelected,
      });
    },
    handleYearCheckBox() {
      const scholasticYears = this.recipients.scholastic_years ?? [];
      const isParentSelected = scholasticYears.some((year) => year.selected);
      isParentSelected
        ? (this.recipients.selected = true)
        : (this.recipients.selected = false);
      this.$emit('parent-update', {
        ...this.recipients,
        selected: isParentSelected,
      });
    },
    handleParentCheckBox(selected) {
      this.recipients.scholastic_years.forEach((year) => {
        year.selected = selected;
      });
      const scholasticYears = this.recipients.scholastic_years ?? [];
      const isParentSelected = scholasticYears.some((year) => year.selected);
      isParentSelected
        ? (this.recipients.selected = true)
        : (this.recipients.selected = false);
      this.$emit('parent-update', {
        ...this.recipients,
        selected: isParentSelected,
      });
    },
  },
  // watch: {
  //   recipients: {
  //     deep: true,
  //     handler(selection) {
  //       if (selection.selected) {
  //         const selectedChannels = selection.channels.filter(
  //           (item) => item.selected === true
  //         );
  //         if (selectedChannels.length === 0) {
  //           this.recipients.selected = false;
  //           this.recipients.scholastic_years.forEach(item => {
  //             item.selected = false;
  //           })
  //         } else {
  //           this.recipients.selected = true;
  //         }
  //       }
  //     },
  //   },
  // },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-chip.v-chip--outlined.v-chip.v-chip {
    background-color: #cbedfd !important;
  }
  .partial {
    i::before {
      content: '\F0375'
    }
  }
  .v-input--checkbox {
    label {
      margin-left: 8px;
    }
  }
}
</style>
