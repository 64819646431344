<template>
  <div class="container-wrapper">
    <v-container class="py-5">
      <v-row
        justify="center"
        v-if="checklist[0] === undefined"
      >
        <v-col
          cols="12"
          sm="10"
        >
          <NotFound messageStatus="checklist" />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        v-if="checklist[0] !== undefined"
      >
        <v-col
          cols="12"
          sm="10"
        >
          <div class="d-flex">
            <v-img
              src="/images/notification-icon.svg"
              max-width="24px"
              max-height="24px"
              contain
            />
            <label class="ml-2 check_list_alert">{{ warning }}</label>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="10"
        >
          <label class="ad-text">Select a response procedure</label>
        </v-col>
        <v-col
          cols="12"
          sm="10"
        >
          <AdsSelect
            :items="getSelectionItems"
            item-text="title"
            item-value="id"
            v-model="selectedItem"
            return-object
            hide-details
            label="Select a reponse procedure"
            menu-props="offset-y"
          />
        </v-col>
        <v-col
          cols="12"
          sm="10"
        >
          <v-list class="py-0">
            <v-list-item
              class="px-0 mb-6"
              v-for="item in checkListAttributes[0]?.items"
              :key="item.label"
            >
              <v-card
                :color="item.user_selection == true ? '#CBEDFD' : ''"
                class="flex-grow-1"
                :class="{ 'active-selection': item.user_selection == true }"
                :style="
                  item.user_selection
                    ? { border: '2px solid #002664' }
                    : { border: '1px solid' }
                "
                elevation="0"
              >
                <div class="d-flex">
                  <div class="ml-5">
                    <v-checkbox
                      color="primary"
                      :label="item.label"
                      v-model="item.user_selection"
                      @change="onChecked(item)"
                      :input-value="item.label"
                      :aria-checked="item.user_selection ? 'true' : 'false'"
                      :disabled="disabled"
                    />
                  </div>
                </div>
              </v-card>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NotFound from '@/components/MessageCard/NotFound';
import { AdsSelect } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';
export default {
  name: 'CheckList',
  data: () => ({
    warning: 'NOTE: These are suggestions only, See your EMP for all actions',
    selectionItems: [],
    selectedItem: {
      id: '',
      title: '',
    },
    disabled: false,
  }),
  components: {
    NotFound,
    AdsSelect,
  },
  computed: {
    ...mapGetters({
      checklist: 'moduleEventDetails/getAllCheckList',
      school: 'moduleDashboard/getSelectedSchool',
      event: 'moduleDashboard/getSelectedEvent',
    }),
    getSelectionItems() {
      const entries = this.checklist.map((item) => {
        return {
          id: item.category_id,
          title: item.label,
        };
      });
      this.setSelectedItem(entries);
      return entries;
    },
    checkListAttributes() {
      return this.checklist.filter(
        (item) => item.category_id === this.selectedItem.id
      );
    },
  },
  methods: {
    async onChecked(item) {
      const payload = {
        user_selection: item.user_selection,
      };
      this.disabled = true;

      const checkListParams = {
        id: this.event.event_id,
        itemChecklist: true,
      };
      await this.$http
        .put(
          process.env.VUE_APP_API_BASE_URL +
            `/events/${this.event.event_id}/checklists/${this.selectedItem?.id}/${item.checklist_id}`,
          payload
        )
        .then((response) => {
          if (response.data.success) {
            this.$store.dispatch(
              'moduleEventDetails/fetchCheckListById',
              checkListParams
            );
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
    setSelectedItem(entries) {
      if (this.selectedItem.title.length === 0) {
        this.selectedItem = entries[0];
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 968px) {
  .checklist-card,
  .ad-select {
    width: 100% !important;
  }
}

.container-wrapper,
.container-main {
  border-radius: 10px 10px 0px 0px;
  background: var(--greys-white, #fff);
}

.v-card_selected {
  color: "#CBEDFD";
}

.card-details {
  min-height: 80px;
  height: auto;
  .v-input--checkbox {
    margin-top: 0px;
  }
}

.checklist-card {
  width: 800px;
  margin: 0 auto;
}

.ad-text {
  color: #22272b;
  font-family: Public Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.select-text {
  width: 100%;
}

::v-deep {
  .v-input .v-label {
    font-weight: bold;
  }

  .v-input--selection-controls__input {
    i {
      color: #8F949D;
    }
  }

  .v-input__slot label {
    color: #041e42;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 100 !important;
    line-height: 17px;
  }

  .v-input--checkbox {
    label {
      margin-left: 8px;
    }
  }

  .active-selection {
    .v-input--is-focused {
      i {
        color: white !important;
        caret-color: white !important;
        z-index: 1;
      }
      .v-input--selection-controls__ripple:before {
        opacity: 1;
      }
    }
  }
}
</style>
