<template>
  <div>
    <div class="wrapper-bg">
      <v-container class="py-5">
        <v-row justify="center">
          <v-col
            cols="12"
            sm="10"
            :class="`d-flex ${isEmergency ? 'align-end' : 'align-start'}`"
          >
            <div>
              <v-img
                src="/images/message-icon.svg"
                v-if="!isEmergency"
                height="42"
                width="42"
                class="mr-4 mt-2"
              />
              <v-img
                src="/images/warning-icon.svg"
                v-if="isEmergency"
                height="54"
                width="60"
                class="mr-4 mb-1"
              />
            </div>
            <div class="d-flex flex-column aware-wrapper align-left">
              <h2 class="d-flex align-center event-details-title">
                {{ message.title }}
              </h2>
              <div 
                class="d-flex align-center event-school-name"
                v-if="school"
              >
                {{ school.name }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="py-5">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
          class="d-flex"
        >
          <v-avatar
            round
            color="#C7DCF0"
          >
            <span>{{ userInitials }}</span>
          </v-avatar>
          <div class="d-flex pl-4 pr-4 mt-2 flex-column">
            <span class="last-message">
              {{ formatDate(message.created_datetime) }}
            </span>
            <span class="last-message-date"> by {{ message.created_by }}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-divider />
    <v-container class="py-5">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
        >
          <div class="d-flex align-center">
            <h2 class="event_history_title">
              {{ message.title }}
            </h2>
          </div>
          <div class="d-flex align-center mt-2">
            <span v-html="message.message" />
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="10"
        >
          <div class="notify-title-text mb-5">
            Recipients
          </div>
          <v-card>
            <div
              class="d-flex flex-column"
              v-for="item in message.recipients"
              :key="item.name"
            >
              <div
                class="d-flex flex-column"
                v-if="item.selected"
              >
                <div class="primary--text title px-4 py-4">
                  <span class="notify_sub_header">{{ getLabel(item) }}</span>
                </div>
                <v-divider />
                <div
                  class="d-flex"
                  v-if="
                    item.name !== 'PARENT' &&
                      item.channels &&
                      item.channels.length > 0
                  "
                >
                  <div class="d-flex div_chip_filter px-4 py-1">
                    <v-chip
                      v-for="(channel, idx) in getChannels(item.channels)"
                      :key="idx"
                      class="ma-2 ml-0 recipient_chip_text"
                      style="
                        border-radius: 20px;
                        border: 1px solid var(--greys-grey-02, #495054);
                        background: var(--greys-white, #fff);
                      "
                    >
                      {{ channel.name.toUpperCase() }}
                    </v-chip>
                  </div>
                </div>

                <div v-if="getSubGroup(item)">
                  <div
                    class="d-flex flex-column px-4 py-2 div_chip_filter"
                    v-for="(subGroup, idx) in getSubGroup(item)"
                    :key="idx"
                  >
                    <div class="primary--text title">
                      <span class="notify_sub_header">
                        {{ getLabel(subGroup) }}
                      </span>
                    </div>

                    <div class="d-flex flex-row">
                      <v-chip
                        v-for="(channel, index) in getChannels(
                          subGroup.channels
                        )"
                        :key="index"
                        class="ma-2 ml-0 recipient_chip_text"
                        style="
                          border-radius: 20px;
                          background: var(--greys-white, #fff);
                          border: 1px solid var(--greys-grey-02, #495054);
                        "
                      >
                        {{ channel.name.toUpperCase() }}
                      </v-chip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          sm="10"
          class="d-flex align-center"
          v-if="message.post_to_facebook"
        >
          <v-icon class="mr-4">
            mdi-check
          </v-icon> Posted to Facebook
        </v-col>
        <v-col
          cols="12"
          sm="10"
          class="d-flex align-center"
          v-if="message.post_to_school_website"
        >
          <v-icon class="mr-4">
            mdi-check
          </v-icon> Posted to school website
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  name: 'ShowEventDetails',
  props: {
    value: Boolean,
    isEmergency: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({
    eventHistory: {
      school: {},
      eventData: {},
    },
  }),
  methods: {
    formatDate(itemDate) {
      return moment(itemDate).format('DD/MM/YYYY, HH:mm A');
    },
    getLabel(item) {
      if (item.label) {
        return item.label;
      }
      switch (item.name) {
        case 'ERT':
          return 'School emergency response team';
        case 'PARENT':
          return 'Parents and carers';
        case 'STAFF':
          return 'School staff';
        case 'OTHER':
          return 'Other contacts';
      }
      return item.name;
    },
    getSubGroup(item) {
      if (item.scholastic_years) {
        return item.scholastic_years
          .filter((year) => year.selected)
          .map((year) => ({
            ...year,
            channels: item.channels,
          }));
      }
      if (item.items && item.items.length > 0) {
        if (item.items.length === 1 && item.items[0].name === item.name) {
          // strange case with DET and ERT where there is a single sub-item with the same name as the parent
          return null;
        }
        return item.items.filter((item) => item.selected);
      }
      return null;
    },
    getChannels(channels) {
      return channels.filter((channel) => channel.selected);
    },
  },
  computed: {
    ...mapGetters({
      message: 'moduleEventDetails/getSelectedMessage',
      school: 'moduleDashboard/getSelectedSchool',
      userProfile: 'moduleUserProfile/userProfile',
    }),

    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    userInitials() {
      const firstName =
        this.userProfile.firstName || this.$OAuth.data?.profile?.name || '';
      const lastName = this.userProfile.lastName || '';
      const firstInitial = firstName.charAt(0);
      const lastInitial = lastName.charAt(0);

      return `${firstInitial}${lastInitial}`;
    },
  },
};
</script>

<style>
.eventdetails-card {
  width: 800px;
  margin: 0 auto;
}

@media screen and (max-width: 968px) {
  .eventdetails-card {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .userProfile,
  .event-school-name {
    display: none;
  }

  .event_history_header {
    margin-left: 24px;
  }
}
</style>
