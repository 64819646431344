<template>
  <div class="bg-white">
    <div v-if="showRecipients">
      <div class="container-wrapper">
        <v-container>
          <v-row justify="center">
            <v-col
              cols="12"
              sm="10"
              v-if="school"
            >
              <span class="new-event-school-name">{{ school.name }}</span>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <v-container class="py-6">
        <v-row justify="center">
          <v-col
            cols="12"
            sm="10"
          >
            <v-form
              ref="eventForm"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                ref="title"
                outlined
                background-color="white"
                label="Message title"
                v-model="formData.title"
                placeholder="Required"
                required
                :rules="titleRules"
                persistent-placeholder
                :aria-invalid="
                  $refs.title && $refs.title.hasFocused && $refs.title.hasError
                    ? 'true'
                    : 'false'
                "
                :append-icon="
                  $refs.title && $refs.title.hasFocused && $refs.title.hasError
                    ? 'mdi-alert-circle'
                    : ''
                "
                :aria-describedby="
                  $refs.title && $refs.title.hasFocused && $refs.title.hasError
                    ? 'title-error'
                    : false
                "
              >
                <template v-slot:message>
                  <div id="title-error">
                    <template v-for="message in $refs.title.messagesToDisplay">
                      {{ message }}
                    </template>
                  </div>
                </template>
              </v-text-field>

              <v-textarea
                ref="message"
                style="min-height: 276px"
                outlined
                background-color="white"
                label="Message"
                counter="1340"
                v-model="formData.message"
                required
                placeholder="Required"
                :rules="messageRules"
                persistent-placeholder
                :aria-invalid="
                  $refs.message &&
                    $refs.message.hasFocused &&
                    $refs.message.hasError
                    ? 'true'
                    : 'false'
                "
                :append-icon="
                  $refs.message &&
                    $refs.message.hasFocused &&
                    $refs.message.hasError
                    ? 'mdi-alert-circle'
                    : ''
                "
                :aria-describedby="
                  $refs.message &&
                    $refs.message.hasFocused &&
                    $refs.message.hasError
                    ? 'message-error'
                    : false
                "
              >
                <template v-slot:message>
                  <div id="message-error">
                    <template
                      v-for="message in $refs.message.messagesToDisplay"
                    >
                      {{ message }}
                    </template>
                  </div>
                </template>
              </v-textarea>
              <v-row
                v-show="displayAlert"
                class="flex-nowrap mr-2 mb-1"
              >
                <v-col cols="auto pr-0">
                  <v-img
                    src="/images/notification-icon.svg"
                    width="24px"
                    height="24px"
                  />
                </v-col>
                <v-col cols="auto pl-0 flex-shrink-1">
                  <div class="ml-2 check_list_alert">
                    {{ warning }}
                  </div>
                </v-col>
              </v-row>
              <v-card v-show="!update && !close">
                <div class="px-4 py-3">
                  <v-checkbox
                    class="my-0 emergency"
                    v-model="formData.is_emergency"
                    color="primary"
                    hide-details
                    :aria-checked="formData.is_emergency ? 'true' : 'false'"
                  >
                    <template v-slot:label>
                      <div>
                        <div class="new-event-emergency-checkbox-label mb-2">
                          This is an emergency
                        </div>
                        <div class="new-event-emergency-checkbox-label-txt">
                          Present this message as an emergency to selected
                          recipients and the Incident Support Line, and show me
                          the emergency checklists.
                        </div>
                      </div>
                    </template>
                  </v-checkbox>
                </div>
              </v-card>
              <div class="d-flex mt-8 pb-4">
                <h2 class="new-event-recipient-label">
                  Recipients
                </h2>
              </div>
              <Recipients
                @del-channels-update="(items) => handleDelEvents(items)"
                @emergency-channels-update="
                  (items) => handleEmergencyEvents(items)
                "
                @parent-channels-update="(items) => handleParentEvents(items)"
                @staff-subgroup-channels-update="
                  (items) => handleStaffEvents(items)
                "
                @other-subgroup-channels-update="
                  (items) => handleOtherEvents(items)
                "
                :update="this.update"
                :close="this.close"
              />
              <v-checkbox
                v-model="formData.post_to_facebook"
                label="Post to Facebook"
                class="mt-4"
                v-if="checkFbFlag()"
                hide-details
                :aria-checked="formData.post_to_facebook ? 'true' : 'false'"
              />
              <v-checkbox
                v-model="formData.post_to_school_website"
                label="Post to school website"
                class="mt-4"
                v-if="checkWebsiteFlag()"
                hide-details
                :aria-checked="formData.post_to_school_website ? 'true' : 'false'"
              />
              <div
                class="notify_validation_error_msg mt-4"
                v-show="!isRecipientValid"
              >
                You must select at least one Recipient and/or channel from above
              </div>
              <v-row
                justify="space-between"
                class="mt-6"
              >
                <v-col sm="3">
                  <AdsButton
                    primary
                    block
                    @click="validateForm"
                    :disabled="!this.valid || !this.isRecipientValid"
                  >
                    Send
                  </AdsButton>
                </v-col>
              </v-row>
            </v-form>
            <Dialog
              :openDialog="openDialog"
              :actions="actions"
              @close="openDialog = false"
              persistent
              maxWidth="350px"
            >
              <template #title>
                <div class="flex-column px-6 py-6">
                  <div class="d-flex justify-center mt-6">
                    <v-icon
                      size="74"
                      color="#407EC9"
                    >
                      mdi-email-open-outline
                    </v-icon>
                  </div>
                  <div class="d-flex new-event-confirm-text">
                    <span> Confirm you're ready to send this message to: </span>
                  </div>
                </div>
              </template>
              <template #text>
                <div class="px-6 py-2 mb-6 new-event-sent-to-sub-text">
                  <ul class="text-left">
                    <li
                      v-for="recipient in sendToList"
                      :key="recipient"
                    >
                      <span v-html="recipient" />
                    </li>
                  </ul>
                </div>
              </template>
              <template #actions>
                <div class="px-6 pb-6 d-flex justify-space-between">
                  <AdsButton
                    secondary
                    @click="openDialog = false"
                  >
                    Cancel
                  </AdsButton>
                  <AdsButton
                    primary
                    @click="sendMessage"
                  >
                    Confirm and send
                  </AdsButton>
                </div>
              </template>
            </Dialog>
            <Dialog
              :openDialog="confirmDialog"
              :actions="actions"
              @close="confirmDialog = false"
              persistent
              maxWidth="350px"
            >
              <template #title>
                <div class="flex-column px-6 pt-6">
                  <div class="d-flex justify-center py-6">
                    <v-img
                      max-height="149"
                      max-width="149"
                      src="/images/message-sent.svg"
                    />
                  </div>
                  <div class="new-event-sent-to">
                    Message sent to:
                  </div>
                </div>
              </template>
              <template #actions>
                <div class="d-flex justify-center px-6 pt-4 pb-10">
                  <AdsButton
                    primary
                    @click="onCloseConfirm()"
                  >
                    Close
                  </AdsButton>
                </div>
              </template>
              <template #text>
                <div class="px-4 py-2 new-event-sent-to-sub-text">
                  <ul class="text-left">
                    <li
                      v-for="recipient in sendToList"
                      :key="recipient"
                    >
                      <span v-html="recipient" />
                    </li>
                  </ul>
                </div>
              </template>
            </Dialog>
            <Dialog
              v-if="!close && !update"
              :openDialog="showEmergencyDialog"
              icon="mdi-check-circle-outline"
              @close="showEmergencyDialog = false"
              persistent
              content-class="close-message"
            >
              <template #title>
                <v-card-title class="d-flex justify-space-between">
                  <h2 class="title">
                    <v-avatar size="40">
                      <v-icon>mdi-alert-outline</v-icon>
                    </v-avatar>
                    For emergencies
                  </h2>
                </v-card-title>
              </template>
              <template #actions>
                <div class="px-6 pb-6 d-flex justify-end">
                  <AdsButton
                    primary
                    @click="showEmergencyDialog = false"
                  >
                    OK
                  </AdsButton>
                </div>
              </template>
              <template #text>
                <div class="px-6 py-4">
                  <label class="primary--text">
                    Before sending this message, please ensure:
                    <ul>
                      <li>
                        You have sought advice from your DEL before implementing your local communication plan
                      </li>
                      <li>
                        Refer to your EMP to follow the appropriate emergency management procedures
                      </li>
                    </ul>
                  </label>
                </div>
              </template>
            </Dialog>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Recipients from '@/views/Message/BlankMessage/Recipients';
import { AdsButton, Dialog } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';

const initialFormData = {
  category_id: '',
  title: '',
  message: '',
  is_emergency: false,
  post_to_facebook: false,
  post_to_school_website: false,
  schoolCode: '',
  recipients: '',
};

export default {
  name: 'BlankMessage',
  components: {
    Recipients,
    AdsButton,
    Dialog,
  },
  props: {
    value: Boolean,
    tempId: {
      type: String,
      default: '',
    },
    eventDel: {
      type: Object,
      default: () => {
        return {};
      },
    },
    close: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actions: [
        { name: 'Cancel', color: '#041E42', size: 'large', outlined: true },
        {
          name: 'Confirm and send',
          color: '#041E42',
          size: 'large',
          outlined: false,
        },
      ],
      titleRules: [
        (v) => !!v || 'Title is required',
        (v) => (v && v.length <= 64) || 'Title must be less than 64 characters',
      ],
      messageRules: [
        (v) => !!v || 'Message is required',
        (v) =>
          (v && v.length <= 1340) ||
          'Message must be less than 1340 characters',
        this.displayWarning,
      ],
      rules: {
        required: (v) => !!v || 'Please enter a message description',
        lengthCheck: (v) =>
          (v && v.length <= 20) || 'Name must be less than 20 characters',
      },
      valid: true,
      displayAlert: false,
      warning:
        'NOTE: This message exceeds SMS length (approx. 160 characters). It will be sent as separate SMS messages.',
      openDialog: false,
      confirmDialog: false,
      isRecipientValid: true,
      isDelValid: false,
      isEmergencyValid: false,
      isParentValid: false,
      isSchoolStaffValid: false,
      isOtherContactValid: false,
      showRecipients: false,
      eventId: '',
      formData: { ...initialFormData },
      showEmergencyDialog: false
    };
  },
  computed: {
    ...mapGetters({
      school: 'moduleDashboard/getSelectedSchool',
      eventFormData: 'moduleTemplates/getEventData',
      userProfile: 'moduleUserProfile/userProfile',
      delRes: 'moduleRecipients/getDelRecipients',
      emergencyRes: 'moduleRecipients/getEmergencyRecipients',
      schoolStaffRes: 'moduleRecipients/getStaffRecipients',
      parentsRes: 'moduleRecipients/getParentsRecipients',
      otherRes: 'moduleRecipients/getOtherContactsRecipients',
      templatesMetaData: 'moduleTemplates/getTemplatesMetaData',
      event: 'moduleDashboard/getSelectedEvent',
      communicationInfo: 'moduleDashboard/communicationInfo',
      selectedTemplate: 'moduleTemplates/getSelectedTemplates',
      messageHistory: 'moduleEventDetails/getAllHistory',
      getRecipientsByName: 'moduleRecipients/getRecipientsByName',
    }),
    sendToList() {
      let sendToList = [];
      sendToList.push(this.delRes);
      sendToList.push(this.emergencyRes);
      sendToList.push(this.parentsRes);
      sendToList.push(this.schoolStaffRes);
      sendToList.push(this.otherRes);

      const formatter = new Intl.ListFormat('en-GB', {
        style: 'long',
        type: 'conjunction',
      });
      const format = (recipient) => {
        //format to get comma separated
        let label = recipient.name;
        let subGroupText = '';
        switch (recipient.name) {
          case 'ERT':
            label = 'School emergency response team';
            break;
          case 'PARENT': {
            label = 'Parents and carers';
            const allSubGroupSelected = recipient.scholastic_years.every(
              (item) => item.selected
            );
            if (
              !allSubGroupSelected &&
              recipient.scholastic_years.some((item) => item.selected)
            ) {
              const selectedSubGroups = recipient.scholastic_years.filter(
                (item) => item.selected
              );
              // here we break the sub group into two arrays: one that has label starting with year, and the other not.
              const yearSubGroups = selectedSubGroups.filter((item) =>
                item.label.startsWith('Year ')
              );
              const otherSubGroups = selectedSubGroups.filter(
                (item) => !item.label.startsWith('Year ')
              );

              // straight-forward concatenation: 'pre-school, kindergarten'
              const otherSubGroupText =
                yearSubGroups.length === 0
                  ? formatter.format(otherSubGroups.map((item) => item.label))
                  : otherSubGroups.map((item) => item.label).join(', ');
              // leading word year followed by the list of numbers: 'years 8,9, 10'
              const yearSubGroupText =
                yearSubGroups.length === 0
                  ? ''
                  : (yearSubGroups.length === 1 ? 'year ' : 'years ') +
                    formatter.format(
                      yearSubGroups.map((item) =>
                        item.label.substring('Year '.length)
                      )
                    );
              subGroupText = `${otherSubGroupText}${
                otherSubGroupText.length > 0 ? ', ' : ''
              }${yearSubGroupText}`;
            }
            break;
          }

          case 'STAFF': {
            label = 'School staff';
            const allSubGroupSelected = recipient.items.every(
              (item) => item.selected
            );
            const allSchoolStaff = recipient.items.find(item => item.label === 'All school staff')
            if (allSchoolStaff.selected) {
              subGroupText = 'All school staff'
            } else if (
              !allSubGroupSelected &&
              recipient.items.some((item) => item.selected)
            ) {
              const selectedSubGroups = recipient.items.filter(
                (item) => item.selected
              );
              subGroupText = formatter.format(
                selectedSubGroups.map((item) => item.label).sort()
              );
            }
            break;
          }

          case 'OTHER': {
            label = 'Other contacts';
            const allSubGroupSelected = recipient.items.every(
              (item) => item.selected
            );
            if (
              !allSubGroupSelected &&
              recipient.items.some((item) => item.selected)
            ) {
              const selectedSubGroups = recipient.items.filter(
                (item) => item.selected
              );
              subGroupText = formatter.format(
                selectedSubGroups.map((item) => item.label)
              );
            }
            break;
          }
        }
        return `<strong>${label}</strong> ${subGroupText}`;
      };

      const sendToListLabels = sendToList
        .filter((recipient) => recipient.selected)
        .map((recipient) => format(recipient));
      if (this.formData.post_to_facebook) {
        sendToListLabels.push('<strong>Facebook</strong>');
      }
      if (this.formData.post_to_school_website) {
        sendToListLabels.push('<strong>School Website Service</strong>');
      }
      return sendToListLabels;
    },
  },

  async created() {
    const commInfo = this.communicationInfo;
    if (commInfo.recipients && commInfo.recipients.length > 0) {
      this.showRecipients = true;
    }
    if (this.update || this.close) {
      this.formData.id = this.event?.event_id;
      this.eventId = this.event?.event_id;
      this.formData.is_emergency = this.event?.is_emergency;
    }

    this.resetFormData();
  },

  methods: {
    displayWarning(val) {
      if (val.length >= 160) {
        this.displayAlert = true;
      } else {
        this.displayAlert = false;
      }
      return true;
    },
    filterTemplateById() {
      return (
        this.templatesMetaData &&
        this.templatesMetaData.filter((item) => item.id === this.tempId)
      );
    },
    handleDelEvents() {
      this.validateRecipients();
    },
    handleEmergencyEvents() {
      this.validateRecipients();
    },
    handleParentEvents() {
      this.validateRecipients();
    },
    handleStaffEvents() {
      this.validateRecipients();
    },
    handleOtherEvents() {
      this.validateRecipients();
    },
    onCloseConfirm() {
      this.$store.dispatch('moduleDashboard/fetchSchoolNotifications');
      this.confirmDialog = false;
      this.$eventHub.$emit('close-fsdialog');
    },
    validateForm() {
      const recipientsValid = this.validateRecipients();
      if (this.$refs.eventForm.validate() && recipientsValid) {
        this.openDialog = true;
      }
    },

    validateRecipients() {
      this.isDelValid = this.delRes && this.checkValid(this.delRes);
      this.isEmergencyValid =
        this.emergencyRes && this.checkValid(this.emergencyRes);
      this.isParentValid = this.parentsRes && this.checkValid(this.parentsRes);
      this.isSchoolStaffValid =
        this.schoolStaffRes && this.checkValid(this.schoolStaffRes);
      this.isOtherContactValid =
        this.otherRes && this.checkValid(this.otherRes);

      this.isRecipientValid =
        this.isDelValid ||
        this.isEmergencyValid ||
        this.isParentValid ||
        this.isSchoolStaffValid ||
        this.isOtherContactValid ||
        this.formData.post_to_facebook ||
        this.formData.post_to_school_website;
      return this.isRecipientValid;
    },
    checkFbFlag() {
      const isFacebookEnabled =
        this.communicationInfo.channels &&
        this.communicationInfo.channels.some(
          (channel) => channel.name === 'facebook' && channel.enabled
        );
      return isFacebookEnabled;
    },

    checkWebsiteFlag() {
      const isWebsiteEnabled =
        this.communicationInfo.channels &&
        this.communicationInfo.channels.some(
          (channel) => channel.name === 'schoolwebsite' && channel.enabled
        );
      return !this.formData.is_emergency ? false : isWebsiteEnabled;
    },
    buildFormData() {
      const items = [];
      items.push(this.delRes);
      items.push(this.emergencyRes);
      items.push(this.parentsRes);
      items.push(this.schoolStaffRes);
      items.push(this.otherRes);

      //remove this when we add channel granularity back into subgroups
      for (let item of items) {
        if (item.name === 'STAFF' || item.name === 'OTHER') {
          for (let subgroup of item.items) {
            subgroup.channels = item.channels;
          }
        }
        if (item.name === 'STAFF') {
          let allStaff = item.items.find(subgroup => subgroup.label === 'All school staff')
          if (allStaff.selected) {
            for (let subgroup of item.items) {
              if (subgroup.label !== 'All school staff') {
                subgroup.selected = false
              }
            }
          }
        }
      }

      const recipients = [...items];
      return recipients;
    },

    handleBadgeFlag() {
      if (this.close) {
        return 'Closed';
      } else if (this.update) {
        return 'Updated';
      } else {
        return 'Initiated';
      }
    },
    async sendMessage() {
      const recipients = this.buildFormData();
      this.$store.commit('SET_IS_LOADING', 'sendMessage', {
        root: true,
      });
      const badgeFlag = this.handleBadgeFlag();
      const data = {
        ...this.formData,
        schoolCode: this.school.code,
        badge: badgeFlag,
        recipients: recipients,
      };

      let api =
        this.update || this.close
          ? process.env.VUE_APP_API_BASE_URL + `/events/${this.formData.id}`
          : process.env.VUE_APP_API_BASE_URL + '/events';
      await this.$http
        .post(api, data)
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.dispatch('moduleDashboard/fetchSchoolNotifications');
          this.$store.commit('REMOVE_IS_LOADING', 'sendMessage', {
            root: true,
          });
          this.openDialog = false;
          this.confirmDialog = true;
        });
    },
    resetFormData() {
      if (this.tempId) {
        const filteredRes = this.filterTemplateById();
        if (filteredRes && filteredRes.length) {
          const res = filteredRes[0];
          this.formData.category_id = res.catId;
          this.formData.title = res.title;
          this.formData.message = res.description;
          this.formData.is_emergency = res?.is_emergency;
        }
      } else {
        this.formData = { ...initialFormData };
      }
      this.formData.id = '';
      if (this.update || this.close) {
        this.formData.id = this.event?.event_id;
        this.formData.is_emergency = this.event?.is_emergency;

        if (this.messageHistory && this.messageHistory.length > 0) {
          let initiateRecipients = this.messageHistory.find(
            (message) => message.badge === 'Initiated'
          );
          this.$store.dispatch(
            'moduleRecipients/updateRecipients',
            initiateRecipients.recipients
          );
          this.formData.post_to_facebook = initiateRecipients.post_to_facebook;
          this.formData.post_to_school_website =
            initiateRecipients.post_to_school_website;
        }
      } else {
        this.$store.dispatch(
          'moduleRecipients/fetchRecipientByTemplateId',
          this.communicationInfo
        );
      }
      if (this.$refs.eventForm) {
        this.$refs.eventForm.resetValidation();
      }
    },
    checkValid(recipient) {
      return (
        recipient.selected &&
        recipient.channels.some((channel) => channel.selected)
      );
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.resetFormData();
      }
    },
    'formData.is_emergency': function (newVal) {
      if (!newVal) {
        this.formData.post_to_school_website = false;
      } else {
        this.showEmergencyDialog = true;
      }
    },
    'formData.post_to_school_website': function () {
      this.validateRecipients();
    },
    'formData.post_to_facebook': function () {
      this.validateRecipients();
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-style {
  height: 44px;
  align-items: center;
  margin-top: 10px;
  border-radius: 4px;
  background: var(--doe-brand-colours-primary-blue, #002664);
}

.ad-text {
  color: #22272b;
  font-family: Public Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}

.emergencyCheck {
  height: 106px;
}

.eventForm {
  max-width: 989px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .container-main {
    padding: 10px;
  }

  .eventForm {
    max-width: 100%;
  }

  .select-text {
    display: none;
  }
}

.emergencyCheck {
  min-height: 106px;
  height: auto;
}

.container-wrapper {
  background-color: #cbedfd;
  width: 100%;
}

.bg-white {
  background-color: #fff;
}

::v-deep .emergency {
  .v-input__slot {
    display: flex;
    align-items: flex-start;
  }
}

::v-deep {
  .v-input--checkbox .theme--light.v-label {
    color: var(--greys-grey-01, #22272b);
    font-weight: bold;
  }

  .v-input--selection-controls__input {
    i {
      color: #a0a5ae;
    }
  }
}

.v-card {
  .v-card__title {
    padding: 24px 10px 0 24px !important;
    color: #002664 !important;
    .title {
      font-size: 1.125rem !important;
      display: flex;
      align-items: center;
      font-weight: 700;
    }

    .v-avatar {
      background-color: #cbedfd !important;
      margin-right: 10px;
    }
  }

  .content {
    padding: 0 24px;
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 24px;
    color: #002664 !important;
    background-color: unset !important;
  }

  .v-card__actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px 24px 24px !important;

    .v-btn {
      margin-left: 12px !important;
      padding: 0 16px;
      color: #fff;
    }
  }

  .v-btn:focus {
    outline: 3px auto -webkit-focus-ring-color;
  }
}
</style>
