<template>
  <v-row
    no-gutters
    v-if="recipients"
  >
    <v-col cols="12">
      <v-card-title
        class="py-0"
        :style="show ? { 'background-color': '#cbedfd' } : {}"
      >
        <v-checkbox
          v-model="recipients.selected"
          :label="recipients.label"
          @click="handleDelCheckBox()"
          :aria-checked="recipients.selected ? 'true' : 'false'"
          :disabled="!delContact"
        />
        <v-spacer />
        <v-btn
          icon
          @click="show = !show"
          aria-label="Expand for DEL recipient options"
          :aria-expanded="show ? 'true' : 'false'"
        >
          <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-expand-transition>
        <div v-show="show">
          <v-card-subtitle class="d-flex align-center flex-wrap">
            <div 
              class="d-flex mr-2"
              v-if="delContact"
            >
              <v-chip
                v-for="(dname, j) in recipients.channels"
                :key="j"
                outlined
                v-show="dname.selected"
                close
                color="#002664"
                class="mr-1"
                @click:close="handleChipClose(dname)"
              >
                {{ dname.name }}
              </v-chip>
            </div>
            <v-btn
              prepend-icon
              rounded
              text
              color="primary"
              @click="showChannelDialog()"
              class="pl-1 add-channel"
              :disabled="!delContact"
            >
              <v-icon
                left
                class="font-weight-bold"
              >
                mdi-plus-circle-outline
              </v-icon>
              Add channel
            </v-btn>
          </v-card-subtitle>
        </div>
      </v-expand-transition>
      <ChannelsDialog
        :openDialog="eventDetailsDialog"
        @close-dialog="(items) => handleResponse(items)"
        :name="recipients.name"
        :items="recipients.channels"
      />
    </v-col>
  </v-row>
</template>

<script>
import ChannelsDialog from '@/views/Message/BlankMessage/ChannlesDialog';
import { mapGetters } from 'vuex';
export default {
  name: 'DelRecipient',
  components: {
    ChannelsDialog,
  },
  data() {
    return {
      show: false,
      openDialog: false,
      eventDetailsDialog: false,
      channels: '',
    };
  },
  props: {
    close: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      delContact: 'moduleManageContacts/getDelContact',
      school: 'moduleDashboard/getSelectedSchool',
      communicationInfo: 'moduleDashboard/communicationInfo',
      getRecipientsByName: 'moduleRecipients/getRecipientsByName',
      event: 'moduleDashboard/getSelectedEvent',
      delRecipients: 'moduleRecipients/getDelRecipients',
      message: 'moduleEventDetails/getSelectedMessage',
    }),
    recipients() {
      return this.delRecipients;
    },
  },
  methods: {
    showChannelDialog() {
      this.eventDetailsDialog = true;
    },
    handleResponse(data) {
      if (data.channels.length > 0) {
        this.recipients.channels = data.channels;
        this.$emit('del-update', this.recipients);
      }
      this.eventDetailsDialog = false;
    },
    handleChipClose(itemClosed) {
      for (var item of this.recipients.channels) {
        if (item.name === itemClosed.name) {
          item.selected = false;
        }
      }
      this.$emit('del-update', this.recipients);
    },
    handleDelCheckBox() {
      this.$emit('del-update', this.recipients);
    },
  },
  watch: {
    recipients: {
      deep: true,
      handler(selection) {
        if (selection.selected) {
          const selectedChannels = selection.channels.filter(
            (item) => item.selected === true
          );
          this.recipients.selected =
            selectedChannels.length === 0 ? false : true;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-chip.v-chip--outlined.v-chip.v-chip {
    background-color: #cbedfd !important;
  }
  .v-input--checkbox {
    label {
      margin-left: 8px;
    }
  }
}
</style>
