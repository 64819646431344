<template>
  <div
    class="light10 fill-height"
    style="width: 100%"
  >
    <EventUpdate @close-event-details="$emit('close-event-details')" />
    <CheckListAndHistory />
  </div>
</template>

<script>
import CheckListAndHistory from '@/views/EventDetails/CheckListAndHistory';
import EventUpdate from '@/views/EventDetails/EventDetails';
export default {
  name: 'EventDetails',
  /**
   *  The title option is used by titleMixin for implementing dynamic <title> in SPA for accessibility
   *  Recommended title for each 'view/page' is 'Page name - Site Name'.
   */
  title: 'Dashboard - School Notify',
  components: {
    EventUpdate,
    CheckListAndHistory,
  },
  mounted() {},
  created() {
    //this.$store.dispatch('moduleEvents/fetchAllEvents','890');
    // this.$store.dispatch('moduleDashboard/fetchAllSchoolNotifications');
  },
};
</script>

<style></style>
