<template>
  <v-card
    v-if="
      this.communicationInfo &&
        Array.isArray(this.communicationInfo.recipients) &&
        this.communicationInfo.recipients.length > 0
    "
  >
    <div v-show="doesNameExist('DEL')">
      <DelRecipient
        @del-update="(items) => handleDelEvents('del-channels-update', items)"
        :update="this.update"
        :close="this.close"
      />
      <v-divider />
    </div>
    <div v-show="doesNameExist('ERT')">
      <EmergencyRecipient
        @emergency-update="
          (items) => handleEmergencyEvents('emergency-channels-update', items)
        "
      />
      <v-divider />
    </div>
    <div v-show="doesNameExist('STAFF')">
      <SchoolStaffRecipient
        @staff-subgroup-update="
          (items) =>
            handleSubgroupEvent('staff-subgroup-channels-update', items)
        "
      />
      <v-divider />
    </div>
    <div v-show="doesNameExist('PARENT')">
      <ParentsAndCarersRecipient
        @parent-update="
          (items) => handleParentsEvents('parent-channels-update', items)
        "
      />
      <v-divider />
    </div>
    <div v-show="doesNameExist('OTHER')">
      <OtherRecipient
        @other-subgroup-update="
          (items) =>
            handleSubgroupEvent('other-subgroup-channels-update', items)
        "
      />
    </div>
  </v-card>
</template>

<script>
import DelRecipient from '@/views/Message/BlankMessage/DelRecipient';
import EmergencyRecipient from '@/views/Message/BlankMessage/EmergencyRecipient';
import OtherRecipient from '@/views/Message/BlankMessage/OtherRecipient';
import ParentsAndCarersRecipient from '@/views/Message/BlankMessage/ParentsAndCarersRecipient';
import SchoolStaffRecipient from '@/views/Message/BlankMessage/SchoolStaffRecipient';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Recipients',
  components: {
    DelRecipient,
    EmergencyRecipient,
    ParentsAndCarersRecipient,
    SchoolStaffRecipient,
    OtherRecipient,
  },
  props: {
    close: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      flag: false,
      show: false,
      totalSelectedChannels: true,
      selection: [],
      groups: {
        id: 2,
        name: 'test',
      },
    };
  },
  computed: {
    ...mapState('moduleRecipients', {
      recipients: (state) => state.recipients,
    }),
    ...mapGetters({
      del: 'moduleRecipients/getDelRecipients',
      emergency: 'moduleRecipients/getEmergencyRecipients',
      parents: 'moduleRecipients/getParentsRecipients',
      communicationInfo: 'moduleDashboard/communicationInfo',
    }),
    delChannels: {
      get() {
        return this.recipients.del.channels;
      },
    },
  },
  methods: {
    showChannelDialog() {
      this.selection = this.delChannels;
    },
    updateSelection() {
      var updatedChannel = {
        gName: this.channels.name,
        cName: this.channels.channels,
      };
      this.$store.dispatch(
        'moduleTemplates/updateSelectedChannels',
        updatedChannel
      );
    },
    removeSelectedChannel(chName, groupName) {
      var updatedChannel = { key: groupName, cName: chName, val: false };
      this.$store.dispatch(
        'moduleRecipients/updateSelectedChannel',
        updatedChannel
      );
    },
    handleDelEvents(eventName, items) {
      this.$emit(eventName, items);
    },
    handleEmergencyEvents(eventName, items) {
      this.$emit(eventName, items);
    },
    handleParentsEvents(eventName, items) {
      this.$emit(eventName, items);
    },
    handleSubgroupEvent(eventName, items) {
      this.$emit(eventName, items);
    },
    doesNameExist(targetName) {
      const check =
        this.recipients &&
        Array.isArray(this.recipients) &&
        this.recipients.some((recipient) => recipient.name === targetName);
      return check;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn--d-style {
  height: 44px;
  width: 100%;
  align-items: center;
}
::v-deep {
  .v-expansion-panel-header {
    padding: 4px;
  }
  .v-expansion-panel-content {
    // padding: 0px 0px;
  }
  .v-expansion-panel-content__wrap {
    // padding: 0px 15px 0px;
  }
}
</style>
