<template>
  <v-app :class="{ mobile: $vuetify.breakpoint.xsOnly }">
    <v-main :inert="loading">
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>

    <v-dialog
      fullscreen
      :showCloseButton="false"
      v-model="sessionExpired"
      transition="dialog-bottom-transition"
      content-class="unauthorised-dialog"
      persistent
    >
      <v-card class="d-flex flex-column">
        <v-container
          :class="
            $vuetify.breakpoint.mobile
              ? 'unauthorised-mobile-device'
              : 'unauthorised-banner'
          "
          style="font-size: 32px; font-weight: bold"
        >
          <v-row justify="center">
            <v-col
              cols="12"
              sm="10"
            >
              <v-img
                src="/images/logo.svg"
                max-height="39"
                max-width="39"
                class="mb-12"
              />
              We cannot log you in
            </v-col>
          </v-row>
        </v-container>
        <v-container class="d-flex flex-grow-1 align-center text-center">
          <v-row>
            <v-col>
              <v-icon
                size="70"
                color="blue"
                class="mb-6"
              >
                mdi-alert-circle-outline
              </v-icon>
              <p
                class="font-weight-bold"
                style="font-size: 20px"
              >
                There seems to be an issue with logging you in successfully.
              </p>
              <p>
                Please reach out to your school principal to request access to
                School Notify. For further assistance, please
                <a
                  target="_blank"
                  href="https://nswdoeesmp1.service-now.com/services_gateway?sys_id=807e2dc397993910dfae7846f053afb4&si=Other%20-%20IT&id=sg_item"
                >
                  contact the school Notify team
                </a>
              </p>
              <AdsButton
                button-text="Log out"
                class="mt-5"
                @click="logout"
                v-if="showDebug"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- App-wide component -->
    <BlockUI 
      v-if="loading" 
      role="status" 
    />
    <!-- </div> -->
  </v-app>
</template>

<script>
import { BlockUI, AdsButton } from '@nswdoe/doe-ui-core';
import axios from 'axios';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { getXIdToken, getContextualSchoolCode, getAccessToken } from './utils';

Vue.prototype.$http = axios.create();

export default {
  name: 'App',
  components: {
    BlockUI,
    AdsButton,
  },

  computed: {
    ...mapGetters({
      userProfile: 'moduleUserProfile/userProfile',
      loading: 'loading',
    }),
    userFirstName() {
      if (this.userProfile.firstName) {
        return this.userProfile.firstName;
      } else {
        return this.$OAuth.data?.profile?.name;
      }
    },
    userLastName() {
      if (this.userProfile.lastName) {
        return this.userProfile.lastName;
      } else {
        return '';
      }
    },
    showDebug() {
      return process.env.NODE_ENV !== 'production';
    },
  },
  created() {
    this.$store.commit('RESET_IS_LOADING');
    this.$store.dispatch('moduleDashboard/fetchEmergencyChecklists')
    this.$http.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    this.$http.interceptors.request.use(
      (config) => {
        if (config.url !== '/school/list') {
          config.headers.common['x-school-code'] = getContextualSchoolCode();
        }
        config.headers.common['x-id-token'] = getXIdToken(this.$OAuth.data);
        config.headers.common['x-access-token'] = getAccessToken(
          this.$OAuth.data
        );
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.$http.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          (error.response && error.response.status === 403) ||
          error.response.status === 401
        ) {
          this.sessionExpired = true;
        }
        return Promise.reject(error);
      }
    );
  },
  methods: {
    clearError() {
      this.$store.commit('SET_HAS_ERROR', false);
    },
    logout() {
      window.sessionStorage.removeItem('vuex');
      this.$OAuth.logout();
    },
  },
  data() {
    return {
      navDrawerItems: [
        {
          title: 'Dashboard',
          icon: 'mdi-view-dashboard-outline',
          iconOutlined: false,
          route: '/',
        },
        {
          title: 'Settings',
          icon: 'settings',
          iconOutlined: true,
          route: '',
          items: [
            {
              title: 'Manage Templates',
              iconOutlined: true,
              route: '/managetemplates',
            },
            {
              title: 'Manage Contacts',
              icon: 'mdi-account-group-outline',
              iconOutlined: true,
              route: '/contacts',
            },
            {
              title: 'Help',
              iconOutlined: true,
              route: '/help',
            },
          ],
        },
      ],
      sessionExpired: false,
    };
  }
};
</script>

<style lang="scss">
.container {
  padding: 12px 20px;

  .v-list--dense .v-list-item {
    min-height: 30px;
  }

  .container {
    padding: 0;
  }
}

// Fix banner bug
.banner.row {
  margin-top: 0;
  margin-bottom: 0;
}

// Unauthorised dialog layout override
.unauthorised-dialog {
  .v-card {
    height: 100%;
  }
  .contentWrapper {
    display: flex;
    height: calc(100% - 56px);
  }
}

.unauthorised-mobile-device {
  background-image: url('/images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100% !important;
  height: 200px;
  width: 100%;
}

.unauthorised-banner {
  background-image: url('/images/page-banner.png');
  background-size: cover;
  max-width: 100% !important;
  width: 100%;
  height: 200px;
}

@media print {
  .v-main {
    padding: 0 !important;
  }
}
</style>
