<template>
  <div style="width: 100%">
    <AdsButton
      buttonText="Update"
      icon="mdi-history"
      @click="onUpdate"
      block
    />
    <v-dialog
      v-model="showDialog"
      fullscreen
      persistent
      hide-overlay
      transition="slide-x-reverse-transition"
      content-class="update-message-dialog bg-grey"
    >
      <v-card class="rounded-0">
        <v-toolbar color="primary">
          <v-btn
            icon
            color="white"
            @click="showDialog = false"
            aria-label="Back"
          >
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title>
            <h1>New message</h1>
          </v-toolbar-title>
        </v-toolbar>
        <NewMessage update />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import NewMessage from '@/views/Message/NewMessage/NewMessage';
import { AdsButton } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';

export default {
  name: 'UpdateMessageDialog',
  components: {
    AdsButton,
    NewMessage,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  props: {
    subGroupType: {
      type: String,
      default: '',
    },
    tertiary: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      updateTemplate: 'moduleTemplates/getUpdateTemplate',
    }),
  },
  created() {},
  watch: {
    showDialog(newValue) {
      if (newValue && this.subGroupType === 'staff') {
        this.$store.dispatch(
          'moduleManageContacts/fetchSchoolStaffContactsList'
        );
      }
      if (newValue && this.subGroupType === 'other') {
        this.$store.dispatch('moduleManageContacts/fetchOtherContactsList');
      }
    },
  },
  methods: {
    onUpdate() {
      this.$store.dispatch(
        'moduleTemplates/updateSelectedTemplate',
        this.updateTemplate
      );
      this.showDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.update-message-dialog {
  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 10px;
    h1 {
      font-size: 20px;
    }
  }
}
</style>
