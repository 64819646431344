import Dashboard from '@/views/Dashboard';
import ManageNotifications from '@/views/ManageNotifications';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Dashboard,
    meta: {
      protected: true
    },
  },
  {
    path: '/notifications',
    component: ManageNotifications,
  },
  {
    path: '/opt', // for shortened links used in sms
    component: ManageNotifications,
  },
  // {
  //   path: '/login/callback',
  //   component: Dashboard,
  //   redirect: {
  //     path: '/'
  //   }
  // },
  // {
  //   path: '/:catchAll(.*)',
  //   component: Dashboard,
  //   meta: {
  //     protected: true
  //   }
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
