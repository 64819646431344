import axios from 'axios';

/* eslint no-console: "off" */
const fetchSchoolDetails = (schoolCode) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/school/${schoolCode}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchContactDetails = (contactId, schoolCode) => {
  return axios.get(`${process.env.VUE_APP_API_BASE_URL}/contacts/${contactId}`, {
    headers: {
      'X-School-Code': schoolCode,
    }
  });
}

const optInOutOther = (contactId, schoolCode, status) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `${process.env.VUE_APP_API_BASE_URL}/contacts/${contactId}/opt${status}`,
        null,
        {
          headers: {
            'X-School-Code': schoolCode,
          },
        }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const optOutCarer = (email, phone, optOutId) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        `${process.env.VUE_APP_API_BASE_URL}/contacts/parent_carer/optOut?optOutId=${optOutId}`,
        {
          email: email,
          phone: phone
        },
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default {
  fetchSchoolDetails,
  fetchContactDetails,
  optInOutOther,
  optOutCarer
};
