<template>
  <div>
    <AdsButton
      :buttonText="buttonText"
      primary
      icon="mdi-plus-circle-outline"
      @click="showDialog = true"
      v-if="!tertiary"
    />
    <v-btn 
      v-else
      icon
      @click="showDialog = true"
      aria-label="Edit"
      class="ma-2"
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="showDialog"
      fullscreen
      persistent
      hide-overlay
      transition="slide-x-reverse-transition"
      content-class="edit-template-dialog"
    >
      <v-card class="rounded-0">
        <v-toolbar color="primary">
          <v-btn
            icon
            color="white"
            @click="showDialog = false"
            aria-label="Back"
          >
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ tempTitle }}
          </v-toolbar-title>
        </v-toolbar>
        <EditTemplate
          :templateId="tempId"
          @close-dialog="showDialog = false"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import EditTemplate from '@/views/Settings/Templates/EditTemplate';
import { AdsButton } from '@nswdoe/doe-ui-core';

export default {
  name: 'FullScreenManageTemplateDialog',
  components: {
    EditTemplate,
    AdsButton,
  },
  props: {
    tempId: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    tertiary: {
      type: Boolean,
      default: false,
    },
    tempTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  created() {},
};
</script>
<style lang="scss" scoped>
.edit-template-dialog {
  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 10px;
    font-weight: 700;
  }
}
</style>
