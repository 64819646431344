<template>
  <div class="container-wrapper">
    <v-container class="py-2">
      <div v-if="closedMessages.length == 0">
        <MessageNotFound messageStatus="closed" />
      </div>
      <div v-else>
        <Message :messages="closedMessages" />
      </div>
    </v-container>
  </div>
</template>
<script>
import Message from '@/components/MessageCard/Message';
import MessageNotFound from '@/components/MessageCard/MessageNotFound';
import { mapGetters } from 'vuex';
export default {
  name: 'ClosedMessage',
  components: {
    Message,
    MessageNotFound,
  },
  computed: {
    ...mapGetters({
      closedMessages: 'moduleDashboard/closedMessages',
    }),
  },
  methods: {

  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.container-wrapper,
.container-main {
  border-radius: 10px 10px 0px 0px;
  background: var(--greys-white, #fff);
}
</style>
