export default [
  {
    label: 'Evacuation',
    category_id: 'f5cba71b-bf54-4ab3-8065-9c2c4cb3da49',
    items: [
      {
        label: 'Call Triple Zero (000)',
        checklist_id: '17f603a7-af65-49c0-ba48-e35b3683c7d3',
        user_selection: true,
      },
      {
        label: 'Trigger the evacuation signal',
        checklist_id: 'b95e5703-0727-48e9-b08a-5d4e12f6b4dd',
        user_selection: false,
      },
      {
        label: 'Collect Evacuation/Emergency Kit',
        checklist_id: 'b95e5703-0727-48e9-b08a-5d4e12f6b4da',
        user_selection: false,
      },
      {
        label: 'Notify the Incident Report and Support Hotline 1800 811 523',
        checklist_id: 'db4e00a6-d516-4d8f-a6d8-fbb55e45896a',
        user_selection: true,
      },
      {
        label: 'Contact your DEL',
        checklist_id: 'db4e00a6-d516-4d8f-a6d8-fbb55e45896d',
        user_selection: true,
      },
      {
        label:
          'Direct students, staff and visitors to the most appropriate assembly/evacuation point',
        checklist_id: '4cc636ac-d26f-409f-899b-271550df8953',
        user_selection: true,
      },
      {
        label:
          'Establish communication with onsite assembly area or offsite evacuation location',
        checklist_id: '4cc636ac-d26f-409f-899b-271550df8953',
        user_selection: true,
      },
      {
        label:
          'Consider arrangements for people with disability, preschool students and animals',
        checklist_id: '4cc636ac-d26f-409f-899b-271550df8953',
        user_selection: true,
      },
      {
        label:
          'Arrange transport if evacuating to an offsite location (if required)',
        checklist_id: 'cf6d37ce-5523-4579-83d4-c60e22b6e4ac',
        user_selection: true,
      },
      {
        label: 'Confirm all students, staff and visitors have been evacuated',
        checklist_id: '3b078498-14a2-4443-8596-ef9012cf6613',
        user_selection: false,
      },
      {
        label: 'Brief emergency services as per your EMP',
        checklist_id: 'e12883a0-51b0-4616-bd44-9883b1a6830d',
        user_selection: false,
      },
      {
        label:
          'Check all students, staff and visitors are accounted for at the assembly/evacuation point',
        checklist_id: '5c63be68-1b35-4e4c-980f-c0902a4066d5',
        user_selection: false,
      },
      {
        label:
          'Notify students, staff and visitors about what they need to know. For example, nature of the emergency and areas to avoid',
        checklist_id: '8b7fb34b-68fc-4643-af6b-9edbf56dedd3',
        user_selection: false,
      },
      {
        label:
          'Contact the school community with relevant information using [School Notify] or your school communication procedures',
        checklist_id: '8078f700-5d24-40ac-9317-0bd2ba73385f',
        user_selection: false,
      },
      {
        label:
          'Receive direction from emergency services to either return to the school site or temporarily cease operations',
        checklist_id: '00efe9dc-2398-42a1-adef-858f76cf487f',
        user_selection: false,
      },
      {
        label:
          'Communicate with the school community that school is returning to normal operations, or where they need to collect their children',
        checklist_id: 'e8a917ed-2d28-4603-8c93-9da5cdd16e33',
        user_selection: false,
      },
      {
        label:
          'Notify Assisted School Travel Program, Early Learning, and Community Users of the situation, bus company, DE International if applicable',
        checklist_id: 'e8a917ed-2d28-4603-8c93-9da5cdd16e33',
        user_selection: false,
      },
      {
        label: 'Debrief staff and arrange evaluation',
        checklist_id: '4dc148c9-93d9-4184-8fac-ec5cf1a85359',
        user_selection: false,
      },
    ],
  },
  {
    label: 'Lockdown',
    category_id: '91b96c1b-19fc-4716-a916-3bb48ecc3ff5',
    items: [
      {
        label: 'Call Triple Zero (000)',
        checklist_id: '47d4da7b-d2bb-4223-b685-6abfac80a744',
        user_selection: true,
      },
      {
        label: 'Trigger the lockdown signal',
        checklist_id: 'a43eebf1-a06b-4a66-969d-83aa04b6f2d9',
        user_selection: false,
      },
      {
        label: 'Direct staff to carry out the lockdown actions as per your EMP',
        checklist_id: 'ad5f9dbf-f3ed-47e4-a41b-b3361f7755fd',
        user_selection: false,
      },
      {
        label:
          'Consider arrangements for people with disability, preschool students and animals',
        checklist_id: 'ad5f9dbf-f3ed-47e4-a41b-b3361f7755fd',
        user_selection: false,
      },
      {
        label: 'Notify the Incident Report and Support Hotline 1800 811 523',
        checklist_id: '58bed567-8caf-47a5-8366-181c9ae7f8b0',
        user_selection: false,
      },
      {
        label: 'Contact your DEL',
        checklist_id: 'b59f489b-9a8e-4f92-9763-44da2d072f63',
        user_selection: false,
      },
      {
        label:
          'Check that school staff can be contacted. For example by mobile phone or public address system if you have one',
        checklist_id: 'f9189112-a2ad-4cb2-932b-97d40f497f48',
        user_selection: false,
      },
      {
        label: 'Confirm all students, staff and visitors are accounted for',
        checklist_id: 'c1e98017-283d-4913-9959-203884b382fd',
        user_selection: false,
      },
      {
        label: 'Brief emergency services as per your EMP',
        checklist_id: '3db1e72b-b1f3-4862-b01c-49a33ab06e2e',
        user_selection: false,
      },
      {
        label:
          'Alert staff and returning excursion groups not to return to school',
        checklist_id: 'd46c9e69-6c06-400b-8938-2df5c2d9e900',
        user_selection: false,
      },
      {
        label:
          'Contact the school community with relevant information using [School Notify] or your school communication procedures',
        checklist_id: 'e498f5e3-13a2-4ae6-ba42-e312852dd0bb',
        user_selection: false,
      },
      {
        label:
          'Receive direction from emergency services that school can return to normal operations (if required)',
        checklist_id: 'e6dadcc3-5d8d-4924-885b-f0fa1ee962fb',
        user_selection: false,
      },
      {
        label:
          'Issue the all-clear to students, staff and visitors, and advise them about what they need to know. For example, where to go, areas to avoid',
        checklist_id: '59452986-c107-4c9b-a632-abb1ff8ce141',
        user_selection: false,
      },
      {
        label:
          'Communicate with the school community that school is returning to normal operations, or to collect their children (if needed)',
        checklist_id: '4247c53b-e437-46cc-8a72-e7b1f08f5fed',
        user_selection: false,
      },
      {
        label: 'Debrief staff and arrange evaluation',
        checklist_id: '4247c53b-e437-46cc-8a72-e7b1f08f5fed',
        user_selection: false,
      },
    ],
  },
  {
    label: 'Secure alert (lockout) ',
    category_id: 'dd18a724-dfd5-4243-bfd1-cec4a64c52e5',
    items: [
      {
        label: 'Issue the secure alert (lockout) instruction',
        checklist_id: '2879cfb9-454d-4296-b4e4-c71b982ba57e',
        user_selection: true,
      },
      {
        label:
          'Direct staff to carry out the secure alert (lockout) actions as per your EMP',
        checklist_id: 'f39e50fb-9fe6-4288-9b43-6a82c903e019',
        user_selection: false,
      },
      {
        label:
          'Consider arrangements for people with disability, preschool students',
        checklist_id: 'f39e50fb-9fe6-4288-9b43-6a82c903e019',
        user_selection: false,
      },
      {
        label: 'Confirm all students, staff and visitors are accounted for',
        checklist_id: 'b83dcd80-6f40-400b-abef-e0f068b53a51',
        user_selection: false,
      },
      {
        label: 'Notify the Incident Report and Support Hotline 1800 811 523',
        checklist_id: 'ea4f4eea-73d4-4122-8556-e20ed08369f1',
        user_selection: false,
      },
      {
        label: 'Contact your DEL',
        checklist_id: 'ea4f4eea-73d4-4122-8556-e20ed08369f1',
        user_selection: false,
      },
      {
        label:
          'Check that school staff can be contacted. For example by mobile phone or public address system if you have one',
        checklist_id: '15e824ee-4ddb-43c2-9b0f-30037edd0b52',
        user_selection: false,
      },
      {
        label:
          'Alert staff and returning excursion groups not to return to school',
        checklist_id: '736d171a-1e47-4a69-b00a-edd8d4a2220f',
        user_selection: false,
      },
      {
        label:
          'Direct staff to carry out lockdown actions (if you determine a lockdown is needed)',
        checklist_id: 'c2ff8874-8bb7-4eae-a231-1a9a47853e1b',
        user_selection: false,
      },
      {
        label:
          'Issue the all-clear to students, staff and visitors, and advise them about what they need to know. For example, where to go, areas to avoid',
        checklist_id: '0134eb86-32e4-4e8e-805e-716e2f0680e1',
        user_selection: false,
      },
      {
        label: 'Debrief staff and arrange evaluation',
        checklist_id: '042b1d40-f797-4de4-a824-6cdc781eddf0',
        user_selection: false,
      },
    ],
  },
  {
    label: 'Shelter-in-place',
    category_id: 'b6aabf4a-6efb-4659-9f82-5fe123da2149',
    items: [
      {
        label: 'Trigger the shelter-in-place signal',
        checklist_id: '60867322-81b9-46b1-87de-42724e686dc6',
        user_selection: false,
      },
      {
        label: 'Collect Evacuation/Emergency Kit',
        checklist_id: '3f937fd4-3ab6-461b-8679-5626affaf1e0',
        user_selection: false,
      },
      {
        label:
          'Direct staff to carry out the shelter-in-place actions as per your EMP / BGRP',
        checklist_id: '9c68d6e1-a529-45b2-b9d5-02d30345aaba',
        user_selection: false,
      },
      {
        label:
          'Consider arrangements for people with disability, preschool students and animals',
        checklist_id: '55aae220-e27c-4c65-a390-48d7b59a34b3',
        user_selection: false,
      },
      {
        label:
          'Call Triple Zero (000) - Ensure location within the school is advised',
        checklist_id: 'b488feed-a8d8-4b6c-97ca-0119db445e3e',
        user_selection: false,
      },
      {
        label: 'Confirm all students, staff and visitors are accounted for',
        checklist_id: 'c94212ee-bb3e-4211-823a-7be27da328b8',
        user_selection: false,
      },
      {
        label: 'Notify the Incident Report and Support Hotline 1800 811 523',
        checklist_id: '1f8437b4-c28e-4f03-8218-4b7a6034abc3',
        user_selection: false,
      },
      {
        label: 'Contact your DEL',
        checklist_id: '9bb4b921-ee05-4304-8920-09746537fee3',
        user_selection: false,
      },
      {
        label: 'Contact the school community with relevant information',
        checklist_id: 'fe6f4bab-407a-439d-bf4f-0f1ea17484d1',
        user_selection: false,
      },
      {
        label:
          'Check the Hazards Near Me app and/or ABC radio or TV regularly for updates',
        checklist_id: 'c46abb38-745f-46bf-8441-911b02daa0b6',
        user_selection: false,
      },
      {
        label:
          'Update emergency services of any risks. For example, embers inside the building where you are sheltering',
        checklist_id: '1f8e3ad2-d615-4b30-aef5-3fcec0b7900d',
        user_selection: false,
      },
      {
        label:
          'Receive confirmation from emergency services on next steps',
        checklist_id: '130ccdb7-6e23-4557-9a1c-6af5387a1215',
        user_selection: false,
      },
      {
        label: 'Notify Assisted School Travel Program, Early Learning, and Community Users of the situation, bus company, DE International if applicable',
        checklist_id: '130ccdb7-6e23-4557-9a1c-6af5387a1216',
        user_selection: false,
      },
      {
        label:
          'Instruct parents and carers where they need to collect their children',
        checklist_id: '130ccdb7-6e23-4557-9a1c-6af5387a1217',
        user_selection: false,
      },
      {
        label: 'Debrief staff and arrange evaluation',
        checklist_id: '130ccdb7-6e23-4557-9a1c-6af5387a1218',
        user_selection: false,
      },
    ],
  },
  {
    label: 'Medical emergency',
    category_id: '68c52bda-cba5-4e5b-9eb6-2f4f0a2ec2e0',
    items: [
      {
        label:
          "Administer first aid (following student's Individual Health Care Plan, if applicable)",
        checklist_id: '086173ef-4305-400f-8c2a-803ed441fed0',
        user_selection: false,
      },
      {
        label: 'Call Triple Zero (000) (if immediate or life threatening)',
        checklist_id: '7d9b4ad9-4d93-4665-aa03-06e6a9281d0b',
        user_selection: false,
      },
      {
        label: 'Notify the first aid officer',
        checklist_id: 'da3090b8-4d31-4d7e-9d72-4fa47d4ec320',
        user_selection: false,
      },
      {
        label: 'Contact emergency contacts of affected person or persons',
        checklist_id: '25758c2b-2664-4880-b35d-5c98b9bc0eb6',
        user_selection: false,
      },
      {
        label:
          'Notify Early Childhood for preschool students and notify DE International for international students',
        checklist_id: '25758c2b-2664-4880-b35d-5c98b9bc0eb6',
        user_selection: false,
      },
      {
        label:
          'Record details of the incident, injury, trauma or illness. For example, symptoms, duration, medication administered',
        checklist_id: '88e50738-386c-4180-b20c-e82406de93fc',
        user_selection: false,
      },
      {
        label: 'Provide support for students and staff who saw the emergency',
        checklist_id: '4b7f0619-c0bb-4879-93b9-fc9ba68391a5',
        user_selection: false,
      },
      {
        label:
          'Notify students, staff and visitors about what they need to know. For example, nature of the emergency and areas to avoid',
        checklist_id: '9fe786bb-5893-49b8-b7d2-c19731152512',
        user_selection: false,
      },
      {
        label: 'Notify the Incident Report and Support Hotline 1800 811 523',
        checklist_id: '8c3ffb6b-f62f-4cb5-a927-689f0497c922',
        user_selection: false,
      },
      {
        label: 'Contact your DEL',
        checklist_id: '8c3ffb6b-f62f-4cb5-a927-689f0497c922',
        user_selection: false,
      },
      {
        label:
          'Complete an Incident, injury, trauma or illness form (preschools only)',
        checklist_id: '94e63794-97f9-42bc-b8b0-b52c85f29436',
        user_selection: false,
      },
      {
        label: 'Debrief staff and arrange evaluation',
        checklist_id: 'ade419f5-6a5a-4e13-8191-7cefd5560dba',
        user_selection: false,
      },
    ],
  },
];
