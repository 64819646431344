export default [
    {
        'name': 'Chatswood West Public School',
        'code': '09887',
        'email': 'cc@det.nsw.edu.au'
    },
    {
        'name': 'Wollongong East Public School',
        'code': '0987',
        'email': 'parra@det.nsw.edu.au'
    }
  ];