<template>
  <div class="center-container mt-10">
    <v-container
      class="content"
      fluid
      justify-center
      align-center
    >
      <v-row
        justify="center"
        align="center"
      >
        <v-col cols="auto">
          <v-img
            :width="77"
            :height="77"
            src="/images/no-active-messages.svg"
          />
        </v-col>
      </v-row>
      <div class="notFoundMessage">
        <v-row
          justify="center"
          align="center"
        >
          <v-col cols="auto py-2">
            <span class="active">You have no {{ messageStatus }} messages</span>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          align="center"
        >
          <v-col cols="auto py-0">
            <p class="selectText">
              select Start new message to get started
            </p>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  name: 'MessageNotFound',
  props: {
    messageStatus: {
      type: [String],
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-card {
    h3 {
      color: $ads-primary-blue;
    }
  }
}

.selectText {
    width: 200px;
    text-align: center;
    font-family: Public Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>