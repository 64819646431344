<template>
  <v-container class="py-5">
    <v-row justify="center">
      <v-col
        cols="12"
        sm="10"
      >
        <p class="text-h6">
          To provide feedback or report any issues, please click on the
          following link. It will open a new tab in your browser and direct you
          to a website outside of School Notify where you can complete and
          submit the School Notify support form:
          <a
            target="_blank"
            href="https://nswdoeesmp1.service-now.com/services_gateway?sys_id=807e2dc397993910dfae7846f053afb4&si=Other%20-%20IT&id=sg_item"
          >
            School Notify team
          </a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Help',
  data: () => ({
    
  }),

  components: {
    // AdsButton,
    // AdsTextArea,
  },
  methods: {
    
  },
  computed: {
    
  },
};
</script>

<style scoped>
.btn-class {
  max-width: 800px;
}
</style>
