<template>
  <v-container class="py-5">
    <v-row justify="center">
      <v-col
        cols="12"
        sm="10"
      >
        <h2 class="notify-title-text mb-5">
          Subgroup Name:
        </h2>
        <v-text-field 
          ref="subgroupName"
          outlined
          background-color="white"
          label="Subgroup name"
          v-model="subGroup.name"
          :placeholder="placeholder"
          :rules="[checkAllStaff, checkDuplicate, rules.lengthCheck]"
          persistent-placeholder
          :aria-invalid="$refs.subgroupName && $refs.subgroupName.hasFocused && $refs.subgroupName.hasError ? 'true' : 'false'"
          :append-icon="$refs.subgroupName && $refs.subgroupName.hasFocused && $refs.subgroupName.hasError ? 'mdi-alert-circle' : ''"
          hide-details="auto"
          :aria-describedby="$refs.subgroupName && $refs.subgroupName.hasFocused && $refs.subgroupName.hasError ? 'subgroup-name-error' : false"
        >
          <template v-slot:message>
            <div id="subgroup-name-error">
              <template v-for="(message) in $refs.subgroupName.messagesToDisplay">
                {{ message }}
              </template>
            </div>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="10"
      >
        <span class="notify-title-text">Contacts:</span>
      </v-col>
      <v-col
        cols="12"
        sm="10"
      >
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-card>
            <div
              class="pl-4 py-2 pr-1"
              v-if="!isOtherType"
            >
              <v-text-field
                solo
                label="Search for a contact"
                prepend-icon="mdi-magnify grey--text"
                v-model="searchBarValue"
                hide-details
                flat
                clearable
                role="combobox"
                :aria-expanded="
                  searchBarValue &&
                    searchBarValue.length > 0 &&
                    filteredContacts &&
                    filteredContacts.length
                    ? 'true'
                    : 'false'
                "
                autocomplete="off"
                aria-activedescendant="contacts-available"
              />
            </div>
            <v-divider v-if="!isOtherType" />
            <v-list
              class="py-0"
              v-if="
                !searchBarValue ||
                  (searchBarValue && searchBarValue.length === 0)
              "
            >
              <template v-if="!isOtherType">
                <v-list-item
                  v-for="member in sortBy(fullMembersList, (m) =>
                    fullName(m).toLocaleLowerCase()
                  )"
                  :key="member.staff_id"
                  class="py-1"
                  @click="removeMember(member, true)"
                  aria-selected="true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ fullName(member) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon color="primary">
                      mdi-minus-circle-outline
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item
                  v-for="member in sortBy(fullMembersList, (m) =>
                    fullName(m).toLocaleLowerCase()
                  )"
                  :key="member.staff_id"
                  class="py-1"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ fullName(member) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon class="ma-0">
                    <EditContactDialog :contact="member" />
                  </v-list-item-icon>
                </v-list-item>
              </template>
            </v-list>
            <v-list
              v-else-if="
                searchBarValue &&
                  searchBarValue.length > 0 &&
                  filteredContacts &&
                  filteredContacts.length
              "
              class="py-0"
              id="contacts-available"
            >
              <v-list-item
                v-for="contact in filteredContacts"
                :key="`search-${contact.staff_id}`"
                class="py-1"
                @click="addMember(contact)"
                :aria-selected="
                  alreadyAdded(contact.staff_id) ? 'true' : 'false'
                "
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ fullName(contact) }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    v-if="alreadyAdded(contact.staff_id)"
                    color="primary"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    v-else
                    color="primary"
                  >
                    mdi-check-circle-outline
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
            <v-list v-else>
              <v-list-item>
                <strong>Contact not found</strong>
              </v-list-item>
            </v-list>
          </v-card>
        </v-form>
      </v-col>
      <v-col
        cols="12"
        sm="10"
      >
        <v-row justify="space-between">
          <v-col
            cols="12"
            sm="auto"
            lg="3"
            :order="$vuetify.breakpoint.smAndUp ? 1 : 0"
          >
            <AdsButton
              primary
              block
              buttonText="Save and close"
              @click="validate"
            />
          </v-col>
          <v-col
            cols="12"
            sm="auto"
            lg="3"
          >
            <AdsButton
              color="error"
              buttonText="Delete this subgroup"
              @click="openDeleteDialog = true"
              block
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Dialog
      :openDialog="openDeleteDialog"
      @close="openDeleteDialog = false"
      persistent
      display-close-btn
      content-class="edit-dialog"
    >
      <template #title>
        <v-card-title class="d-flex justify-space-between">
          <h2 class="title">
            Delete {{ subGroup.name }}
          </h2>
        </v-card-title>
      </template>
      <template #actions>
        <div class="px-6 pb-6 d-flex flex-column">
          <AdsButton
            color="error"
            @click="handleDeleteSubgroup(subGroup)"
          >
            Confirm delete
          </AdsButton>
          <AdsButton
            secondary
            @click="openDeleteDialog = false"
            class="mt-2"
          >
            Cancel
          </AdsButton>
        </div>
      </template>
      <template #text>
        <div class="px-6">
          <p>Are you sure you want to delete this subgroup?</p>
          <p v-if="subGroup.type === 'OTHER'">
            This will permanently delete the {{ subGroupMembers }} in this
            subgroup
          </p>
        </div>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import EditContactDialog from '@/views/Settings/Contacts/EditContactDialog';
import { AdsButton, Dialog } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';
import { sortBy } from 'lodash';
export default {
  name: 'EditSubGroupMembers',
  components: {
    AdsButton,
    EditContactDialog,
    Dialog
  },
  props: {
    subGroup: {
      type: Object,
      default: () => {
        return {};
      },
    },
    memberList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters({
      schoolStaffSubGroupDetails:
        'moduleManageContacts/schoolStaffSubGroupDetails',
      otherContactsSubGroupDetails:
        'moduleManageContacts/otherContactsSubGroupDetails',
      staffContactsList: 'moduleManageContacts/getSchoolStaffContactList',
      otherContactsList: 'moduleManageContacts/getOtherContactList',
      school: 'moduleDashboard/getSelectedSchool',
      schoolStaffSubGroupList: 'moduleManageContacts/getSchoolStaffSubGroup',
      otherContactsSubGroupList: 'moduleManageContacts/getOtherContactsSubGroup',
    }),
    filteredContacts() {
      let contacts = this.contacts;
      if (this.searchBarValue.length > 0) {
        return contacts.filter((contact) =>
          this.fullName(contact)
            .toLocaleLowerCase()
            .includes(this.searchBarValue.toLocaleLowerCase())
        );
      }
      return contacts;
    },
    fullMembersList() {
      return [...this.members, ...this.addedMembers].filter((user) => {
        return !this.removedMembers.some(
          (member) => member.staff_id === user.staff_id
        );
      });
    },
    isOtherType() {
      if (this.subGroup.type === 'OTHER') {
        return true;
      }
      return false;
    },
    subGroupMembers() {
      if (this.subGroup) {
        let groupMembers = {};
        if (this.subGroup.type === 'STAFF') {
          groupMembers = this.schoolStaffSubGroupDetails
        } else {
          groupMembers = this.otherContactsSubGroupDetails
        }
        
        return `${groupMembers.length} contact${
          groupMembers.length > 1 ? 's' : ''
        }`;
      }
      return '0 contacts';
    },
    placeholder() {
      if (this.isOtherType) {
        let data = this.otherContactsSubGroupList.find(el => el.id === this.subGroup.id)
        return data.name
      } else {
        let data = this.schoolStaffSubGroupList.find(el => el.id === this.subGroup.id)
        return data.name
      }
    },
    members() {
      if (this.subGroup.type === 'STAFF') {
        return this.schoolStaffSubGroupDetails;
      }
      if (this.subGroup.type === 'OTHER') {
        return this.otherContactsSubGroupDetails;
      }
      return [];
    },
    contacts() {
      if (this.subGroup.type === 'STAFF') {
        return this.staffContactsList;
      }
      if (this.subGroup.type === 'OTHER') {
        return this.otherContactsList;
      }
      return [];
    }
  },
  data: () => ({
    valid: true,
    entries: [],
    model: null,
    search: null,
    isLoading: false,
    searchBarValue: '',
    addedMembers: [],
    removedMembers: [],
    formData: {
      firstName: '',
    },
    rules: {
      lengthCheck: (v) =>
        (v.length <= 30) || 'Name must be less than 30 characters',
    },
    openDeleteDialog: false
  }),
  methods: {
    sortBy,
    removeMember(member, timeout) {
      if (timeout) {
        setTimeout(() => {
          if (
            this.addedMembers.some((user) => user.staff_id === member.staff_id)
          ) {
            this.addedMembers = this.addedMembers.filter(
              (item) => item.staff_id !== member.staff_id
            );
          } else {
            this.removedMembers.push(member);
          }
        }, 350);
      } else {
        if (
          this.addedMembers.some((user) => user.staff_id === member.staff_id)
        ) {
          this.addedMembers = this.addedMembers.filter(
            (item) => item.staff_id !== member.staff_id
          );
        } else {
          this.removedMembers.push(member);
        }
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        if (this.isOtherType) {
          let subgroup = this.otherContactsSubGroupList.find((el) => el.id === this.subGroup.id);
          if (subgroup.name !== this.subGroup.name) {
            this.updateOtherSubGroup();
          } else {
            this.$emit('close-dialog', true);
          }
        } else {
          this.updateStaffSubGroup();
        }
      }
    },
    async handleDeleteSubgroup(subGroup) {
      this.$store.commit('SET_IS_LOADING', 'deleteSubGroup', {
        root: true,
      });
      await this.$http
        .delete(
          process.env.VUE_APP_API_BASE_URL +
            `/contacts/categories/${subGroup.id}`
        )
        .then((response) => {
          if (response.status === 200) {
            if (subGroup.type === 'STAFF') {
              this.$store.dispatch(
                'moduleManageContacts/fetchSchoolStaffContactSubGroups'
              );
              this.$store.dispatch(
                'moduleManageContacts/fetchStaffSubGroupDetailsBySubGroupId',
                subGroup.id
              );
            }
            if (subGroup.type === 'OTHER') {
              this.$store.dispatch(
                'moduleManageContacts/fetchOtherContactSubGroups'
              );
              this.$store.dispatch(
                'moduleManageContacts/fetchOtherContactBySubGroupId',
                subGroup.id
              );
            }
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'deleteSubGroup', {
            root: true,
          });
          this.$emit('close-dialog', true);
        });
    },
    async updateOtherSubGroup() {
      const payload = {
        name: this.subGroup.name,
        type: this.subGroup.type,
      };
      this.$store.commit('SET_IS_LOADING', 'updateSubGroup', {
        root: true,
      });
      await this.$http
        .put(
          process.env.VUE_APP_API_BASE_URL +
            '/contacts/categories/' +
            this.subGroup.id,
          payload
        )
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch(
              'moduleManageContacts/fetchOtherContactSubGroups'
            );
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$emit('close-dialog', true);
          this.$store.commit('REMOVE_IS_LOADING', 'updateSubGroup', {
            root: true,
          });
        });
    },
    async updateStaffSubGroup() {
      const payload = this.mapMembersData(this.fullMembersList);
      const categoryId = this.subGroup.id;
      const type = this.subGroup.type.toLocaleLowerCase();
      this.$store.commit('SET_IS_LOADING', 'updateSubGroup', {
        root: true,
      });
      await this.$http
        .put(
          process.env.VUE_APP_API_BASE_URL + `/contacts/${type}/bulk`,
          payload,
          {
            params: {
              categoryId,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            let subgroup = this.schoolStaffSubGroupList.find(el => el.id === this.subGroup.id)
            if (subgroup.name !== this.subGroup.name) {
              this.$http
                .put(
                  process.env.VUE_APP_API_BASE_URL + '/contacts/categories/' + categoryId,
                  {
                    name: this.subGroup.name,
                    type: type
                  }
                )
                .then((response) => {
                  if (response.status === 200) {
                    this.$store.dispatch(
                      'moduleManageContacts/fetchSchoolStaffContactSubGroups'
                    );
                    this.$store.dispatch(
                      'moduleManageContacts/fetchStaffSubGroupDetailsBySubGroupId',
                      categoryId
                    );
                  }
                })
                .catch(() => {
                  this.$store.commit('SET_HAS_ERROR', true);
                })
                .finally(() => {
                  this.$emit('close-dialog', true);
                  this.addedMembers = [];
                  this.removedMembers = [];
                  this.searchBarValue = '';
                  this.$store.commit('REMOVE_IS_LOADING', 'updateSubGroup', {
                    root: true,
                  });
                });
            }
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$emit('close-dialog', true);
          this.addedMembers = [];
          this.removedMembers = [];
          this.searchBarValue = '';
          this.$store.commit('REMOVE_IS_LOADING', 'updateSubGroup', {
            root: true,
          });
        });
    },
    mapMembersData(members) {
      const options = members.map((el) => {
        const option = {
          first_name: el.first_name,
          last_name: el.last_name,
          staff_id: el.staff_id,
          contact_id: el.contact_id,
        };
        return option;
      });
      return options;
    },
    addMember(member) {
      if (
        this.members.find(
          (user) =>
            user.staff_id === member.staff_id &&
            !this.removedMembers.find(
              (user) => user.staff_id === member.staff_id
            )
        )
      ) {
        this.removeMember(member, false);
      } else if (
        this.members.find(
          (user) =>
            user.staff_id === member.staff_id &&
            this.removedMembers.find(
              (user) => user.staff_id === member.staff_id
            )
        )
      ) {
        this.removedMembers = this.removedMembers.filter(
          (user) => user.staff_id !== member.staff_id
        );
      } else if (
        this.addedMembers.find((o) => o.staff_id === member.staff_id)
      ) {
        this.removeMember(member, false);
      } else {
        this.addedMembers.push(member);
      }
    },
    fullName(contact) {
      return contact?.first_name.trim() + ' ' + contact?.last_name.trim();
    },
    alreadyAdded(staff_id) {
      return this.fullMembersList.some(
        (member) => member.staff_id === staff_id
      );
    },
    checkDuplicate(val) {
      let res = [];
      if (this.subGroup.type === 'STAFF') {
        res = this.schoolStaffSubGroupList.filter((el) => el.name.toLowerCase() === val.toLowerCase() && el.id !== this.subGroup.id);
      }
      if (this.subGroup.type === 'OTHER') {
        res = this.otherContactsSubGroupList.filter((el) => el.name.toLowerCase() === val.toLowerCase() && el.id !== this.subGroup.id);
      }

      if (res && res.length > 0) {
        return `Name "${val}" already exists`;
      } else {
        return true;
      }
    },
    checkAllStaff(val) {
      if (val === 'All school staff') {
        return 'Please use another subgroup name'
      } else {
        return true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-style {
  height: 44px;
  align-items: center;
}
</style>
