<template>
  <FullscreenDialog
    title="New Message"
    v-model="showDialog"
    transition="dialog-bottom-transition"
    content-class="new-message-dialog"
  >
    <template v-slot:activator>
      <AdsButton
        button-text="Start new message"
        icon="add_circle_outline"
        class="start-message-button"
        @click="showDialog = true"
      />
    </template>
    <template v-slot:content>
      <NewMessage />
    </template>
  </FullscreenDialog>
</template>
<script>
import NewMessage from '@/views/Message/NewMessage/NewMessage';
import { AdsButton, FullscreenDialog } from '@nswdoe/doe-ui-core';

export default {
  name: 'FullScreenNewMessageDialog',
  components: {
    FullscreenDialog,
    NewMessage,
    AdsButton,
  },
  props: {},
  data() {
    return {
      showDialog: false,
    };
  },
  mounted() {
    this.$eventHub.$on('close-fsdialog', () => {
      this.showDialog = false;
    });
  },
  watch: {
    showDialog(newValue) {
      if (newValue) {
        this.$store.dispatch('moduleTemplates/updateSelectedTemplate', null);
        this.$store.dispatch('moduleDashboard/updateSelectedEvent', null);
        this.$store.dispatch('moduleTemplates/fetchTemplates');
        this.$store.dispatch('moduleDashboard/fetchCommunicationInfo');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 968px) {
  .start_with_blank_btn,
  .use-template-btn {
    width: 100% !important;
  }
}
</style>
