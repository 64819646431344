<template>
  <div>
    <div
      class="container-wrapper"
      style="background: #f4f4f7"
    >
      <v-container class="py-5">
        <v-row justify="center">
          <v-col
            cols="12"
            sm="10"
          >
            <span class="notify_sub_header">Template category:</span>
          </v-col>
          <v-col
            cols="12"
            sm="10"
          >
            <v-btn
              outlined
              @click="templateCategoryDialog = !templateCategoryDialog"
              class="template-category pl-3 pr-4"
              block
              :aria-label="
                selectedItem ? selectedItem.name : 'Select template category'
              "
            >
              {{
                selectedItem
                  ? `${selectedItem.name} (${selectedItem.total})`
                  : "Select template category"
              }}
              <v-icon
                right
                dark
                size="24"
              >
                mdi-menu-down
              </v-icon>
            </v-btn>
            <v-dialog
              v-model="templateCategoryDialog"
              width="500"
              v-if="getAllTemplates"
            >
              <v-list>
                <v-list-item-group
                  v-model="selectedItem"
                  mandatory
                  @change="templateCategoryChange"
                >
                  <template v-for="templateCategory in getAllTemplates">
                    <div :key="templateCategory.id">
                      <v-subheader
                        class="font-weight-bold"
                        style="color: #002664 !important; font-size: 16px"
                      >
                        {{ templateCategory.name }}
                      </v-subheader>
                      <v-list-item
                        v-for="template in templateCategory.items"
                        :key="template.catId"
                        :value="template"
                      >
                        <template v-slot:default>
                          <v-list-item-content
                            style="color: #002664 !important"
                          >
                            {{ template.name }} ({{ template.total }})
                          </v-list-item-content>
                          <v-list-item-action
                            v-if="!template.catId.startsWith('C00')"
                            class="ma-0"
                          >
                            <v-btn
                              icon
                              @click.stop="editTemplateCategory(template)"
                              style="color: #002664 !important"
                              :aria-label="`Edit ${template.name} category`"
                            >
                              <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                      <v-divider />
                    </div>
                  </template>
                </v-list-item-group>
                <v-form
                  ref="categoryNameForm"
                  v-model="valid"
                  lazy-validation
                  class="mt-2"
                  onSubmit="return false"
                >
                  <v-list-item>
                    <template v-slot:default>
                      <v-list-item-content class="pb-0">
                        <v-text-field
                          ref="categoryName"
                          outlined
                          background-color="white"
                          label="Create new category"
                          v-model="categoryName"
                          required
                          placeholder="Required"
                          class="plain-textbox"
                          :rules="[
                            checkDuplicate,
                            rules.required,
                            rules.lengthCheck,
                          ]"
                          persistent-placeholder
                          :aria-invalid="
                            (createNewCategoryInvalid &&
                              categoryName.length === 0) ||
                              ($refs.categoryNameForm &&
                              $refs.categoryNameForm.inputs[0].hasFocused &&
                              $refs.categoryNameForm.inputs[0].hasError)
                              ? 'true'
                              : 'false'
                          "
                          :append-icon="
                            (createNewCategoryInvalid &&
                              categoryName.length === 0) ||
                              ($refs.categoryNameForm &&
                              $refs.categoryNameForm.inputs[0].hasFocused &&
                              $refs.categoryNameForm.inputs[0].hasError)
                              ? 'mdi-alert-circle'
                              : ''
                          "
                          :aria-describedby="
                            (createNewCategoryInvalid &&
                              categoryName.length === 0) ||
                              ($refs.categoryNameForm &&
                              $refs.categoryNameForm.inputs[0].hasFocused &&
                              $refs.categoryNameForm.inputs[0].hasError)
                              ? 'category-name-error'
                              : false
                          "
                        >
                          <template v-slot:message="{ message }">
                            <div 
                              id="category-name-error" 
                              v-html="message" 
                            />
                          </template>
                        </v-text-field>
                      </v-list-item-content>
                      <v-list-item-action class="align-self-start mt-3">
                        <v-btn
                          icon
                          @click="validateAndCreateNewCategory"
                          aria-label="Create new category"
                        >
                          <v-icon color="primary">
                            mdi-plus-circle
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-form>
              </v-list>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            sm="10"
            class="d-flex align-center"
          >
            <span role="status">
              <span class="notify_subheader_subname">
                {{ selectedItem ? selectedItem.total : 0 }}
              </span>
              <span class="select ml-1">
                template{{
                  selectedItem && selectedItem.total === 1 ? "" : "s"
                }} in this category
              </span>
            </span>
            <v-spacer />
            <FullScreenTemplateDialog
              buttonText="New template"
              tempTitle="New template"
              tempId="new"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container :class="$vuetify.breakpoint.mobile ? 'pa-0' : 'pa-5'">
      <v-row
        justify="center"
        :no-gutters="$vuetify.breakpoint.mobile"
        v-if="templatesMetaData"
      >
        <v-col
          cols="12"
          sm="10"
        >
          <v-card :elevation="$vuetify.breakpoint.mobile ? '0' : '1'">
            <v-list :class="$vuetify.breakpoint.mobile ? 'my-0' : 'py-0'">
              <template v-for="(item, index) in templatesMetaData">
                <v-list-item
                  :key="`${item.id}-item`"
                  three-line
                  class="pr-0"
                >
                  <template v-slot:default>
                    <v-list-item-content class="listContent">
                      <v-list-item-title class="template-header-text pb-4">
                        <h2 style="font-size: 18px">
                          {{ item.title }}
                        </h2>
                      </v-list-item-title>
                      <v-list-item-subtitle class="template-description">
                        {{ item.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="ma-0">
                      <FullScreenTemplateDialog
                        :tempId="item.id"
                        :tempTitle="item.title"
                        tertiary
                      />
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider
                  v-if="index !== templatesMetaData.length - 1"
                  :key="`${item.id}-divider`"
                />
              </template>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <Dialog
        :openDialog="openDialog"
        @close="openDialog = false"
        persistent
        display-close-btn
        content-class="edit-dialog"
      >
        <template #title>
          <div class="v-card__title d-flex justify-space-between">
            <h2 class="title">
              Edit template category
            </h2>
          </div>
        </template>
        <template #actions>
          <div class="px-6 d-flex flex-column">
            <AdsButton
              primary
              @click="handleSaveAndClose(category)"
            >
              Save and close
            </AdsButton>
            <v-btn
              text
              color="error"
              class="my-2"
              @click="deleteCategory"
            >
              <v-icon left>
                mdi-delete-outline
              </v-icon>
              Delete this category
            </v-btn>
          </div>
        </template>
        <template #text>
          <v-form
            ref="categoryForm"
            v-model="valid"
            lazy-validation
            onSubmit="return false"
          >
            <div class="px-6">
              <v-text-field
                ref="categoryName"
                outlined
                background-color="white"
                label="Category name"
                v-model="category.name"
                id="category.id"
                required
                placeholder="Required"
                :rules="[checkDuplicateEdit, rules.required, rules.lengthCheck]"
                persistent-placeholder
                :aria-invalid="
                  $refs.categoryName &&
                    $refs.categoryName.hasFocused &&
                    $refs.categoryName.hasError
                    ? 'true'
                    : 'false'
                "
                :append-icon="
                  $refs.categoryName &&
                    $refs.categoryName.hasFocused &&
                    $refs.categoryName.hasError
                    ? 'mdi-alert-circle'
                    : ''
                "
                :aria-describedby="
                  $refs.categoryName &&
                    $refs.categoryName.hasFocused &&
                    $refs.categoryName.hasError
                    ? 'category-name-error'
                    : false
                "
              >
                <template v-slot:message>
                  <div id="category-name-error">
                    <template
                      v-for="message in $refs.categoryName.messagesToDisplay"
                    >
                      {{ message }}
                    </template>
                  </div>
                </template>
              </v-text-field>
            </div>
          </v-form>
        </template>
      </Dialog>
      <Dialog
        :openDialog="openDeleteDialog"
        :title="`Delete ${category.name}`"
        @close="openDeleteDialog = false"
        persistent
        display-close-btn
        content-class="edit-dialog"
      >
        <template #title>
          <v-card-title class="d-flex justify-space-between">
            <h2 class="title">
              Delete {{ category.name }}
            </h2>
          </v-card-title>
        </template>
        <template #actions>
          <div class="px-6 pb-6 d-flex flex-column">
            <AdsButton
              color="error"
              @click="handleDeleteCategory(category.id)"
            >
              Confirm Delete
            </AdsButton>
            <AdsButton
              secondary
              @click="openDeleteDialog = false"
              class="mt-2"
            >
              Cancel
            </AdsButton>
          </div>
        </template>
        <template #text>
          <div class="px-6">
            <p>Are you sure you want to delete this template category?</p>
            <p>
              This will permanently delete the {{ category.total }} template(s)
              in this category.
            </p>
          </div>
        </template>
      </Dialog>
    </v-container>
  </div>
</template>

<script>
import FullScreenTemplateDialog from '@/views/Settings/Templates/FullScreenTemplateDialog';
import { AdsButton, Dialog } from '@nswdoe/doe-ui-core';
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'ManageTemplates',
  components: {
    AdsButton,
    Dialog,
    FullScreenTemplateDialog,
  },
  data: () => ({
    selectedItem: null,
    openDialog: false,
    categoryName: '',
    valid: true,
    openDeleteDialog: false,
    category: {
      id: '',
      name: '',
      total: '',
    },
    rules: {
      required: (v) => !!v || 'Name is required',
      lengthCheck: (v) =>
        (v && v.length <= 30) || 'Name must be less than 30 characters',
    },
    categoryNameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.length <= 30) || 'Name must be less than 30 characters',
    ],
    templateCategoryDialog: false,
    templateCategory: null,
    createNewCategoryInvalid: false,
  }),
  computed: {
    ...mapState('moduleTemplates', {
      temp: (state) => state.templates,
    }),
    ...mapGetters({
      templates: 'moduleTemplates/getTemplates',
      selectedTemplate: 'moduleTemplates/getSelectedTemplates',
      templatesMetaData: 'moduleTemplates/getTemplatesMetaData',
      school: 'moduleDashboard/getSelectedSchool',
    }),
    getAllTemplates() {
      if (this.templates) {
        this.templates.map((templateCategory) => {
          if (templateCategory.id == 'Emergencies') {
            templateCategory.items = this.sortByPriority(templateCategory);
          } else {
            templateCategory.items = this.sortByName(templateCategory);
          }
          return templateCategory;
        });
        this.setSelectedItem(this.templates);
        return this.templates;
      } else {
        return [];
      }
    },
  },
  methods: {
    /* eslint no-console: "off" */
    checkDuplicate(val) {
      if (!val) {
        return true;
      }
      let name = val.trim();
      const res = this.templates.filter(function (element) {
        return element.items.some(function (subElement) {
          return (
            subElement.name.toLowerCase() === name.toLowerCase()
          )
        });
      });
      if (res.length > 0) {
        return `Name "${name}" already exists`;
      } else {
        return true;
      }
    },
    checkDuplicateEdit(val) {
      let that = this;
      if (!val) {
        return true;
      }
      let name = val.trim();
      const res = this.templates.filter(function (element) {
        return element.items.some(function (subElement) {
          return (
            subElement.name.toLowerCase() === name.toLowerCase() && subElement.catId !== that.category.id
          )
        });
      });
      if (res.length > 0) {
        return `Name "${name}" already exists`;
      } else {
        return true;
      }
    },
    async validateAndCreateNewCategory() {
      if (this.$refs.categoryNameForm.validate()) {
        this.createNewCategoryInvalid = false;
        await this.createNewCategory();
        this.$refs.categoryNameForm.reset();
      } else {
        this.createNewCategoryInvalid = true;
      }
    },
    async createNewCategory() {
      const payload = {
        name: this.categoryName,
        type: 'Other Templates',
      };
      this.$store.commit('SET_IS_LOADING', 'createCategory', {
        root: true,
      });
      await this.$http
        .post(
          process.env.VUE_APP_API_BASE_URL + '/templates/categories',
          payload
        )
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch('moduleTemplates/fetchTemplates');
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'createCategory', {
            root: true,
          });
        });
    },
    async updateCategory(category) {
      const payload = {
        name: category.name,
        type: 'Other Templates',
      };
      this.$store.commit('SET_IS_LOADING', 'updateCategory', {
        root: true,
      });
      await this.$http
        .put(
          process.env.VUE_APP_API_BASE_URL +
            '/templates/categories/' +
            category.id,
          payload
        )
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch('moduleTemplates/fetchTemplates');
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'updateCategory', {
            root: true,
          });
        });
    },
    editTemplateCategory(item) {
      this.category.id = item.catId;
      this.category.total = item.total;
      this.category.name = item.name;
      this.openDialog = true;
      this.$refs.categoryNameForm.reset();
    },
    validate() {
      if (this.$refs.categoryNameForm.validate()) {
        this.$router.push({
          path: '/contacts',
        });
      }
    },
    async handleSaveAndClose(category) {
      if (this.$refs.categoryForm.validate()) {
        await this.updateCategory(category);
        this.openDialog = false;
      }
    },
    deleteCategory() {
      this.openDeleteDialog = true;
    },
    async handleDeleteCategory(CategoryId) {
      this.$store.commit('SET_IS_LOADING', 'deleteCategory', {
        root: true,
      });
      await this.$http
        .delete(
          process.env.VUE_APP_API_BASE_URL +
            `/templates/categories/${CategoryId}`
        )
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch('moduleTemplates/fetchTemplates');
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'deleteCategory', {
            root: true,
          });
          this.openDeleteDialog = false;
          this.openDialog = false;
        });
    },
    setSelectedItem(entries) {
      let emergencies = entries.find(
        (category) => category.name == 'Emergencies'
      );
      if (emergencies) {
        let template = emergencies.items.find(
          (template) => template.catId === 'C001'
        );
        this.selectedItem = template;
      } else {
        return null;
      }
    },
    sortByName(item) {
      const res = item.items?.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      return res;
    },
    sortByPriority(item) {
      const res = item.items?.sort(function (a, b) {
        if (a.catId < b.catId) {
          return -1;
        }
        if (a.catId > b.catId) {
          return 1;
        }
        return 0;
      });
      return res;
    },
    templateCategoryChange() {
      this.templateCategoryDialog = false;
    },
  },
  watch: {
    selectedItem: {
      handler(newSelection) {
        this.$store.dispatch(
          'moduleTemplates/fetchTemplatesByCategoryId',
          newSelection?.catId
        );
      },
    },
  },
  created() {
    //this.$store.dispatch('moduleTemplates/fetchTemplates');
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 968px) {
  .container-main {
    padding: 10px !important;
  }
}

.listContent {
  align-self: flex-start;
}

::v-deep {
  .container-main fieldset {
    background-color: #fff;
  }
}

.template-category {
  background-color: white;
  border: 1px solid #9d9d9d;
  font-weight: 500;
  ::v-deep .v-btn__content {
    justify-content: space-between;
  }
}

.v-card {
  position: relative;

  .close-btn__wrapper {
    position: absolute;
    top: 9px;
    right: 9px;
  }
  .v-card__title {
    margin-bottom: 18px;
    color: #002664 !important;

    .v-avatar {
      background-color: #cbedfd !important;
      margin-right: 6px;
    }
  }

  .content {
    padding: 0 24px;
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 24px;
    color: #002664 !important;
    background-color: unset !important;
  }

  .v-card__actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px 24px 24px !important;

    .v-btn {
      margin-left: 12px !important;
      padding: 0 16px;
      color: #fff;
    }
  }

  .v-btn:focus {
    outline: 3px auto -webkit-focus-ring-color;
  }
}
</style>
