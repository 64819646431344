<template>
  <div>
    <v-container class="py-5">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
        >
          <h2
            class="notify-title-text"
            v-show="!isEdit"
          >
            Add a new contact
          </h2>
          <span
            class="notify-title-text"
            v-show="isEdit"
          > Edit contact </span>
        </v-col>
        <v-col
          cols="12"
          sm="10"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-card>
              <v-card-subtitle>
                <v-text-field
                  ref="firstName"
                  outlined
                  background-color="white"
                  label="First name"
                  v-model="formData.first_name"
                  required
                  placeholder="Required"
                  :rules="firstNameRules"
                  persistent-placeholder
                  :aria-invalid="
                    $refs.firstName &&
                      $refs.firstName.hasFocused &&
                      $refs.firstName.hasError
                      ? 'true'
                      : 'false'
                  "
                  :append-icon="
                    $refs.firstName &&
                      $refs.firstName.hasFocused &&
                      $refs.firstName.hasError
                      ? 'mdi-alert-circle'
                      : ''
                  "
                  :aria-describedby="
                    $refs.firstName &&
                      $refs.firstName.hasFocused &&
                      $refs.firstName.hasError
                      ? 'first-name-error'
                      : false
                  "
                >
                  <template v-slot:message>
                    <div id="first-name-error">
                      <template
                        v-for="message in $refs.firstName.messagesToDisplay"
                      >
                        {{ message }}
                      </template>
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  ref="lastName"
                  outlined
                  background-color="white"
                  label="Last name"
                  v-model="formData.last_name"
                  required
                  placeholder="Required"
                  :rules="lastNameRules"
                  persistent-placeholder
                  :aria-invalid="
                    $refs.lastName &&
                      $refs.lastName.hasFocused &&
                      $refs.lastName.hasError
                      ? 'true'
                      : 'false'
                  "
                  :append-icon="
                    $refs.lastName &&
                      $refs.lastName.hasFocused &&
                      $refs.lastName.hasError
                      ? 'mdi-alert-circle'
                      : ''
                  "
                  :aria-describedby="
                    $refs.lastName &&
                      $refs.lastName.hasFocused &&
                      $refs.lastName.hasError
                      ? 'last-name-error'
                      : false
                  "
                >
                  <template v-slot:message>
                    <div id="last-name-error">
                      <template
                        v-for="message in $refs.lastName.messagesToDisplay"
                      >
                        {{ message }}
                      </template>
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  ref="phone"
                  outlined
                  background-color="white"
                  label="Mobile number"
                  v-model="formData.phone"
                  required
                  placeholder="Required"
                  :rules="[phoneRules]"
                  persistent-placeholder
                  :aria-invalid="
                    $refs.phone &&
                      $refs.phone.hasFocused &&
                      $refs.phone.hasError
                      ? 'true'
                      : 'false'
                  "
                  :append-icon="
                    $refs.phone &&
                      $refs.phone.hasFocused &&
                      $refs.phone.hasError
                      ? 'mdi-alert-circle'
                      : ''
                  "
                  :aria-describedby="
                    $refs.phone &&
                      $refs.phone.hasFocused &&
                      $refs.phone.hasError
                      ? 'phone-error'
                      : false
                  "
                >
                  <template v-slot:message>
                    <div id="phone-error">
                      <template
                        v-for="message in $refs.phone.messagesToDisplay"
                      >
                        {{ message }}
                      </template>
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  ref="email"
                  outlined
                  background-color="white"
                  label="Email address"
                  v-model="formData.email"
                  required
                  placeholder="Required"
                  :rules="[emailRules]"
                  persistent-placeholder
                  :aria-invalid="
                    $refs.email &&
                      $refs.email.hasFocused &&
                      $refs.email.hasError
                      ? 'true'
                      : 'false'
                  "
                  :append-icon="
                    $refs.email &&
                      $refs.email.hasFocused &&
                      $refs.email.hasError
                      ? 'mdi-alert-circle'
                      : ''
                  "
                  :aria-describedby="
                    $refs.email &&
                      $refs.email.hasFocused &&
                      $refs.email.hasError
                      ? 'email-error'
                      : false
                  "
                >
                  <template v-slot:message>
                    <div id="email-error">
                      <template
                        v-for="message in $refs.email.messagesToDisplay"
                      >
                        {{ message }}
                      </template>
                    </div>
                  </template>
                </v-text-field>
                <v-text-field
                  ref="role"
                  outlined
                  background-color="white"
                  label="Role"
                  v-model="formData.role"
                  :rules="[roleRules]"
                  persistent-placeholder
                  :aria-invalid="
                    $refs.role && $refs.role.hasFocused && $refs.role.hasError
                      ? 'true'
                      : 'false'
                  "
                  :append-icon="
                    $refs.role && $refs.role.hasFocused && $refs.role.hasError
                      ? 'mdi-alert-circle'
                      : ''
                  "
                  :aria-describedby="
                    $refs.role && $refs.role.hasFocused && $refs.role.hasError
                      ? 'role-error'
                      : false
                  "
                >
                  <template v-slot:message>
                    <div id="role-error">
                      <template v-for="message in $refs.role.messagesToDisplay">
                        {{ message }}
                      </template>
                    </div>
                  </template>
                </v-text-field>
              </v-card-subtitle>
            </v-card>
            <v-row>
              <v-col
                cols="12"
                class="mt-8"
              >
                <h2 class="notify-title-text">
                  Choose a subgroup
                </h2>
              </v-col>
              <v-col cols="12">
                <v-card>
                  <v-card-subtitle class="message-dashboard">
                    choose a subgroup for this contact
                  </v-card-subtitle>
                  <v-card-text>
                    <v-btn
                      outlined
                      @click="subgroupDialog = !subgroupDialog"
                      class="template-category pl-3 pr-4"
                      block
                      :aria-label="
                        formData.subgroup
                          ? formData.subgroup.name
                          : 'Select subgroup'
                      "
                    >
                      {{
                        formData.subgroup
                          ? formData.subgroup.name
                          : "Select subgroup"
                      }}
                      <v-icon
                        right
                        dark
                        size="24"
                      >
                        mdi-menu-down
                      </v-icon>
                    </v-btn>
                    <v-dialog
                      v-model="subgroupDialog"
                      width="500"
                      v-if="getSelectionItems"
                    >
                      <v-list>
                        <v-list-item-group
                          v-model="formData.subgroup"
                          mandatory
                          @change="subgroupChange"
                        >
                          <template v-for="subgroup in getSelectionItems">
                            <v-list-item
                              :key="subgroup.id"
                              :value="subgroup"
                            >
                              {{ subgroup.name }}
                            </v-list-item>
                          </template>
                          <v-divider />
                        </v-list-item-group>
                        <v-form
                          ref="categoryForm"
                          v-model="categoryFormValid"
                          lazy-validation
                          class="mt-2"
                          onSubmit="return false"
                        >
                          <v-list-item>
                            <template v-slot:default>
                              <v-list-item-content class="pb-0">
                                <v-text-field
                                  ref="categoryName"
                                  outlined
                                  background-color="white"
                                  label="Create a new subgroup"
                                  v-model="newSubGroup"
                                  required
                                  placeholder="Required"
                                  class="plain-textbox"
                                  :rules="[nameRules]"
                                  persistent-placeholder
                                  :aria-invalid="
                                    (createNewCategoryInvalid &&
                                      newSubGroup.length === 0) ||
                                      ($refs.categoryForm &&
                                      $refs.categoryForm.inputs[0].hasFocused &&
                                      $refs.categoryForm.inputs[0].hasError)
                                      ? 'true'
                                      : 'false'
                                  "
                                  :append-icon="
                                    (createNewCategoryInvalid &&
                                      newSubGroup.length === 0) ||
                                      ($refs.categoryForm &&
                                      $refs.categoryForm.inputs[0].hasFocused &&
                                      $refs.categoryForm.inputs[0].hasError)
                                      ? 'mdi-alert-circle'
                                      : ''
                                  "
                                  :aria-describedby="
                                    (createNewCategoryInvalid &&
                                      newSubGroup.length === 0) ||
                                      ($refs.categoryForm &&
                                      $refs.categoryForm.inputs[0].hasFocused &&
                                      $refs.categoryForm.inputs[0].hasError)
                                      ? 'category-error'
                                      : false
                                  "
                                >
                                  <template v-slot:message>
                                    <div id="category-error">
                                      <template
                                        v-for="message in $refs.categoryName
                                          .messagesToDisplay"
                                      >
                                        {{ message }}
                                      </template>
                                    </div>
                                  </template>
                                </v-text-field>
                              </v-list-item-content>
                              <v-list-item-action class="align-self-start mt-3">
                                <v-btn
                                  icon
                                  @click="createGroup"
                                  aria-label="Create a new subgroup"
                                >
                                  <v-icon color="primary">
                                    mdi-plus-circle
                                  </v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </template>
                          </v-list-item>
                        </v-form>
                      </v-list>
                    </v-dialog>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col
                v-if="isEdit"
                cols="12"
                sm="3"
                :order="$vuetify.breakpoint.mobile ? 1 : 0"
              >
                <AdsButton
                  color="error"
                  buttonText="Delete this contact"
                  @click="validateDelete"
                  block
                />
              </v-col>
              <v-spacer />
              <v-col
                cols="12"
                sm="3"
              >
                <AdsButton
                  primary
                  :buttonText="isEdit ? 'Save' : 'Add contact'"
                  @click="validate"
                  block
                  style="border-radius: 4px"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';

const isPhoneNumbersEqual = (phone1, phone2) => {
  if (phone1 == null && phone2 == null) {
    return true;
  }
  if (phone1 == null || phone2 == null) {
    return false;
  }
  // take out leading + 61 or 0
  if (phone1.startsWith('+61')) {
    phone1 = phone1.substring(3);
  }
  if (phone1.startsWith('0')) {
    phone1 = phone1.substring(1);
  }
  if (phone2.startsWith('+61')) {
    phone2 = phone2.substring(3);
  }
  if (phone2.startsWith('0')) {
    phone2 = phone2.substring(1);
  }
  const result = phone1.trim() === phone2.trim();
  return result;
};

const isEmailAddressesEqual = (email1, email2) => {
  if (email1 == null && email2 == null) {
    return true;
  }
  if (email1 == null || email2 == null) {
    return false;
  }
  return email1.trim().toLowerCase() === email2.trim().toLowerCase();
};
export default {
  name: 'CreateNewContact',
  data: () => ({
    valid: true,
    categoryFormValid: true,
    newSubGroup: '',
    firstNameRules: [
      (v) => !!v || 'First Name is required',
      (v) =>
        (v && v.length <= 40) || 'First Name must be less than 40 characters',
    ],
    lastNameRules: [
      (v) => !!v || 'Last Name is required',
      (v) =>
        (v && v.length <= 50) || 'Last Name must be less than 50 characters',
    ],
    formData: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      role: '',
      contact_cat_id: '',
      subgroup: {
        name: '',
        id: '',
      },
    },
    subgroupDialog: false,
    selectedSubgroup: null,
    createNewCategoryInvalid: false,
  }),
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    AdsButton,
  },
  computed: {
    ...mapGetters({
      school: 'moduleDashboard/getSelectedSchool',
      subGroupList: 'moduleManageContacts/getOtherContactsSubGroup',
      subGroupMembers: 'moduleManageContacts/otherContactsSubGroupDetails',
      otherContactsList: 'moduleManageContacts/getOtherContactList',
    }),
    getSelectionItems() {
      const entries = this.subGroupList.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      this.setSelectedItem(entries);

      return entries;
    },
  },
  methods: {
    setSelectedItem(entries) {
      if (this.isEdit) {
        const id = this.contact.contact_cat_id;
        var index = entries.findIndex(function (item) {
          return item.id === id;
        });
        this.formData.first_name = this.contact.first_name;
        this.formData.last_name = this.contact.last_name;
        this.formData.email = this.contact.email;
        this.formData.phone = this.contact.phone;
        this.formData.role = this.contact.role;
        this.formData.subgroup = entries[index];
        return;
      }
      this.formData.subgroup = entries[0];
    },
    async validate() {
      if (this.$refs.form.validate()) {
        if (this.isEdit) {
          await this.updateContact(this.formData);
        } else {
          await this.createNewContact(this.formData);
        }
        this.$emit('close-dialog', this.formData.subgroup.id);
        this.$refs.form.reset();
      }
    },
    async validateDelete() {
      await this.deleteContact(this.contact);
      this.$emit('close-dialog', this.formData.subgroup.id);
      this.$refs.form.reset();
    },
    async createGroup() {
      if (this.$refs.categoryForm.validate()) {
        this.createNewCategoryInvalid = false;
        await this.createNewSubGroup();
        this.$refs.categoryForm.reset();
      } else {
        this.createNewCategoryInvalid = true;
      }
    },
    async createNewSubGroup() {
      const payload = {
        name: this.newSubGroup,
        type: 'other',
      };
      this.$store.commit('SET_IS_LOADING', 'createSubGroup', {
        root: true,
      });
      await this.$http
        .post(
          process.env.VUE_APP_API_BASE_URL + '/contacts/categories',
          payload
        )
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch(
              'moduleManageContacts/fetchOtherContactSubGroups'
            );
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'createSubGroup', {
            root: true,
          });
        });
    },
    async createNewContact(data) {
      const payload = {
        first_name: data.first_name,
        last_name: data.last_name,
        contact_cat_id: data.subgroup.id,
        email: data.email,
        phone: data.phone,
        role: data.role,
        type: 'other',
      };
      this.$store.commit('SET_IS_LOADING', 'createNewContact', {
        root: true,
      });
      await this.$http
        .post(process.env.VUE_APP_API_BASE_URL + '/contacts', payload)
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch(
              'moduleManageContacts/fetchOtherContactBySubGroupId',
              data.subgroup.id
            );
            this.$refs.form.reset();
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'createNewContact', {
            root: true,
          });
        });
    },
    async deleteContact(data) {
      this.$store.commit('SET_IS_LOADING', 'deleteContact', {
        root: true,
      });
      const contactId = data.contact_id;
      const categoryId = data.contact_cat_id;
      await this.$http
        .delete(process.env.VUE_APP_API_BASE_URL + '/contacts/' + contactId)
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch(
              'moduleManageContacts/fetchOtherContactBySubGroupId',
              categoryId
            );
            this.$refs.form.reset();
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'deleteContact', {
            root: true,
          });
        });
    },
    async updateContact(data) {
      const contactId = this.contact.contact_id;
      const categoryId = data.subgroup.id;
      const payload = {
        first_name: data.first_name,
        last_name: data.last_name,
        contact_cat_id: categoryId,
        email: data.email,
        phone: data.phone,
        role: data.role,
        type: 'other',
      };
      this.$store.commit('SET_IS_LOADING', 'updateContact', {
        root: true,
      });
      await this.$http
        .put(
          process.env.VUE_APP_API_BASE_URL + `/contacts/${contactId}`,
          payload
        )
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch(
              'moduleManageContacts/fetchOtherContactBySubGroupId',
              this.contact.contact_cat_id
            );
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'updateContact', {
            root: true,
          });
        });
    },
    nameRules(v) {
      if (!v) {
        return 'Name is required';
      } else if (v && v.length >= 30) {
        return 'Name must be less than 30 characters';
      } else if (this.getSelectionItems.some((item) => item.name === v)) {
        return `${v} already exists`;
      } else {
        return true;
      }
    },
    emailRules(v) {
      if (!v) {
        return 'Email address is required';
      } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) {
        return 'Email address must be valid';
      } else if (
        this.otherContactsList.some(
          (item) =>
            isEmailAddressesEqual(item.email, v) &&
            item.contact_id !== this.contact.contact_id
        )
      ) {
        return 'Contact with email address already exists';
      } else {
        this.$store.dispatch('moduleManageContacts/fetchOtherContactsList', {
          backgroundLoading: true,
        });
        const exists = this.otherContactsList.some(
          (item) =>
            isEmailAddressesEqual(item.email, v) &&
            item.contact_id !== this.contact.contact_id
        );
        if (exists) {
          return 'Contact with email address already exists';
        }
        return true;
      }
    },
    phoneRules(v) {
      if (!v) {
        return 'Mobile number is required';
      } else if (!/^0(5|4)\d{8}$/.test(v)) {
        return 'Mobile number is required; must be 10 digits and begin with 04 or 05';
      } else if (
        this.subGroupMembers.some(
          (item) =>
            isPhoneNumbersEqual(item.phone, v) &&
            item.contact_id !== this.contact.contact_id
        )
      ) {
        return 'Contact with this mobile number already exists';
      } else {
        this.$store.dispatch('moduleManageContacts/fetchOtherContactsList', {
          backgroundLoading: true,
        });
        const exists = this.otherContactsList.some(
          (item) =>
            isPhoneNumbersEqual(item.phone, v) &&
            item.contact_id !== this.contact.contact_id
        );
        if (exists) {
          return 'Contact with this mobile number already exists';
        }
        return true;
      }
    },
    roleRules(v) {
      if (v && v.length > 50) {
        return 'Role cannot be more than 50 characters';
      } else {
        return true;
      }
    },
    subgroupChange() {
      this.subgroupDialog = false;
    },
  },
  created() {},
  mounted() {
    this.$store.dispatch('moduleManageContacts/fetchOtherContactsList');
  },
};
</script>

<style lang="scss" scoped>
.template-category {
  background-color: white;
  border: 1px solid #9d9d9d;
  font-weight: 500;

  ::v-deep .v-btn__content {
    justify-content: space-between;
  }
}
</style>
