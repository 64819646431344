<template>
  <div style="width: 100%">
    <AdsButton
      buttonText="New Subgroup"
      secondary
      icon="mdi-account-multiple-plus"
      @click="showDialog = true"
      block
      class="my-3"
    />
    <v-dialog
      v-model="showDialog"
      fullscreen
      persistent
      hide-overlay
      transition="slide-x-reverse-transition"
      content-class="subgroup-dialog bg-grey"
    >
      <v-card class="rounded-0">
        <v-toolbar color="primary">
          <v-btn
            icon
            color="white"
            @click="showDialog = false"
            aria-label="Back"
          >
            <v-icon> mdi-chevron-left </v-icon>
          </v-btn>
          <v-toolbar-title>
            <h1>New subgroup</h1>
          </v-toolbar-title>
        </v-toolbar>
        <CreateNewSubGroup
          @close-dialog="showDialog = false"
          :listType="subGroupType"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CreateNewSubGroup from '@/views/Settings/Contacts/CreateNewSubGroup';
import { AdsButton } from '@nswdoe/doe-ui-core';

export default {
  name: 'CreateSubGroupDialog',
  components: {
    AdsButton,
    CreateNewSubGroup,
  },
  data() {
    return {
      showDialog: false,
    };
  },
  props: {
    subGroupType: {
      type: String,
      default: '',
    },
    tertiary: {
      type: Boolean,
      default: false,
    },
  },

  created() {},
  watch: {
    showDialog(newValue) {
      if (newValue && this.subGroupType === 'staff') {
        this.$store.dispatch(
          'moduleManageContacts/fetchSchoolStaffContactsList'
        );
      }
      if (newValue && this.subGroupType === 'other') {
        this.$store.dispatch('moduleManageContacts/fetchOtherContactsList');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.subgroup-dialog {
  .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 10px;
    h1 {
      font-size: 20px;
    }
  }
}
</style>
