<template>
  <Dialog
    v-if="items"
    :openDialog="openDialog"
    :displayCloseBtn="true"
    @close="handleClose()"
    persistent
    :retain-focus="false"
    :return-handler="returnHandler"
    content-class="edit-dialog"
  >
    <template #title>
      <div class="v-card__title">
        <div class="title">
          Send this message to
          <span class="send_message_via">{{ name }}</span> via:
        </div>
      </div>
    </template>
    <template #text>
      <div class="px-6">
        <v-card
          class="mb-4"
          :color="recipient.selected == true ? '#CBEDFD' : ''"
          :style="
            recipient.selected
              ? { border: '2px solid #002664' }
              : { border: '1px solid' }
          "
          v-for="(recipient, index) in items"
          :key="index"
        >
          <v-card-subtitle>
            <v-checkbox
              v-model="recipient.selected"
              color="primary"
              :label="recipient.name"
              :aria-checked="recipient.selected ? 'true' : 'false'"
            />
          </v-card-subtitle>
        </v-card>
      </div>
    </template>
    <template #actions>
      <div class="px-6 pb-6 d-flex flex-column">
        <AdsButton
          primary
          buttonText="Done"
          class="btn--d-style"
          @click="updateChannelSelection()"
          style="border-radius: 4px"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { AdsButton, Dialog } from '@nswdoe/doe-ui-core';

export default {
  name: 'ChannelsDialog',
  data() {
    return {
      channels: [],
    };
  },
  props: {
    openDialog: Boolean,
    returnHandler: {
      type: Function,
      default: () => {},
    },
    name: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    Dialog,
    AdsButton,
  },
  methods: {
    updateChannelSelection() {
      const data = { name: this.name, channels: this.items };
      this.$emit('close-dialog', data);
    },
    handleClose() {
      const data = { name: this.name, channels: this.items };
      this.$emit('close-dialog', data);
    },
  },
};
</script>
<style></style>
