<template>
  <div>
    <div style="background-color: white">
      <v-container class="py-5">
        <v-row justify="center">
          <v-col
            cols="12"
            md="10"
            class="justify-center"
          >
            <FullScreenBlankMessageDialog
              :eventDetails="event"
              buttonText="Start with a blank message"
              :close="close"
              :update="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="container-wrapper">
      <v-container
        style="background: #f4f4f7"
        class="py-1"
      >
        <v-row
          class="mt-2"
          justify="center"
        >
          <v-col
            cols="12"
            md="10"
            class="template-header justify-center"
          >
            <span style="color: #456eb0"> OR </span>
            <span
              class="ml-2"
              style="color: #000"
            > choose a template: </span>
          </v-col>
          <v-col
            v-if="getAllTemplates"
            cols="12"
            md="10"
          >
            <AdsSelect
              class="ad-select white"
              v-model="selected"
              :items="getAllTemplates"
              return-object
              item-text="name"
              item-value="catId"
              outlined
              label="Choose a template"
              menu-props="offset-y"
              hide-details
            />
          </v-col>
          <v-col
            cols="12"
            md="10"
            v-if="selected"
          >
            <span role="status">
              <span
                class="template-number-text"
                style="font-weight: 700"
              >
                {{ selected.total }}
              </span>
              <span class="select">templates in this category</span>
            </span>
          </v-col>
          <v-col
            cols="12"
            md="10"
            v-if="templatesMetaData"
          >
            <v-list class="pb-0">
              <v-list-item
                class="pb-6 px-0"
                v-for="item in templatesMetaData"
                :key="item.id"
              >
                <v-card class="notification-box px-2 py-2 flex-grow-1">
                  <v-card-title class="template-header-text mb-4">
                    <h2 style="font-size: 18px">
                      {{ item.title }}
                    </h2>
                  </v-card-title>
                  <v-card-subtitle class="template-description">
                    {{ item.description }}
                  </v-card-subtitle>

                  <v-card-actions class="pt-2 pb-4 px-4">
                    <FullScreenBlankMessageDialog
                      :templateId="item.id"
                      buttonText="Use this template"
                      :close="close"
                      :update="update"
                    />
                  </v-card-actions>
                </v-card>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import FullScreenBlankMessageDialog from '@/components/common/FullScreenBlankMessageDialog';
import { AdsSelect } from '@nswdoe/doe-ui-core';
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'NewMessage',
  props: {
    update: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showMessage: false,
    items: [],
  }),
  components: {
    AdsSelect,
    FullScreenBlankMessageDialog,
  },
  methods: {
    showDashboard() {
      this.$router.push({
        path: '/',
      });
    },
    showBlankMessage(templateId) {
      this.$router.push({
        path: `/message/${templateId}`,
      });
    },
    useTemplate(templateId) {
      this.$router.push({
        path: `/message/${templateId}`,
      });
    },
    resetSelectionItems() {
      this.items = [];
    },
    // mapSelectionItem1(item) {
    //   const header = { header: item.name };
    //   const options = item.items.map((el) => {
    //     const option = {
    //       name: el.name,
    //       type: el.type,
    //       total: el.total,
    //       catId: el.catId,
    //     };
    //     var prependCount = `${el.name} (${el.total})`;
    //     option.name = prependCount;
    //     return option;
    //   });
    //   const divider = { divider: true };
    //   this.items.push(header);
    //   for (const op of options) {
    //     this.items.push(op);
    //   }
    //   this.items.push(divider);
    //   return this.items;
    // },
    mapSelectionItem(item) {
      var mappedItems = [];
      const header = { header: item.name };
      const options = item.items.map((el) => {
        const option = {
          name: el.name,
          type: el.type,
          total: el.total,
          catId: el.catId,
        };
        var prependCount = `${el.name} (${el.total})`;
        option.name = prependCount;
        return option;
      });
      if (options && options[0].type !== 'Emergencies') {
        this.sortByName(options);
      }
      const divider = { divider: true };
      mappedItems.push(header);
      for (const op of options) {
        mappedItems.push(op);
      }
      mappedItems.push(divider);
      this.items.push(...mappedItems);
      return mappedItems;
    },
    sortByName(item) {
      const res = item.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      return res;
    },
    sortByPriority(item) {
      const res = item.sort(function (a, b) {
        if (a.catId < b.catId) {
          return -1;
        }
        if (a.catId > b.catId) {
          return 1;
        }
        return 0;
      });
      return res;
    },
  },
  computed: {
    ...mapState('moduleTemplates', {
      templates: (state) => state.templates,
    }),
    ...mapGetters({
      //templates: 'moduleTemplates/getTemplates',
      selectedTemplate: 'moduleTemplates/getSelectedTemplates',
      templatesMetaData: 'moduleTemplates/getTemplatesMetaData',
      school: 'moduleDashboard/getSelectedSchool',
      event: 'moduleDashboard/getSelectedEvent',
      communicationInfo: 'moduleDashboard/communicationInfo',
    }),
    getAllTemplates() {
      this.resetSelectionItems();
      var selection = [];
      let emergencyItems = [];
      for (const item of this.templates) {
        const items = this.mapSelectionItem(item);
        if (items[0].header === 'Emergencies') {
          emergencyItems = items;
          this.sortByPriority(emergencyItems);
        } else {
          selection.push(...items);
        }
      }
      this.sortByName(selection);
      this.sortByPriority(emergencyItems);
      selection.unshift(...emergencyItems);

      return selection;
    },
    selected: {
      get() {
        if (this.templates && !this.selectedTemplate) {
          const category = this.templates.filter(
            (el) => el.category === 'Emergencies'
          );
          if (category.length > 0) {
            const res = category[0]?.items.filter(
              (eachVal) => eachVal.catId === 'C001'
            );
            this.$store.dispatch(
              'moduleTemplates/updateSelectedTemplate',
              res[0]
            );
            return res[0];
          }
        }
        return this.selectedTemplate;
      },
      set(selection) {
        this.$store.dispatch(
          'moduleTemplates/updateSelectedTemplate',
          selection
        );
      },
    },
  },
  watch: {
    selected: {
      async handler(selection) {
        await this.$store.dispatch(
          'moduleTemplates/fetchTemplatesByCategoryId',
          selection?.catId
        );
      },
    },
  },
  async created() {
    await this.$store.dispatch('moduleTemplates/fetchTemplates');
    await this.$store.dispatch('moduleDashboard/fetchCommunicationInfo');
    await this.$store.dispatch(
      'moduleRecipients/fetchRecipientByTemplateId',
      this.communicationInfo
    );
    this.$store.dispatch('moduleManageContacts/fetchDelContacts');
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 968px) {
  .notification-box,
  .start_with_blank_btn,
  .use-template-btn,
  .ad-select {
    width: 100% !important;
  }
}

.primary {
  height: 64px;
  background-color: #002664 !important;
}

.container-wrapper,
.container-main {
  background-color: $ads-light-10;
  margin-right: 0px;
}

.container-wrapper {
  height: 100%;
}

.start_with_blank_btn {
  display: flex;
  width: 799px;
  height: 49px !important;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

::v-deep {
  .theme--light.v-list {
    background: #f4f4f7;
  }

  .header-btn i {
    font-size: 40px;
  }

  .container-main fieldset {
    background-color: #fff;
  }
  .ad-select {
    .v-text-field__details {
      display: none;
    }
  }
}
</style>
