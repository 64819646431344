export default [
    {
        'title': 'Storm Notification',
        'name' : 'Storm emergency',
        'description' : 'This template to notify storm emergency ggggggggg dfgdfgdfg degdfgdfgd oghyugg',
        'id': 'emergency1231',
        'parentCartegoryId' : 'emergency123',
        'edit': true,
    },
    {
        'title': 'Wind Notification',
        'name' : 'Wind emergency',
        'description' : 'This template to notify wind emergency detertretretret ',
        'id': 'emergency1232',
        'parentCartegoryId' : 'emergency123',
        'edit': false,
    },
    {
        'title': 'Cyclone Notification',
        'name' : 'Cyclone emergency',
        'description' : 'This template to notify Cyclone emergency dfgdfgdfgdfgdf',
        'id': 'emergency1233',
        'parentCartegoryId' : 'emergency123',
        'edit': true,
    },
    {
        'title': 'Flood Notification',
        'name' : 'Flood emergency',
        'description' : 'This template to notify Flood emergency',
        'id': 'emergency1234',
        'parentCartegoryId' : 'emergency123',
        'edit': true,
    }
  ];