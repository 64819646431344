<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    persistent
    hide-overlay
    transition="slide-x-reverse-transition"
    content-class="subgroup-dialog bg-grey"
  >
    <v-card class="rounded-0">
      <v-toolbar color="primary">
        <v-toolbar-title class="order-1">
          <h1>Manage subgroup</h1>
        </v-toolbar-title>
        <v-btn
          icon
          color="white"
          @click="$emit('closeDialog')"
          aria-label="Back"
        >
          <v-icon> mdi-chevron-left </v-icon>
        </v-btn>
      </v-toolbar>
      <EditSubGroupMembers
        :subGroup="subGroup"
        @close-dialog="$emit('closeDialog')"
      />
    </v-card>
  </v-dialog>
</template>
<script>
import EditSubGroupMembers from '@/views/Settings/Contacts/EditSubGroupMembers';

export default {
  name: 'EditSubGroupDialog',
  components: {
    EditSubGroupMembers,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    subGroupType: {
      type: String,
      default: '',
    },
    tertiary: {
      type: Boolean,
      default: false,
    },
    subGroup: {
      type: Object,
      default: () => {
        return {};
      },
    },
    closeDialog: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
.subgroup-dialog {
  .v-toolbar__content > .v-toolbar__title:first-child {
    padding-left: 10px;
    h1 {
      font-size: 20px;
    }
  }
}
</style>
