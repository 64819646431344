<template>
  <div
    class="container-wrapper"
    style="background: #f4f4f7"
  >
    <v-container class="py-5">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
        >
          <h2 class="notify-title-text mb-6">
            Group Name:
          </h2>
          <span class="select">School emergency response team</span>
        </v-col>
        <v-col
          cols="12"
          sm="10"
        >
          <h2 class="notify-title-text">
            Group Members:
          </h2>
        </v-col>
        <v-col
          cols="12"
          sm="10"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-card>
              <div class="pl-4 py-2 pr-1">
                <v-text-field
                  solo
                  prepend-icon="mdi-magnify grey--text"
                  label="Search for a contact"
                  v-model="searchBarValue"
                  hide-details
                  flat
                  clearable
                  role="combobox"
                  :aria-expanded="searchBarValue && searchBarValue.length > 0 && filteredContacts && filteredContacts.length ? 'true' : 'false'"
                  autocomplete="off"
                  aria-activedescendant="contacts-available"
                />
              </div>
              <v-divider />
              <v-list
                class="py-0"
                v-if="!searchBarValue || searchBarValue && searchBarValue.length === 0"
              >
                <v-list-item
                  v-for="member in sortBy(fullMembersList, (m) =>
                    fullName(m).toLocaleLowerCase()
                  )"
                  :key="`list-${member.staff_id}`"
                  class="py-1"
                  @click="removeMember(member, true)"
                  aria-selected="true"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ fullName(member) }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon color="primary">
                      mdi-minus-circle-outline
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              <v-list
                v-else-if="searchBarValue && searchBarValue.length > 0 && filteredContacts && filteredContacts.length"
                class="py-0"
                id="contacts-available"
              >
                <v-list-item
                  v-for="contact in filteredContacts"
                  :key="`search-${contact.staff_id}`"
                  class="py-1 d-flex align-center"
                  @click="addMember(contact)"
                  :aria-selected="alreadyAdded(contact.staff_id) ? 'true' : 'false'"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ fullName(contact) }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon
                      v-if="alreadyAdded(contact.staff_id)"
                      color="primary"
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon
                      v-else
                      color="primary"
                    >
                      mdi-check-circle-outline
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              <v-list v-else>
                <v-list-item>
                  <strong>Contact not found</strong>
                </v-list-item>
              </v-list>
            </v-card>
          </v-form>
        </v-col>
        <v-col
          cols="12"
          sm="10"
        >
          <v-row justify="end">
            <v-col
              cols="12"
              sm="3"
            >
              <AdsButton
                primary
                buttonText="Done"
                @click="validate"
                block
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';
import { sortBy } from 'lodash';
export default {
  name: 'EmergencyContact',
  components: {
    AdsButton,
  },
  computed: {
    ...mapGetters({
      membersList: 'moduleManageContacts/getEmergencyTeam',
      contactsList: 'moduleManageContacts/getContactList',
      school: 'moduleDashboard/getSelectedSchool',
    }),
    filteredContacts() {
      let contacts = this.contactsList;
      if (this.searchBarValue.length > 0) {
        return contacts.filter((contact) =>
          this.fullName(contact)
            .toLocaleLowerCase()
            .includes(this.searchBarValue.toLocaleLowerCase())
        );
      }
      return contacts;
    },
    fullMembersList() {
      return [...this.membersList, ...this.addedMembers].filter((user) => {
        return !this.removedMembers.some(
          (member) => member.staff_id === user.staff_id
        );
      });
    },
  },
  data: () => ({
    valid: true,
    entries: [],
    model: null,
    search: null,
    isLoading: false,
    searchBarValue: '',
    addedMembers: [],
    removedMembers: [],
    formData: {
      firstName: '',
    },
    rules: {
      required: (v) => !!v || 'this field is required',
      lengthCheck: (v) =>
        (v && v.length <= 20) || 'Name must be less than 20 characters',
    },
  }),
  methods: {
    sortBy,
    removeMember(member, timeout) {
      if (timeout) {
        setTimeout(() => {
          if (
            this.addedMembers.some((user) => user.staff_id === member.staff_id)
          ) {
            this.addedMembers = this.addedMembers.filter(
              (item) => item.staff_id !== member.staff_id
            );
          } else {
            this.removedMembers.push(member);
          }
        }, 350);
      } else {
        if (
          this.addedMembers.some((user) => user.staff_id === member.staff_id)
        ) {
          this.addedMembers = this.addedMembers.filter(
            (item) => item.staff_id !== member.staff_id
          );
        } else {
          this.removedMembers.push(member);
        }
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        await this.UpsertERTGroup();
        this.$emit('close-dialog', true);
      }
    },
    addMember(member) {
      if (
        this.membersList.find(
          (user) =>
            user.staff_id === member.staff_id &&
            !this.removedMembers.find(
              (user) => user.staff_id === member.staff_id
            )
        )
      ) {
        this.removeMember(member, false);
      } else if (
        this.membersList.find(
          (user) =>
            user.staff_id === member.staff_id &&
            this.removedMembers.find(
              (user) => user.staff_id === member.staff_id
            )
        )
      ) {
        this.removedMembers = this.removedMembers.filter(
          (user) => user.staff_id !== member.staff_id
        );
      } else if (
        this.addedMembers.find((o) => o.staff_id === member.staff_id)
      ) {
        this.removeMember(member, false);
      } else {
        this.addedMembers.push(member);
      }
    },
    fullName(contact) {
      return contact?.first_name.trim() + ' ' + contact?.last_name.trim();
    },
    async UpsertERTGroup() {
      const payload = this.mapMembersData(this.fullMembersList);
      const categoryId = 'ERT';
      this.$store.commit('SET_IS_LOADING', 'updateERTGroup', {
        root: true,
      });
      await this.$http
        .put(process.env.VUE_APP_API_BASE_URL + '/contacts/ert/bulk', payload, {
          params: {
            categoryId,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.addedMembers = [];
            this.removedMembers = [];
            this.searchBarValue = '';
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'updateERTGroup', {
            root: true,
          });
        });
    },
    mapMembersData(members) {
      const options = members.map((el) => {
        const option = {
          first_name: el.first_name,
          last_name: el.last_name,
          staff_id: el.staff_id,
        };
        return option;
      });
      return options;
    },
    alreadyAdded(staff_id) {
      return this.fullMembersList.some(
        (member) => member.staff_id === staff_id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-style {
  height: 44px;
  align-items: center;
}
</style>
