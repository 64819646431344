<template>
  <div>
    <v-container>
      <v-card
        class="py-4 px-8"
      >
        <v-card-text class="text-center">
          <div>
            <v-icon size="50">
              mdi-email
            </v-icon>
          </div>

          <div>
            <span class="active">{{ messageStatus }} not available</span>
          </div>
          <!-- <div>
            <b>select Start new message to to get started</b>
          </div> -->
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
    name: 'NotFound',
    props: {
    messageStatus: {
        type: [String],
        default: ''
    }
    }
}
</script>

<style lang="scss" scoped>

::v-deep {
  .v-card {
    h3 {
      color: $ads-primary-blue;
    }
  }
}

</style>