var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-wrapper",staticStyle:{"background":"#f4f4f7"}},[_c('v-container',{staticClass:"py-5"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"notify-title-text"},[_vm._v(" Subgroup Name: ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"subgroupName",attrs:{"outlined":"","background-color":"white","label":"Subgroup name","required":"","placeholder":"Required","rules":[_vm.checkAllStaff, _vm.checkDuplicate, _vm.rules.required, _vm.rules.lengthCheck],"persistent-placeholder":"","aria-invalid":_vm.$refs.form &&
                    _vm.$refs.form.inputs[0].hasFocused &&
                    _vm.$refs.form.inputs[0].hasError
                    ? 'true'
                    : 'false',"append-icon":_vm.$refs.form &&
                    _vm.$refs.form.inputs[0].hasFocused &&
                    _vm.$refs.form.inputs[0].hasError
                    ? 'mdi-alert-circle'
                    : '',"aria-describedby":_vm.$refs.form &&
                    _vm.$refs.form.inputs[0].hasFocused &&
                    _vm.$refs.form.inputs[0].hasError
                    ? 'subgroup-name-error'
                    : false,"hide-details":"auto"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_c('div',{attrs:{"id":"subgroup-name-error"}},[_vm._l((_vm.$refs.subgroupName.messagesToDisplay),function(message){return [_vm._v(" "+_vm._s(message)+" ")]})],2)]},proxy:true}]),model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"notify-title-text"},[_vm._v("Contacts:")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('div',{staticClass:"pl-4 py-2 pr-1"},[_c('v-text-field',{attrs:{"solo":"","prepend-icon":"mdi-magnify grey--text","label":"Search for a contact","hide-details":"","flat":"","clearable":"","role":"combobox","aria-expanded":_vm.searchBarValue &&
                        _vm.searchBarValue.length > 0 &&
                        _vm.filteredContacts &&
                        _vm.filteredContacts.length
                        ? 'true'
                        : 'false',"autocomplete":"off","aria-activedescendant":"contacts-available"},model:{value:(_vm.searchBarValue),callback:function ($$v) {_vm.searchBarValue=$$v},expression:"searchBarValue"}})],1),_c('v-divider'),(
                    !_vm.searchBarValue ||
                      (_vm.searchBarValue && _vm.searchBarValue.length === 0)
                  )?_c('v-list',{staticClass:"py-0"},_vm._l((_vm.sortBy(_vm.fullMembersList, (m) =>
                      _vm.fullName(m).toLocaleLowerCase()
                    )),function(member){return _c('v-list-item',{key:member.staff_id,staticClass:"py-1",attrs:{"aria-selected":"true"},on:{"click":function($event){return _vm.removeMember(member, true)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.fullName(member))+" ")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-minus-circle-outline ")])],1)],1)}),1):(
                    _vm.searchBarValue &&
                      _vm.searchBarValue.length > 0 &&
                      _vm.filteredContacts &&
                      _vm.filteredContacts.length
                  )?_c('v-list',{staticClass:"py-0",attrs:{"id":"contacts-available"}},_vm._l((_vm.filteredContacts),function(contact){return _c('v-list-item',{key:`search-${contact.staff_id}`,staticClass:"py-1",attrs:{"aria-selected":_vm.alreadyAdded(contact.staff_id) ? 'true' : 'false'},on:{"click":function($event){return _vm.addMember(contact)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.fullName(contact))+" ")])],1),_c('v-list-item-icon',[(_vm.alreadyAdded(contact.staff_id))?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-check-circle-outline ")])],1)],1)}),1):(
                    _vm.searchBarValue &&
                      _vm.searchBarValue.length > 0 &&
                      _vm.filteredContacts &&
                      _vm.filteredContacts.length === 0
                  )?_c('v-list',[_c('v-list-item',[_c('strong',[_vm._v("Contact not found")])])],1):_vm._e(),(
                    _vm.searchBarValue &&
                      _vm.searchBarValue.length === 0 &&
                      _vm.fullMembersList &&
                      _vm.fullMembersList.length === 0
                  )?_c('v-list',[_c('v-list-item',[_c('strong',[_vm._v("Members not found")])])],1):_vm._e()],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('AdsButton',{attrs:{"primary":"","buttonText":"Done","block":""},on:{"click":_vm.validate}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }