export function getXIdToken(oAuthData) {
    if (process.env.NODE_ENV === 'local') {
        const id_token = localStorage.getItem('id_token');
        if (id_token) {
            return id_token;
        }
        return 'dev_token';
    } else {
        return oAuthData.idToken;
    }
}

export function getContextualSchoolCode() {
    const sessionStorageData = window.sessionStorage.getItem('vuex');
    if (sessionStorageData) {
        const parsedData = JSON.parse(sessionStorageData)
        const schoolCode = parsedData.moduleDashboard.selectedSchool.code;
        return schoolCode
    }
    return null;
}

export function getAccessToken(oAuthData) {
    if (process.env.NODE_ENV === 'local') {
        const access_token = localStorage.getItem('access_token');
        if (access_token) {
            return access_token;
        }
        return 'dev_token';
    }
    else {
        return oAuthData.accessToken;
    }
}
