var render = function render(){var _vm=this,_c=_vm._self._c;return (
    this.communicationInfo &&
      Array.isArray(this.communicationInfo.recipients) &&
      this.communicationInfo.recipients.length > 0
  )?_c('v-card',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.doesNameExist('DEL')),expression:"doesNameExist('DEL')"}]},[_c('DelRecipient',{attrs:{"update":this.update,"close":this.close},on:{"del-update":(items) => _vm.handleDelEvents('del-channels-update', items)}}),_c('v-divider')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.doesNameExist('ERT')),expression:"doesNameExist('ERT')"}]},[_c('EmergencyRecipient',{on:{"emergency-update":(items) => _vm.handleEmergencyEvents('emergency-channels-update', items)}}),_c('v-divider')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.doesNameExist('STAFF')),expression:"doesNameExist('STAFF')"}]},[_c('SchoolStaffRecipient',{on:{"staff-subgroup-update":(items) =>
          _vm.handleSubgroupEvent('staff-subgroup-channels-update', items)}}),_c('v-divider')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.doesNameExist('PARENT')),expression:"doesNameExist('PARENT')"}]},[_c('ParentsAndCarersRecipient',{on:{"parent-update":(items) => _vm.handleParentsEvents('parent-channels-update', items)}}),_c('v-divider')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.doesNameExist('OTHER')),expression:"doesNameExist('OTHER')"}]},[_c('OtherRecipient',{on:{"other-subgroup-update":(items) =>
          _vm.handleSubgroupEvent('other-subgroup-channels-update', items)}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }