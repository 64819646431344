// import mockCommsInfo from '../../api/data/comms-data.js';
import emergencyChecklistsData from '../../api/data/checklist.js';

// Vuex Dashboard module
const moduleDashboard = {
  namespaced: true,
  state: {
    dashboard: [],
    selectedSchool: undefined,
    school: [],
    selectedEvent: undefined,
    communicationInfo: {},
    facebookEnabled: false,
    teamsEnabled: false,
    emergencyChecklists: [],
  },
  getters: {
    activeMessages: (state) => {
      let activeMessage = state.dashboard.filter(
        (item) => item.status === 'Active'
      );
      let res = activeMessage.sort(function (x, y) {
        return new Date(x.modified_datetime) - new Date(y.modified_datetime);
      });

      return res.reverse();
    },
    closedMessages: (state) => {
      var closedMessages = state.dashboard.filter(
        (item) => item.status === 'Closed'
      );
      const res = closedMessages.sort(function (x, y) {
        return new Date(x.modified_datetime) - new Date(y.modified_datetime);
      });
      return res.reverse();
    },
    communicationInfo: (state) => state.communicationInfo,
    getSelectedSchool: (state) => state.selectedSchool,
    getSelectedEvent: (state) => state.selectedEvent,
    getAllSchools: (state) =>
      state.school.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }),
    getEmergencyChecklists: (state) => state.emergencyChecklists,
  },
  mutations: {
    SET_COMMUNICATION_INFO(state, communicationInfo) {
      state.communicationInfo = communicationInfo;
    },
    SET_FACEBOOK_ENABLED(state, isEnabled) {
      state.facebookEnabled = isEnabled;
    },
    SET_TEAMS_ENABLED(state, isEnabled) {
      state.teamsEnabled = isEnabled;
    },
    SET_DASHBOARD_MESSAGES(state, dashboard) {
      state.dashboard = dashboard;
    },
    SET_SELECTED_SCHOOL(state, schools) {
      state.selectedSchool = schools;
    },
    SET_SELECTED_EVENT(state, event) {
      state.selectedEvent = event;
    },
    SET_ALL_SCHOOLS(state, schools) {
      schools.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      var selectedSchool = schools[0];
      state.selectedSchool = selectedSchool;
      state.school = schools;
    },
    SET_EMERGENCY_CHECKLISTS(state, data) {
      state.emergencyChecklists = data;
    },
  },

  actions: {
    fetchSchoolNotifications({ commit, dispatch }) {
      commit('SET_IS_LOADING', 'schoolNotifications', { root: true });

      return this._vm.$http
        .get('/events')
        .then((response) => {
          if (response.data) {
            return commit('SET_DASHBOARD_MESSAGES', response.data);
          }
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', 'schoolNotifications', { root: true });
          dispatch('fetchCommunicationInfo');
        });
    },

    fetchCommunicationInfo({ commit }) {
      commit('SET_IS_LOADING', 'communicationInfo', { root: true });

      this._vm.$http
        .get('/school/comms-info')
        .then((response) => {
          commit('SET_COMMUNICATION_INFO', response.data);
          const isFacebookEnabled = response.data.channels.find(
            (channel) => channel.name === 'facebook'
          )?.enabled;
          commit('SET_FACEBOOK_ENABLED', isFacebookEnabled);

          const isTeamsEnabled = response.data.channels.find(
            (channel) => channel.name === 'teams'
          )?.enabled;
          commit('SET_TEAMS_ENABLED', isTeamsEnabled);
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', 'communicationInfo', { root: true });
        });
    },

    updateSelectedSchool({ commit }, selectedSchool) {
      commit('SET_SELECTED_SCHOOL', selectedSchool);
    },

    updateSelectedEvent({ commit }, event) {
      commit('SET_SELECTED_EVENT', event);
    },

    fetchAllSchools({ commit }) {
      commit('SET_IS_LOADING', 'fetchAllSchools', { root: true });
      this._vm.$http
        .get('/school/list')
        .then((response) => {
          if (response.data.length == 1) {
            commit('SET_SELECTED_SCHOOL', [response.data[0]]);
          }
          return commit('SET_ALL_SCHOOLS', response.data);
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        })
        .finally(() => {
          commit('REMOVE_IS_LOADING', 'fetchAllSchools', { root: true });
        });
    },
    fetchEmergencyChecklists({ commit }) {
      commit('SET_EMERGENCY_CHECKLISTS', emergencyChecklistsData);
    },
  },
};

export default moduleDashboard;
