<template>
  <v-container class="py-5">
    <v-row justify="center">
      <v-col
        v-for="item in history"
        :key="item.id"
        cols="12"
        sm="10"
      >
        <v-card variant="outlined">
          <div class="d-flex history-header py-2">
            <v-icon class="pl-4 pr-2">
              mdi-send-outline
            </v-icon>
            <h3 class="message_sent mt-1">
              MESSAGE SENT
            </h3>
          </div>
          <div class="d-flex pl-4 pr-4 mt-4 align-center">
            <v-avatar
              round
              color="#002664"
            >
              <span class="initials">{{ userInitials }}</span>
            </v-avatar>
            <div class="d-flex pl-4 pr-4 flex-column">
              <div class="last-message">
                {{ formatDate(item.created_datetime) }}
              </div>
              <div class="last-message-date">
                by {{ item.created_by }}
              </div>
            </div>
            <v-spacer />
            <div>
              <EventDetailsDialog
                :item="item"
                :isEmergency="event && event.is_emergency"
              />
            </div>
          </div>
          <v-card-title class="ml-2">
            <h2 style="font-size: 20px;">
              {{ item.title }}
            </h2>
          </v-card-title>
          <v-card-text class="ml-2">
            <span
              class="description"
              v-html="item.message"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import EventDetailsDialog from '@/views/EventDetails/EventDetailsDialog';

export default {
  name: 'History',
  components: {
    EventDetailsDialog,
  },
  data: () => ({
    eventDetailsDialog: false,
    eventHistory: {
      school: {},
      eventData: {},
    },
  }),
  computed: {
    ...mapGetters({
      history: 'moduleEventDetails/getAllHistory',
      school: 'moduleDashboard/getSelectedSchool',
      event: 'moduleDashboard/getSelectedEvent',
      userProfile: 'moduleUserProfile/userProfile',
    }),
    userInitials() {
      const firstName =
        this.userProfile.firstName || this.$OAuth.data?.profile?.name || '';
      const lastName = this.userProfile.lastName || '';
      const firstInitial = firstName.charAt(0);
      const lastInitial = lastName.charAt(0);

      return `${firstInitial}${lastInitial}`;
    },
  },
  created() {
    if (this.event) {
      this.$store.dispatch(
        'moduleEventDetails/fetchHistoryById',
        this.event.event_id,
        this.school.schoolCode
      );
    }
  },
  methods: {
    formatDate(itemDate) {
      let date = new Date(itemDate);
      return moment(date).format('DD/MM/YYYY, HH:mm A');
    },
  },
};
</script>

<style lang="scss" scoped>
.container-wrapper,
.container-main {
  border-radius: 10px 10px 0px 0px;
  background: var(--greys-white, #fff);
}

.description {
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #22272b;
}
.initials {
  color: #cbedfd !important;
}
.history-card {
  width: 800px;
  margin: 0 auto;
}
@media screen and (max-width: 968px) {
  .history-card {
    width: 100%;
  }
}
</style>
