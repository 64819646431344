<template>
  <div
    class="container-wrapper"
    style="background: #f4f4f7"
  >
    <v-container class="py-5">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
        >
          <span class="notify-title-text">DEL Details</span>
        </v-col>
        <v-col
          cols="12"
          sm="10"
        >
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-card>
              <v-card-subtitle class="pa-6 pt-7 pb-0">
                <v-text-field 
                  ref="firstName"
                  outlined
                  background-color="white"
                  label="First name"
                  v-model="formData.first_name"
                  required
                  placeholder="Required"
                  :rules="firstNameRules"
                  persistent-placeholder
                  :aria-invalid="$refs.firstName && $refs.firstName.hasFocused && $refs.firstName.hasError ? 'true' : 'false'"
                  :append-icon="$refs.firstName && $refs.firstName.hasFocused && $refs.firstName.hasError ? 'mdi-alert-circle' : ''"
                  :aria-describedby="$refs.firstName && $refs.firstName.hasFocused && $refs.firstName.hasError ? 'first-name-error' : false"
                >
                  <template v-slot:message>
                    <div id="first-name-error">
                      <template v-for="(message) in $refs.firstName.messagesToDisplay">
                        {{ message }}
                      </template>
                    </div>
                  </template>
                </v-text-field>
                <v-text-field 
                  ref="lastName"
                  outlined
                  background-color="white"
                  label="Last name"
                  v-model="formData.last_name"
                  required
                  placeholder="Required"
                  :rules="lastNameRules"
                  persistent-placeholder
                  :aria-invalid="$refs.lastName && $refs.lastName.hasFocused && $refs.lastName.hasError ? 'true' : 'false'"
                  :append-icon="$refs.lastName && $refs.lastName.hasFocused && $refs.lastName.hasError ? 'mdi-alert-circle' : ''"
                  :aria-describedby="$refs.lastName && $refs.lastName.hasFocused && $refs.lastName.hasError ? 'last-name-error' : false"
                >
                  <template v-slot:message>
                    <div id="last-name-error">
                      <template v-for="(message) in $refs.lastName.messagesToDisplay">
                        {{ message }}
                      </template>
                    </div>
                  </template>
                </v-text-field>
                <v-text-field 
                  ref="phone"
                  outlined
                  background-color="white"
                  label="Mobile number"
                  v-model="formData.phone"
                  required
                  placeholder="Required"
                  :rules="phoneRules"
                  persistent-placeholder
                  :aria-invalid="$refs.phone && $refs.phone.hasFocused && $refs.phone.hasError ? 'true' : 'false'"
                  :append-icon="$refs.phone && $refs.phone.hasFocused && $refs.phone.hasError ? 'mdi-alert-circle' : ''"
                  :aria-describedby="$refs.phone && $refs.phone.hasFocused && $refs.phone.hasError ? 'phone-error' : false"
                >
                  <template v-slot:message>
                    <div id="phone-error">
                      <template v-for="(message) in $refs.phone.messagesToDisplay">
                        {{ message }}
                      </template>
                    </div>
                  </template>
                </v-text-field>
                <v-text-field 
                  ref="email"
                  outlined
                  background-color="white"
                  label="Email address"
                  v-model="formData.email"
                  required
                  placeholder="Required"
                  :rules="emailRules"
                  persistent-placeholder
                  :aria-invalid="$refs.email && $refs.email.hasFocused && $refs.email.hasError ? 'true' : 'false'"
                  :append-icon="$refs.email && $refs.email.hasFocused && $refs.email.hasError ? 'mdi-alert-circle' : ''"
                  :aria-describedby="$refs.email && $refs.email.hasFocused && $refs.email.hasError ? 'email-error' : false"
                >
                  <template v-slot:message>
                    <div id="email-error">
                      <template v-for="(message) in $refs.email.messagesToDisplay">
                        {{ message }}
                      </template>
                    </div>
                  </template>
                </v-text-field>
              </v-card-subtitle>
            </v-card>
            <v-row justify="end">
              <v-col sm="3">
                <AdsButton
                  primary
                  block
                  buttonText="Save and close"
                  :disabled="!this.valid"
                  @click="validate"
                  class="mt-8"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core';
import { mapGetters } from 'vuex';
export default {
  name: 'DelContact',
  data: () => ({
    valid: true,
    isEdit: false,
    formData: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      contact_id: '',
    },
    firstNameRules: [
      (v) => !!v || 'First Name is required',
      (v) =>
        (v && v.length <= 40) || 'First Name must be less than 40 characters',
    ],
    lastNameRules: [
      (v) => !!v || 'Last Name is required',
      (v) =>
        (v && v.length <= 50) || 'Last Name must be less than 50 characters',
    ],
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.length <= 20) || 'Name must be less than 20 characters',
    ],
    emailRules: [
      (v) => !!v || 'Enter a department email address',
      (v) =>
        /.+@det.nsw.edu.au$/.test(v) ||
        'Enter a department email address',
    ],
    phoneRules: [
      (v) => !!v || 'Mobile number is required',
      (v) => /^0(5|4)\d{8}$/.test(v) || 'Mobile number is required; must be 10 digits and begin with 04 or 05',
    ],
  }),
  props: {
    dialogOpen: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    AdsButton,
  },
  methods: {
    addContact() {
      alert('Add contacts');
    },
    showContacts() {
      this.$router.push({
        path: '/contacts',
      });
    },
    async validate() {
      if (this.$refs.form.validate()) {
        const payload = {
          first_name: this.formData.first_name,
          last_name: this.formData.last_name,
          contact_cat_id: 'DEL',
          email: this.formData.email,
          phone: this.formData.phone,
          type: 'DEL',
        };
        if (!this.isEdit) {
          await this.createNewDel(payload);
        } else {
          await this.updateDel(this.formData.contact_id, payload);
        }
        this.$emit('close-dialog', true);
      }
    },
    async createNewDel(payload) {
      this.$store.commit('SET_IS_LOADING', 'createNewDel', {
        root: true,
      });
      await this.$http
        .post(process.env.VUE_APP_API_BASE_URL + '/contacts', payload)
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch('moduleManageContacts/fetchDelContacts');
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'createNewDel', {
            root: true,
          });
        });
    },
    async updateDel(delId, payload) {
      this.$store.commit('SET_IS_LOADING', 'updateDel', {
        root: true,
      });
      await this.$http
        .put(process.env.VUE_APP_API_BASE_URL + '/contacts/' + delId, payload)
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch('moduleManageContacts/fetchDelContacts');
          }
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          this.$store.commit('REMOVE_IS_LOADING', 'updateDel', {
            root: true,
          });
        });
    },
  },
  computed: {
    ...mapGetters({
      delContact: 'moduleManageContacts/getDelContact',
      school: 'moduleDashboard/getSelectedSchool',
    }),
  },
  mounted() {
    if (this.delContact) {
      this.formData = { ...this.delContact };
      this.isEdit = true;
    }
  },
  watch: {
    dialogOpen(open) {
      if (open && this.delContact) {
        this.formData = { ...this.delContact };
        this.isEdit = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-style {
  height: 44px;
  align-items: center;
}
</style>
